import { Component } from 'react';
import * as React from 'react';
import { Grid, Box } from '@material-ui/core';
import { QuestionnaireInformation } from '../../../models/questionnaire-models/internal/QuestionnaireModels';
import QuestionnaireCard from '../../questionnaire-page/components/QuestionnaireCard';

interface Props {
  questionnaires?: Array<QuestionnaireInformation>;
  component?: any;
}

export default class QuestionnaireOverview extends Component<Props, any> {
  static renderQuestionnaire(questionnaireInformation: QuestionnaireInformation, index: number, Component?: any) {
    return (
      <Grid item xs={12} sm={6} md={12} key={index}>
        {Component ? <Component questionnaire={questionnaireInformation} /> : <QuestionnaireCard questionnaire={questionnaireInformation} />}
      </Grid>
    );
  }

  render() {
    const { questionnaires, component } = this.props;
    return (
      <Box>
        <Grid container spacing={2}>
          {(questionnaires as Array<QuestionnaireInformation>).map((value, index) => QuestionnaireOverview.renderQuestionnaire(value, index, component))}
        </Grid>
      </Box>
    );
  }
}
