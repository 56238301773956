const spacing = [
    0,      // 0
    4,      // 1
    8,      // 2
    12,     // 3
    16,     // 4
    24,     // 5
    32,     // 6
    48,     // 7
    64,     // 8
    80,     // 9
    128,    // 10
    256     // 11
];
export default spacing;
