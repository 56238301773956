import { State } from '../Reducers';

import { LOG_IN, LOG_OUT, SET_CURRENT_SECONDARY_PROFILE, SET_FLAVOURID_USER, SET_USER } from './LoginActions';
import _ from 'lodash';
import { FlavourIDUser, SecondaryProfile } from '../../models/user-models/UserModels';

export interface AuthenticationState {
  authenticated: boolean;
  currentUser?: User;
  authenticationToken?: string;
  flavourIdUser?: FlavourIDUser;
  currentSecondaryProfile?: SecondaryProfile;
}

export const authenticationInitialState: AuthenticationState = {
  authenticated: false,
  currentUser: undefined,
  flavourIdUser: undefined,
  currentSecondaryProfile: undefined
};

function loginReduction(state: State, values: any) {
  const newState = _.cloneDeep(state);
  newState.authentication.authenticated = true;
  newState.authentication.currentUser = values.currentUser || state.authentication.currentUser;
  return newState;
}

function setUserReduction(state: State, values: any) {
  const newState = _.cloneDeep(state);
  newState.authentication.authenticated = !!values.currentUser;
  newState.authentication.currentUser = values.currentUser || undefined;
  return newState;
}

function setFlavourIdUser(state: State, values: any) {
  const newState = _.cloneDeep(state);
  newState.authentication.flavourIdUser = values.flavourIdUser;
  return newState;
}

function logoutReduction(state: State) {
  const newState = _.cloneDeep(state);
  newState.authentication.authenticated = false;
  newState.authentication.currentUser = undefined;
  newState.authentication.flavourIdUser = undefined;
  newState.authentication.currentSecondaryProfile = undefined;
  return newState;
}

function setCurrentSecondaryProfile(state: State, values: { type: string; secondaryProfile?: SecondaryProfile }) {
  const newState = _.cloneDeep(state);
  newState.authentication.currentSecondaryProfile = values.secondaryProfile;
  return newState;
}

export const AuthenticationReducers = {
  [LOG_IN]: loginReduction,
  [LOG_OUT]: logoutReduction,
  [SET_USER]: setUserReduction,
  [SET_FLAVOURID_USER]: setFlavourIdUser,
  // @ts-ignore
  [SET_CURRENT_SECONDARY_PROFILE]: setCurrentSecondaryProfile
};
