import * as React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { TextQuestionViewProps } from '../../../../../models/questiontype-models/internal/QuestionTypeModels';
import QuestionTypeContainer from '../../QuestionTypeContainer';
import { QuestionProps } from '../../../../../models/questionnaire-models/internal/QuestionModels';
import I18NFromObjectComponent from '../../../../../shared/components/I18NFromObjectComponent';

interface Props extends QuestionProps, TextQuestionViewProps {}

export default class TextQuestionView extends QuestionTypeContainer<Props, any> {
  render() {
    const { hint, value, maxLength } = this.props;
    return (
      <Grid container justify="center">
        <TextField
          label={<I18NFromObjectComponent object={{ hint }}>{(hint: string) => <>{hint}</>}</I18NFromObjectComponent>}
          value={this.getValue(value, this.props)}
          inputProps={{ maxLength: maxLength }}
          onChange={(event) => this.handleChange(event.target.value, this.props)}
          margin="normal"
          fullWidth={true}
          multiline={true}
        />
      </Grid>
    );
  }
}
