import { createMuiTheme } from '@material-ui/core/styles';
import style from './Style';
import { TextTransformProperty } from 'csstype';

export const theme = createMuiTheme({
  palette: {
    common: {
      black: style.colors.baseColorPalette.black,
      white: style.colors.baseColorPalette.white
    },
    primary: {
      light: style.colors.brand.vividGreen,
      main: style.colors.brand.brandGreen,
      dark: style.colors.brand.darkGreen,
      contrastText: style.colors.baseColorPalette.black
    },
    text: {
      primary: style.colors.grayscale[2],
      secondary: style.colors.grayscale[1]
    },
    error: {
      main: style.colors.system.danger
    },
    warning: {
      main: style.colors.system.warning
    },
    success: {
      main: style.colors.system.success
    },
    grey: {
      100: style.colors.grayscale[1],
      200: style.colors.grayscale[2],
      300: style.colors.grayscale[3],
      400: style.colors.grayscale[4],
      500: style.colors.grayscale[5],
      600: style.colors.grayscale[6],
      700: style.colors.grayscale[7]
    }
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Open Sans',
    h1: style.typography.trafalgar,
    h2: style.typography.paragon,
    h3: style.typography.doublePica,
    h4: style.typography.greatPrimer,
    h5: style.typography.pica,
    h6: style.typography.pica_strong,
    subtitle1: style.typography.bravier,
    subtitle2: style.typography.bravier_strong,
    body1: style.typography.body,
    body2: style.typography.body_strong,
    caption: style.typography.minion,
    button: style.typography.button,
    overline: style.typography.micro
  },
  spacing: [0, 4, 8, 12, 16, 24, 32, 48, 64, 80, 128, 256],
  overrides: {
    MuiTouchRipple:{
      child: {
        // Some CSS
        backgroundColor: "blue"
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        color: style.colors.grayscale[4],
        "&:hover": {
          color: style.colors.grayscale[4],
          backgroundColor: 'transparent'
        },
        "&$checked": {
          color: style.colors.brand.darkGreen,
          "&:hover": {
            color: style.colors.brand.darkGreen,
            backgroundColor: 'transparent'
          },
          "&.Mui-focusVisible": {
            color: style.colors.brand.darkGreen
          }
        },
        "&.Mui-focusVisible": {
          color: "transparent"
        },
        "&.focused:not(.Mui-focusVisible):not($checked)": {
          color: "transparent"
        }
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none' as TextTransformProperty
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        textTransform: 'none' as TextTransformProperty,
        fontSize: 12
      }
    },
    MuiSvgIcon: {
      root: {
          width: 'auto',
          height: 'auto',
      },
  },
  }
});

// TODO: implement this
