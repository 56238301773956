import { Component } from 'react';
import * as React from 'react';
import { Dialog, withStyles, StyledComponentProps } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import AddUserDialog from './AddUserDialog';
import { connect } from 'react-redux';
import { State } from '../../../redux/Reducers';
import { FlavourIDUser, SecondaryProfile } from '../../../models/user-models/UserModels';
import LoadingIndicator from '../static/LoadingIndicator';
import { setCurrentSecondaryProfile } from '../../../redux/authentication/LoginActionCreators';
import AuthenticatedPageWithLoading from '../../containers/AuthenticatedPageWithLoading';
import CurrentUserAvatar from '../avatars/CurrentUserAvatar';
import Fab from '@material-ui/core/Fab';
import RemoveUserDialog from './RemoveUserDialog';
import { FormattedMessage } from 'react-intl';
import StyledParagraph from '../../../styling/StyledParagraph';

const styles = {
  closeButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  removeButton: {
    backgroundColor: 'red',
    boxShadow: 'none',
    position: 'absolute' as 'absolute',
    right: -24,
    top: -24,
    minHeight: 24,
    height: 24,
    width: 24,
    zIndex: 10
  }
};

interface Props extends StyledComponentProps {
  open: boolean;
  onClose: () => void;
  flavourIdUser?: FlavourIDUser;
  setCurrentSecondaryProfile: (profile?: SecondaryProfile) => void;
}

interface MyState {
  addUserDialogOpen: boolean;
  removeUserDialogOpen: boolean;
  removingUser?: SecondaryProfile;
}

class SwitchAccountDialog extends Component<Props, MyState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addUserDialogOpen: false,
      removeUserDialogOpen: false,
      removingUser: undefined
    };
  }

  selectUser(user?: SecondaryProfile) {
    const { onClose, setCurrentSecondaryProfile } = this.props;
    setCurrentSecondaryProfile(user);
    onClose();
  }

  openRemoveDialog(user: SecondaryProfile) {
    this.setState({
      removeUserDialogOpen: true,
      removingUser: user
    });
  }

  closeRemoveDialog() {
    this.setState({
      removeUserDialogOpen: false,
      removingUser: undefined
    });
  }

  openAddUserDialog() {
    this.setState({
      addUserDialogOpen: true
    });
  }

  closeAddUserDialog() {
    this.setState({
      addUserDialogOpen: false
    });
  }

  formatName(name: string) {
    let currentName = name;
    if (name.includes('@')) {
      currentName = name.split('@')[0];
    }
    return currentName.slice(0, Math.min(currentName.length, 10)) + (currentName.length > 10 ? '...' : '');
  }

  render() {
    const { open, onClose, flavourIdUser } = this.props;
    const classes = this.props.classes || {};

    if (!flavourIdUser) {
      return <LoadingIndicator />;
    }
    return (
      <Dialog onClose={onClose} open={open}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <DialogTitle>
              <FormattedMessage id={'switch_user_dialog_title'} />
            </DialogTitle>
          </Grid>
          <Grid item>
            <Fab onClick={() => onClose()} className={classes.closeButton}>
              <CloseIcon style={{ width: 24, height: 24, color: 'black' }} />
            </Fab>
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={6} sm={4} item style={{ padding: 16 }}>
            <Button style={{ width: '100%' }} onClick={() => this.selectUser(undefined)}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Avatar>
                    <CurrentUserAvatar usesSecondaryUser={false} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <StyledParagraph>{this.formatName(flavourIdUser.name)}</StyledParagraph>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          {(flavourIdUser.secondaryProfiles || []).map((user, index) => (
            <Grid key={index} xs={6} sm={4} item style={{ padding: 16 }}>
              <div>
                <Button onClick={() => this.selectUser(user)} style={{ width: '100%'}}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item style={{position:'relative'}}>
                      <Fab onClick={(e) => { e.stopPropagation(); this.openRemoveDialog(user); }} size="small" aria-label="remove" className={classes.removeButton}>
                        <CloseIcon style={{ width: 24, height: 24, color: 'white' }} />
                      </Fab>
                      <Avatar>{user.name.charAt(0)}</Avatar>
                    </Grid>
                    <Grid item>
                      <StyledParagraph>{this.formatName(user.name)}</StyledParagraph>
                    </Grid>
                  </Grid>
                </Button>
              </div>
            </Grid>
          ))}
          <Grid xs={6} sm={4} item style={{ padding: 16 }}>
            <Button onClick={() => this.openAddUserDialog()} style={{ width: '100%' }}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Avatar>
                    <AddIcon style={{width:'100%'}} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <StyledParagraph>
                    <FormattedMessage id={'switch_user_dialog_add'} />
                  </StyledParagraph>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        <AuthenticatedPageWithLoading>
          {
            // @ts-ignore
            <AddUserDialog open={this.state.addUserDialogOpen} onClose={() => this.closeAddUserDialog()} />
          }
        </AuthenticatedPageWithLoading>
        <AuthenticatedPageWithLoading>
          {this.state.removingUser ? (
            // @ts-ignore
            <RemoveUserDialog open={this.state.removeUserDialogOpen} currentDeletingUser={this.state.removingUser} onClose={() => this.closeRemoveDialog()} />
          ) : (
            <></>
          )}
        </AuthenticatedPageWithLoading>
      </Dialog>
    );
  }
}

export default connect(
  (state: State) => ({
    flavourIdUser: state.authentication.flavourIdUser
  }),
  (dispatch) => ({
    setCurrentSecondaryProfile: (secondaryProfile?: SecondaryProfile) => {
      dispatch(setCurrentSecondaryProfile(secondaryProfile));
    }
  })
)(withStyles(styles)(SwitchAccountDialog));
