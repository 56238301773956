import { Component } from 'react';
import * as React from 'react';
import { withStyles, Typography, StyledComponentProps } from '@material-ui/core';
import style from './Style';

const styles = {
  big: {
    ...style.typography.trafalgar
  },
  normal: {
    ...style.typography.doublePica
  },
  medium: {
    ...style.typography.greatPrimer_strong
  },
  small: {
    ...style.typography.pica
  },
  minion: {
    ...style.typography.bravier_strong
  },
  default: {
    ...style.typography.doublePica
  }
};

interface Props extends StyledComponentProps {
  children?: any;
  variation?: 'big' | 'normal' | 'medium' | 'small' | 'minion';
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'srOnly' | undefined;
  color?: any;
  style?: any;
}

class StyledTitle extends Component<Props, any> {
  render() {
    let { children, element,color, style, ...props } = this.props;
    const classes = this.props.classes || {};
    return (
      <React.Fragment>
        {
          <Typography variant={element} className={props.variation ? classes[props.variation] : classes.default} style={{color: color, ...style}}>
            {children}
          </Typography>
        }
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(StyledTitle);