import { Component } from 'react';
import * as React from 'react';
import { Grid, withStyles, Box, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { QuestionnaireInformation } from '../../../models/questionnaire-models/internal/QuestionnaireModels';
import StyledButton from '../../../styling/StyledButton';
import { getStates } from '../../../shared/components/QuestionOrbLine';
import QuestionOrbLine from '../../../shared/components/QuestionOrbLine';
import { FormattedMessage } from 'react-intl';
import I18NFromObjectComponent from '../../../shared/components/I18NFromObjectComponent';
import style from '../../../styling/Style';
import StyledParagraph from '../../../styling/StyledParagraph';
import StyledCard from '../../../styling/StyledCard';

const styles = (theme: any) => ({
  root: {
    marginBottom: '24px',
  },
  questionnarieTitle: {
    ...style.typography.minion
  }
});

interface Props extends RouteComponentProps {
  questionnaire: QuestionnaireInformation;
  classes: any;
}

class QuestionnaireCard extends Component<Props, any> {
  render() {
    const { questionnaire, history, classes } = this.props;

    const name = <I18NFromObjectComponent object={questionnaire.title}>{(title: any) => title}</I18NFromObjectComponent>;
    const completed = questionnaire.progress;

    return (
      <StyledCard className={classes.root} size="sm">
        <Grid container justify="space-between" alignItems="flex-start" direction="row-reverse">
          <Grid item xs={6} sm={4}>
            <Grid container justify="flex-end">
              <StyledButton size="sm" onClick={() => history.push('/questionnaire/' + questionnaire.id)}>
                <FormattedMessage id={'questionnaire_card_review'} />
              </StyledButton>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={8}>
            <StyledParagraph>{name}</StyledParagraph>
            <Typography className={classes.questionnarieTitle} style={{ color: completed === 100 ? style.colors.system.success : style.colors.system.danger }}>
              {(completed || 0).toFixed(0)}
              <FormattedMessage id={'questionnaire_progress'} />
            </Typography>
          </Grid>
        </Grid>
        <Box pt={4}>
          <QuestionOrbLine questions={getStates(questionnaire.questionStatuses)} currentIndex={-1} isOverview={true} />
        </Box>
      </StyledCard>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(withRouter(QuestionnaireCard));
