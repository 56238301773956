import { Component } from 'react';
import * as React from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Box, FormHelperText, Grid, StyledComponentProps, Typography, withStyles } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as Yup from 'yup';
import StyledButton from '../../../styling/StyledButton';
import StyledTextField from '../../../styling/StyledTextField';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import style from '../../../styling/Style';
import AuthenticationSwitch from "../../authentication/containers/AuthenticationSwitch";
import {EmailSentIcon} from "./ForgotPasswordProviderIcons";
import {RouteComponentProps, withRouter} from "react-router-dom";

const styles = {
  forgotPasswordTitle: {
    ...style.typography.doublePica,
    color: style.colors.grayscale[1],
    marginBottom: 12
  },
  forgotPasswordDescription: {
    ...style.typography.paragraphText,
    color: style.colors.grayscale[2],
  }
};
interface Props extends StyledComponentProps, RouteComponentProps {
  onReset: () => void;
  intl: IntlShape;
}

interface State {
  verificationEmail: string;
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  global: Yup.string().notRequired()
});

class ForgotPasswordForm extends Component<Props, State> {

  constructor(props: any) {
    super(props);

    this.state = {
      verificationEmail: ''
    }
  }

  getQueryParams() {
    return new URLSearchParams(this.props.location.search);
  }

  onReset(values: any, formikHelpers: FormikHelpers<any>) {
    const actionCodeSettings: any = {};

    return firebase
      .auth()
      .sendPasswordResetEmail(values.email, actionCodeSettings)
      .then(
        () => {
          this.setState({verificationEmail: values.email});
        },
        function (error) {
          // Handle Errors here.
          // const errorCode = error.code;
          const errorMessage = error.message;
          formikHelpers.setErrors({
            global: errorMessage
          });
        }
      );
  }

  render() {
    const { intl } = this.props;
    const classes = this.props.classes || {};
    const {verificationEmail} = this.state;
    const isResend = !!verificationEmail;

    return (
      <Formik initialValues={{ email: verificationEmail, global: '' }} validationSchema={forgotPasswordSchema} onSubmit={(values, formikHelpers) => this.onReset(values, formikHelpers)}>
        {({ errors, touched, handleSubmit }) => {
          return (
            <Form translate="yes" onSubmit={handleSubmit}>
              <Grid container>

                {isResend &&
                <Grid item xs={12}>
                  <Box textAlign="center" mb={8}>
                    <EmailSentIcon />
                  </Box>
                </Grid>}

                <Grid item xs={12}>
                  <Box textAlign="left">
                    <Typography className={classes.forgotPasswordTitle}>
                      <FormattedMessage id={isResend?'resend_password_title':'forgot_password_title'} />
                    </Typography>
                  </Box>
                </Grid>

                {!isResend?
                    <Grid item xs={12}>
                      <Box textAlign="left">
                        <Typography className={classes.forgotPasswordDescription}>
                          <FormattedMessage id={'forgot_password_description'} />
                        </Typography>
                      </Box>
                    </Grid>
                    :
                    <Grid item xs={12}>
                      <Box textAlign="left" mt={2}>
                        <Typography>
                          <FormattedMessage id={'we_sent_an_email_to'} />
                        </Typography>
                      </Box>
                      <Box textAlign="left">
                        <Typography style={{fontWeight:'bold'}}>
                          {verificationEmail}
                        </Typography>
                      </Box>
                      <Box textAlign="left">
                        <Typography>
                          <FormattedMessage id={'check_email_message'} />
                        </Typography>
                      </Box>
                    </Grid>
                }


                <Grid item xs={12}>
                  <Box pt={5}>
                    <Field name="email" type="email">
                      {(field: FieldProps<any>) => (
                        <StyledTextField
                          {...field}
                          fullWidth={true}
                          label={<FormattedMessage id={'authentication_email_label'} />}
                          placeholder={intl.formatMessage({ id: 'authentication_email_placeholder' })}
                          margin="normal"
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <div>
                    <Field name="global">{(field: FieldProps<any>) => <FormHelperText error={true}>{field.meta.error}</FormHelperText>}</Field>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={5}>
                    <StyledButton type="submit" size="lg">
                      <FormattedMessage id={isResend?'resend_button':'send_button'} />
                    </StyledButton>
                  </Box>
                  {!isResend?
                      <Box mt={5} textAlign="center">
                        <StyledButton variant="link" type="button" size="lg" href={'/signin'}>
                          <FormattedMessage id={'back_to_login'} />
                        </StyledButton>
                      </Box>
                      :
                      null
                    }
                </Grid>
                {!isResend?
                    <Grid container justify="center">
                      <AuthenticationSwitch />
                    </Grid>
                    :
                    null
                }

              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withStyles(styles)(injectIntl(withRouter(ForgotPasswordForm)));
