import { Component } from 'react';
import * as React from 'react';
import { Container } from '@material-ui/core';
import { withStyles, StyledComponentProps } from '@material-ui/core';

interface Props extends StyledComponentProps {
  children?: any;
}

const styles = {
  customContainer: {
    maxWidth: '800px'
  }
};

class LegalPage extends Component<Props> {
  render() {
    const classes = this.props.classes || {};
    const { children } = this.props;
    return <Container className={classes.customContainer}>{children}</Container>;
  }
}

export default withStyles(styles)(LegalPage);
