import React from "react";
import type { Props } from './index.d';
import { createUseStyles } from 'react-jss'
import Icon from "../Icon/index";

const useStyles = createUseStyles((theme: any) => ({
    button: {
        ...theme.buttons.button
    },
    label:{
        ...theme.buttons.label
    },
    iconContainerLeft:{
        paddingLeft: 5,
        paddingRight: 10
    },
    iconContainerRight:{
        paddingLeft: 10,
        paddingRight: 5
    },
    fullWidth:{
        width: '100%'
    },
    primary:{
        ...theme.buttons.primary.normal,
        '&:hover:not(:disabled)':{
            ...theme.buttons.primary.hover,
            '& $buttonLabel':{
                ...theme.buttons.primary.hoverLabel
            }
        },
        '&:active:not(:disabled)':{
            ...theme.buttons.primary.active
        },
        '&:disabled':{
            ...theme.buttons.primary.disabled
        }
    },
    secondary:{
        ...theme.buttons.secondary.normal,
        '&:hover:not(:disabled)':{
            ...theme.buttons.secondary.hover,
            '& $buttonLabel':{
                ...theme.buttons.secondary.hoverLabel
            }
        },
        '&:active:not(:disabled)':{
            ...theme.buttons.secondary.active
        },
        '&:disabled':{
            ...theme.buttons.secondary.disabled
        }
    },
    text:{
        ...theme.buttons.text.normal,
        '&:hover:not(:disabled)':{
            ...theme.buttons.text.hover,
            '& $buttonLabel':{
                ...theme.buttons.text.hoverLabel
            }
        },
        '&:active:not(:disabled)':{
            ...theme.buttons.text.active
        },
        '&:disabled':{
            ...theme.buttons.text.disabled
        }
    },
    icon:{
        ...theme.buttons.icon.normal,
        '&:hover:not(:disabled)':{
            ...theme.buttons.icon.hover,
            '& $buttonLabel':{
                ...theme.buttons.icon.hoverLabel
            }
        },
        '&:active:not(:disabled)':{
            ...theme.buttons.icon.active
        },
        '&:disabled':{
            ...theme.buttons.icon.disabled
        }
    },
    circle:{
        ...theme.buttons.circle.normal,
        '& $iconContainerLeft':{
            ...theme.buttons.circle.normalIconLeft
        },
        '&:hover:not(:disabled)':{
            ...theme.buttons.circle.hover,
            '& $buttonLabel':{
                ...theme.buttons.circle.hoverLabel
            },
            '& $iconContainerLeft':{
                ...theme.buttons.circle.hoverIconLeft
            }
        },
        '&:active:not(:disabled)':{
            ...theme.buttons.circle.active,
            '& $iconContainerLeft':{
                ...theme.buttons.circle.activeIconLeft
            }
        },
        '&:disabled':{
            ...theme.buttons.circle.disabled,
            '& $iconContainerLeft':{
                ...theme.buttons.circle.disabledIconLeft
            }
        }
    }
}));

export const Button: React.FC<Props> =
    ({
        variant= 'primary',
        disabled = false,
        title = '',
        size = 'normal',
        fullWidth= false,
        icon,
        iconRight,
        children,
        ...props
    }) =>
    {

        const classes = useStyles();

        const buttonClasses: string[] = [
            classes.button,
            classes[variant]
        ];

        if(fullWidth) buttonClasses.push(classes.fullWidth);

        return (
            <button className={buttonClasses.join(' ')} disabled={disabled} {...props}>
                { icon && <span className={classes.iconContainerLeft}>
                    {icon && typeof icon === 'string' && <Icon svgIcon={icon} />}
                    {icon && typeof icon !== 'string' && icon()}
                </span> }
                <span className={classes.label}>{title}{children}</span>
                { iconRight && <span className={classes.iconContainerRight}>
                    {iconRight && typeof iconRight === 'string' && <Icon svgIcon={iconRight} />}
                    {iconRight && typeof iconRight !== 'string' && iconRight()}
                </span> }
            </button>
        );
    }
