import { Component } from "react";
import { withStyles } from "@material-ui/core";
import style from "../../../styling/Style";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import { flavourIDUrls } from "./urlMappings";

const styles = {
    flavorIdImageContainer: {
        position: 'relative' as 'relative',
    },
    flavorIdImage: {
        width: '100%',
    },
    descriptionContainer: {
        position: 'absolute' as 'absolute',
        left:0,
        right:0,
        bottom:0,
        top:0,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    circle: {
        width:'34%',
        height:'34%',
        borderRadius:'50%',
        overflow:'hidden',
        background:style.colors.flavorCircle.background,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection: 'column' as 'column'
    },
    separator: {
        ...style.typography.flavorCircleSeparator,
        color:style.colors.flavorCircle.text
    },
    flavorName: {
        ...style.typography.flavorCircle,
        color:style.colors.flavorCircle.text
    }
}

interface Props {
    classes?: any;
    containerClasses?: any;
    topTypes: string[];
}

interface State {}

const unavailableUrl = flavourIDUrls['Unavailable.png'];

class AromaTypeViz extends Component<Props, State, any> {

    render() {

        const { classes, topTypes, containerClasses } = this.props;
        const imageName = topTypes ? `${topTypes[0]} & ${topTypes[1]}.png` : null;
        const imageNameSwapped = topTypes ? `${topTypes[1]} & ${topTypes[0]}.png` : null;
        const imageUrl = flavourIDUrls[imageName || ''] || flavourIDUrls[imageNameSwapped || ''] || unavailableUrl;

        return (
            <div className={containerClasses}>
                <div className={classes.flavorIdImageContainer}>
                    <div className={classes.descriptionContainer}>
                        <div className={classes.circle}>
                            <div className={classes.flavorName}><FormattedMessage id={topTypes[0] || "flavorid_unknown_aroma"}/></div>
                            <div className={classes.separator}>&</div>
                            <div className={classes.flavorName}><FormattedMessage id={topTypes[1] || "flavorid_unknown_aroma"}/></div>
                        </div>
                    </div>
                    <img alt="flavorid visualisation" src={imageUrl} className={classes.flavorIdImage} />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(AromaTypeViz);
