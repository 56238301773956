import APIQuestionnaireOverview from './APIQuestionnaireOverview';
import { questionnaireGetTransformer } from '../../../models/questionnaire-models/transformers/QuestionnaireTransformersToInternal';
import { QuestionnaireGETModel } from '../../../models/questionnaire-models/external/QuestionnairesGetModels';
import { AuthenticatedProps } from '../../../shared/containers/AuthenticatedPageWithLoading';
import SecondaryProfileMiddleware from '../../../API/refetch/SecondaryProfileMiddleware';

interface Props extends AuthenticatedProps {
  filter?: string;
}

// @ts-ignore
export default SecondaryProfileMiddleware((props: Props) => {
  return {
    questionnaires: {
      url: `/stop/questionnaire/`,
      authenticationToken: props.authToken,
      queryParams: {
        filter: props.filter
      },
      then: (questionnaires: any) => {
        return {
          value: questionnaireGetTransformer(questionnaires as QuestionnaireGETModel)
        };
      }
    }
  };
}, APIQuestionnaireOverview);
