import {SvgIcon} from "@material-ui/core";
import React from "react";

export function EyeIcon(props: any) {
    return (
        <SvgIcon {...props} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 0C5.48701 0 1.61982 2.89166 0 7C1.61982 11.1083 5.48701 14 10 14C14.513 14 18.3802 11.1083 20 7C18.3802 2.89166 14.513 0 10 0ZM10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10Z" fill="#CED5D9"/>
        </SvgIcon>
    );
}
