import { CoreTranslations } from '../interfaces/ICoreTranslations';

export const core_translations_en: CoreTranslations = {
  toolbar_avatar_popup_switch_or_add_user: 'Switch/Add family member',
  toolbar_avatar_popup_change_language: 'Change language',
  toolbar_questionnaire_no_name: ' ',
  logout: 'Log Out',
  login: 'Log In',
  add_user_dialog_add_user: 'Add user',
  add_user_dialog_label: 'Name of the new user',
  add_user_dialog_placeholder: 'Your new users name',
  add_user_dialog_create_user: 'Create user',
  add_user_dialog_username_too_short: 'Username is too short',
  add_user_dialog_username_too_long: 'Username is too long',
  remove_user_dialog_are_you_sure: 'Are you sure you want to delete this account?',
  remove_user_dialog_remove: 'Remove',
  switch_user_dialog_title: 'Switch/Add family member',
  switch_user_dialog_add: 'Add',
  change_language_dialog_title: 'Change language',
  change_language_dialog_change: 'Confirm'
};
