import * as React from 'react';
import AuthenticationPage from '../../containers/AuthenticationPageView';
import SignUpForm from './SignUpForm';
import { SIGNUP_QUESTIONNAIRE } from '../../AuthenticationViewTypes';
import {State} from "../../../../redux/Reducers";
import {Redirect} from "react-router";
import {connect} from "react-redux";

class SignUpPageView extends React.Component<any, any> {
  static NEXT_VIEW = SIGNUP_QUESTIONNAIRE;
  render() {
    const { onAction, isAuthenticated, isEmailVerified } = this.props;

    if(isAuthenticated && isEmailVerified){
      return <Redirect to={'/'} />;
    }

    return (
      <AuthenticationPage>
        <SignUpForm onAction={onAction} />
      </AuthenticationPage>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    isAuthenticated: state.authentication.authenticated,
    isEmailVerified: state.authentication.currentUser?.emailVerified
  };
};

export default connect(mapStateToProps, null)(SignUpPageView);
