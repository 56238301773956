import { EN, Language } from '../../Languages';
import { SET_LANGUAGE } from './LanguageActions';
import { State } from '../Reducers';

export interface LanguageState {
  language: Language;
  languageChanged: boolean
}

export const LanguageInitialState: LanguageState = {
  language: EN,
  languageChanged: false,
};

function languageReduction(state: State, values: any) {
  return Object.assign({}, state, {
    language: { language: values.language, languageChanged: values.languageChanged }
  });
}

export const LanguageReducers = {
  [SET_LANGUAGE]: languageReduction
};
