import type { Props } from "./index.d";
import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles((theme: any)=>({
    aromaDot:{
        ...theme.aromaDots.aromaDot
    }
}));

export const AromaDot: React.FC<Props> =
({
     color,
    ...props
}) => {

    const classes = useStyles();

    return (
        <div className={classes.aromaDot} {...props} style={{backgroundColor:color, ...(props.style?props.style:{})}} />
    );
}

export default AromaDot;
