import { Component } from 'react';
import * as React from 'react';
import { Grid, LinearProgress, StyledComponentProps, withStyles, Box } from '@material-ui/core';
import { QuestionnaireDetailProps } from '../../../API/containers/QuestionnaireAPIDetailFetcher';
import { FormattedMessage } from 'react-intl';
import StyledParagraph from '../../../styling/StyledParagraph';

const styles = {
  linearProgressBar: {
    width: '100%',
    height: 8,
    borderRadius: '20px'
  }
};

interface Props extends QuestionnaireDetailProps, StyledComponentProps {}

class QuestionnaireProgressComponent extends Component<Props> {
  render() {
    const { questionnaire } = this.props;
    const classes = this.props.classes || {};

    if (!questionnaire) {
      return (
        <StyledParagraph>
          <FormattedMessage id={'profilepage_questionnaire_not_found'}></FormattedMessage>
        </StyledParagraph>
      );
    }

    const completed = questionnaire.completed;
    const progress = Math.round((completed || 0) * 100) + '%';

    return (
      <Box pt={4}>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item xs={12}>
            <StyledParagraph variation="small">{progress}</StyledParagraph>
          </Grid>
        </Grid>
        <LinearProgress className={`${classes.linearProgressBar}`} variant="determinate" value={completed * 100 || 0} />
      </Box>
    );
  }
}

export default withStyles(styles)(QuestionnaireProgressComponent);
