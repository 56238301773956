import * as React from 'react';
import firebase from 'firebase/app';
import AuthenticationPageView from '../../authentication/containers/AuthenticationPageView';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Component } from 'react';
import { SIGNIN } from "../../authentication/AuthenticationViewTypes";
import { VerifiedIcon } from "../../../styling/icons/Verified";
import { Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import NormalBlock from "../../../styling/blocks/Normal";
import StyledButton from "../../../styling/StyledButton";
import AuthenticationNavbarHalf from "../../../shared/components/static/navbars/AuthenticationNavbarHalf";
import {ErrorIcon} from "../../../styling/icons/Error";
import {DEFAULT_QUESTIONNAIRE} from "../../../constants/QuestionnaireConstants";
import {setUserAndFetchData} from "../../../redux/authentication/LoginActionCreators";
import {AnyAction, Dispatch} from 'redux';
import {User} from "firebase";
import {connect} from "react-redux";
import CustomTokenAuth from "../../../API/containers/CustomTokenAuth";
import AuthenticatedPageWithLoading from "../../../shared/containers/AuthenticatedPageWithLoading";
import {CustomTokenAuthResponse} from "../../../models/user-models/UserModels";
import { Userpilot } from 'userpilot'
import {Redirect} from "react-router";

interface Props extends RouteComponentProps {
  actionCode?: string;
  onEmailVerified: (currentUser?: firebase.User | null) => AnyAction;
}

enum Status {
  InProgress = "in_progress",
  Success = "success",
  Failure = "failure"
};

class EmailVerifyPage extends Component<Props, {status: Status}> {
  constructor(props: Props) {
    super(props);
    this.state = {status: Status.InProgress};
  }

  updateStatus(status: Status) {
    this.setState({status});
  }

  componentDidMount() {
    const { actionCode } = this.props;

    if (!actionCode) {
      this.updateStatus(Status.Failure);
      return;
    }
    // Try to apply the email verification code.
    firebase.auth().applyActionCode(actionCode).then((resp: any) => {

      const currentUser: firebase.firebase.User | null = firebase.auth().currentUser;
      if(currentUser){
        currentUser.reload().then(()=>{
          this.props.onEmailVerified(currentUser);
        });
      }

      // Email address has been verified.
      this.updateStatus(Status.Success);
      if (currentUser?.uid) {
        Userpilot.identify(currentUser.uid, {});
        Userpilot.track("email_confirmed", {});
      }
    }).catch((error: any) => {
      // Code is invalid or expired. Ask the user to verify their email address
      // again.
      this.updateStatus(Status.Failure);
    });
  }

  handleSubmit( authToken?: string ) {
    const { history } = this.props;

    switch (this.state.status) {
      case Status.Success:
	// TODO: Display a link back to the app, or sign-in the user directly
	// if the page belongs to the same domain as the app:
	// auth.signInWithEmailAndPassword(accountEmail, newPassword);

        history.push("/signin");
        history.push('/questionnaire/' + DEFAULT_QUESTIONNAIRE);
        break;
      case Status.Failure:
        history.push("/forgot-password");
        break;
      case Status.InProgress:
        break;
      default:
        break;
    }
  }

  getQueryParams() {
    return new URLSearchParams(this.props.location.search);
  }

  getEmailVerifyPage( authToken?: string ){
    const { status } = this.state;

    return (
        <AuthenticationPageView headerType={SIGNIN}>
          <AuthenticationNavbarHalf />
          <Grid container>
            <Grid container alignItems="center" justify="center">
              <Grid item style={{marginBottom:20}}>
                {status === Status.Success && <VerifiedIcon />}
                {status === Status.Failure && <ErrorIcon />}
              </Grid>
              <Grid item>
                <NormalBlock title={<FormattedMessage id={`verify_callback_title_${status}`} />}>
                  <FormattedMessage id={`verify_callback_form_description_${status}`} />
                </NormalBlock>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}>
                <StyledButton size="lg" type="submit" disabled={this.state.status === Status.InProgress} onClick={() => this.handleSubmit(authToken)}>
                  <FormattedMessage id={`email_verified_button_${status}`} />
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </AuthenticationPageView>
    );
  }

  render() {

    const { status } = this.state;

    const queryParams = this.getQueryParams();
    const continueUrl = queryParams.get('continueUrl');

    if(continueUrl && status === Status.Success ){

      const currentUser: firebase.firebase.User | null = firebase.auth().currentUser;

      if(!currentUser){
        return <Redirect to="/signin" />
      }

      return <>
        <AuthenticatedPageWithLoading>
          <CustomTokenAuth>
            {(values: CustomTokenAuthResponse)=>{
              return this.getEmailVerifyPage( values.authToken );
            }}
          </CustomTokenAuth>
        </AuthenticatedPageWithLoading>
      </>;
    }

    return this.getEmailVerifyPage();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onEmailVerified: (currentUser?: firebase.User | null) => {
      // @ts-ignore
      return dispatch(setUserAndFetchData(currentUser));
    }
  };
};

export default withRouter( connect(null, mapDispatchToProps)(EmailVerifyPage) );
