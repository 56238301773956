import { Component } from 'react';
import * as React from 'react';
import { QuestionnaireInformation } from '../../../models/questionnaire-models/internal/QuestionnaireModels';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import style from '../../../styling/Style';
import { BoxSizingProperty } from 'csstype';
import I18NFromObjectComponent from '../../../shared/components/I18NFromObjectComponent';
import ActionListItem from "./ActionListItem";

const styles = {
  item: {
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: style.colors.grayscale[7],
      '& path': {
        fill: style.colors.brand.brandGreen
      }
    },
    '&:active': {
      border: `1px solid ${style.colors.grayscale[4]}`,
      boxSizing: 'border-box' as BoxSizingProperty,
      boxShadow: '0px 8px 14px rgba(80, 93, 100, 0.21)',
      borderRadius: 4
    }
  },
  primaryText: {
    ...style.typography.body_strong
  },
  secondaryText: {
    ...style.typography.minion
  }
};

interface Props extends RouteComponentProps, StyledComponentProps {
  questionnaire: QuestionnaireInformation;
}

class QuestionnaireListItem extends Component<Props> {
  render() {
    const { questionnaire } = this.props;
    const name = <I18NFromObjectComponent object={questionnaire.title}>{(title: any) => title}</I18NFromObjectComponent>;
    const description = <I18NFromObjectComponent object={questionnaire.description}>{(description: any) => description}</I18NFromObjectComponent>;

    return (
        <ActionListItem
            title={name}
            description={description}
            url={'/questionnaire/' + questionnaire.id}
            actionKey={questionnaire.id}
        />
    );
  }
}

export default withStyles(styles)(withRouter(QuestionnaireListItem));
