import { Component, ReactElement } from 'react';
import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import HealthGraphComponent from '../../../shared/components/visualisations/HealthGraphComponent';
import HealthGraphComponentV2 from '../../../shared/components/visualisations/HealthGraphComponentV2';
import { HealthScoreDetailProps } from '../../../API/containers/HealthScoreFetcher';
import { HealthScore, HealthScoreStatus } from '../../../models/user-models/UserModels';
import { FormattedMessage } from 'react-intl';
import StyledTitle from '../../../styling/StyledTitle';
import StyledCard from '../../../styling/StyledCard';

interface Props extends HealthScoreDetailProps {}

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;
type HealthScoreStatusMap = PartialRecord<HealthScoreStatus, (healthScore: HealthScore) => ReactElement<any>>;


// TODO: translation
const renderDescription = (healthScore: HealthScore): string => {
  let scope: string[] = [];
    
  const overallHealthScore = healthScore.healthScore ?? 0;
  /*
  if (overallHealthScore === undefined) {
    return `There is not enough data for calculating a health score.`;
  }
  */
  if (overallHealthScore < 1) {
    if (healthScore?.insufficientKnowledgeFruit ?? true) {
        scope.push('fruits');
    }
    if (healthScore?.insufficientKnowledgeVegetables ?? true) {
        scope.push('vegetables');
    }
    
    return `There is not enough data for calculating a health score. We advise you to try out more ${scope.join(" and ")}.`;
  
  } else if (overallHealthScore === 1) {
    if ((healthScore?.healthScoreFruit ?? 0) < 2) {
        scope.push('fruits');
    }
    if ((healthScore?.healthScoreVegetables ?? 0) < 2) {
        scope.push('vegetables');
    }
    
    return `You dislike too many ${scope.join(" and ")} for having a healthy diet.`;
  } else if (overallHealthScore === 2) {
    if ((healthScore?.healthScoreFruit ?? 0) < 3) {
        scope.push('fruits');
    }
    if ((healthScore?.healthScoreVegetables ?? 0) < 3) {
        scope.push('vegetables');
    }
    return `You are on a good track but there is still room for improvement for ${scope.join(" and ")}`;
  } else {
      return "You are eating healthy. Keep up the good work!.";
  }
};

export default class HealthScoreComponent extends Component<Props> {
  private healthScoreStatusMap: HealthScoreStatusMap = {
    HEALTH_STATUS_INSUFFICIENT_DATA: (healthScore) => (
      <p>
        <FormattedMessage id={'healthscore_insufficient_data'}></FormattedMessage>
      </p>
    ),
    HEALTH_STATUS_UNKNOWN: (healthScore) => (
      <p>
        <FormattedMessage id={'healthscore_unknown'}></FormattedMessage>
      </p>
    )
  };

  renderHealthScoreV1(healthScore: HealthScore) {
    return <HealthGraphComponent percentage={healthScore.healthScore || 0} />;
  }

  renderHealthScoreV2(healthScore: HealthScore) {
    return (
      <HealthGraphComponentV2
        value={healthScore.healthScore || 0} 
        description={renderDescription(healthScore)}
      />
    );
  }

  renderOtherStatusses(healthScore?: HealthScore) {
    if (!healthScore) {
      return (
        <p>
          <FormattedMessage id={'healthscore_not_found'}></FormattedMessage>
        </p>
      );
    }
    const renderFunction = this.healthScoreStatusMap[healthScore.status];
    if (renderFunction !== undefined) {
      return renderFunction(healthScore);
    }
    return <div />;
  }

  render() {
    const { healthScore } = this.props;
    return (
      <Box mx={4}>
        <StyledCard size="lg">
          <div style={{ textAlign: 'center' }}>
            <StyledTitle variation="small" element="h4">
              <FormattedMessage id={'healthscore_header'}></FormattedMessage>
            </StyledTitle>
          </div>
          <Grid container justify = "center">
            {healthScore? (
                (healthScore.version ?? 0) >= 2 ? (
                  this.renderHealthScoreV2(healthScore!!)
                ): (
                  this.renderHealthScoreV1(healthScore!!)
                )
              ) : this.renderOtherStatusses(healthScore)
            }
          </Grid>
        </StyledCard>
      </Box>
    );
  }
}
