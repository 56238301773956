import { LOG_IN, LOG_OUT, SET_CURRENT_SECONDARY_PROFILE, SET_FLAVOURID_USER, SET_USER } from './LoginActions';

import firebase from 'firebase/app';
import { Dispatch } from 'redux';
import { setLanguage } from '../language/LanguageActionCreator';
import { LANGUAGE_BY_NAME, EN } from '../../Languages';
import { firebaseDidInit } from '../../FirebaseConfig';
import { FlavourIDUser, SecondaryProfile, UserSetupConfig } from '../../models/user-models/UserModels';

let loading = false;

export function login(currentUser?: User) {
  return {
    type: LOG_IN,
    currentUser: currentUser
  };
}

export function setUser(currentUser?: User) {
  return {
    type: SET_USER,
    currentUser: currentUser
  };
}

export function logout() {
  firebase.auth().signOut();
  return {
    type: LOG_OUT
  };
}

export function setFlavourIdUser(flavourIdUser: FlavourIDUser) {
  return {
    type: SET_FLAVOURID_USER,
    flavourIdUser: flavourIdUser
  };
}

export function setCurrentSecondaryProfile(secondaryProfile?: SecondaryProfile) {
  return {
    type: SET_CURRENT_SECONDARY_PROFILE,
    secondaryProfile: secondaryProfile
  };
}

export function setUserAndFetchData(currentUser?: firebase.User | null, userSetupConfig?: UserSetupConfig | null) {
  return function (dispatch: Dispatch) {
    if (currentUser) {
      if (loading) {
        return;
      }
      loading = true;

      return currentUser
        .getIdToken()
        .then((token) =>
          fetch('/stop/user', {
            method: 'POST',
            mode: 'same-origin',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token
            },
            body: JSON.stringify({
              ...(((userSetupConfig?.enabledExperiments?.length ?? 0) > 0)? {
                experiments: {
                  enabledIds: userSetupConfig!.enabledExperiments || []
                }
              }: {}),
              ...(((userSetupConfig?.enabledIntegrations?.length ?? 0) > 0)? {
                integrations: {
                  enabledIds: userSetupConfig!.enabledIntegrations || []
                }
              }: {})
            })
          })
        )
        .then((response) => response.json())
        .then((response) => {
          dispatch(login(currentUser));
          dispatch(setFlavourIdUser(response));
          if (response.settings) {
            if (response.settings.language) {
              dispatch(setLanguage(LANGUAGE_BY_NAME[response.settings.language], true));
            } else {
              dispatch(setLanguage(LANGUAGE_BY_NAME[EN.locale], false));
            }
          }
          firebaseDidInit(dispatch);
        })
        .then(() => {
          loading = false;
          return true;
        })
        .catch((error) => {
          console.log('Ooops', error);
          return false;
        });
    } else {
      dispatch(logout());
      firebaseDidInit(dispatch);
      return new Promise(() => false);
    }
  };
}
