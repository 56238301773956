import type { Props } from "./index.d";
import React, {useEffect, useState} from "react";
import AromaDot from "../AromaDot/index";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles((theme: any)=>({
    root:{
        position:'fixed',
        right:0,
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 '+theme.spacing[3]+'px',
        marginTop: 100
    },
    dotContainer:{
        transform: 'scale(0.62)',
        marginBottom: theme.spacing[2],
        opacity: .3,
        transition: 'all .2s ease-in-out'
    },
    dotActive:{
        transform: 'scale(1)',
        opacity: 1
    }
}));

export const AromaNavigator: React.FC<Props> =
({
     active,
    aromas,
     onAromaDotClick,
     getActiveUpdater,
    ...props
}) => {

    const classes = useStyles();
    const [activeItem, setActiveItem] = useState<number>(-1);

    useEffect(()=>{
        if(getActiveUpdater){
            getActiveUpdater(setActiveItem, () => activeItem);
        }
    }, [getActiveUpdater, activeItem]);

    if(getActiveUpdater){
        active = activeItem;
    }

    return (
        <div className={classes.root} {...props}>
            {aromas.map((item, key)=>(
                <div
                    className={classes.dotContainer+(active===key ? ` ${classes.dotActive}`:'')}
                    key={key}
                    onClick={()=>{
                        if(onAromaDotClick){
                            onAromaDotClick( item, key );
                        }
                    }}
                >
                    <AromaDot color={item.color} style={{margin:0}} />
                </div>
            ))}
        </div>
    );
}
export default AromaNavigator;
