import { AnswersStateModel, QuestionnaireMetaData } from './QuestionnairesGetModels';
import { MultipleChoiceQuestion, NumberQuestion, RatingQuestion, TextQuestion, TitleQuestion, YesNoQuestion } from '../../questiontype-models/external/QuestionTypeModels';

export interface QuestionnaireDetail extends QuestionnaireMetaData {
  lastAnswers: QuestionnaireAnswers;
  questionGroups: Array<QuestionGroup>;
}

export interface QuestionnaireAnswers extends AnswersStateModel {
  answerGroups: Array<{
    qgId: any;
    answers: Array<Answer>;
  }>;
  questionnaire: {
    id: string;
  };
}

export interface Answer {
  qId: string;
  choice?: string;
  choices?: {
    choices: Array<string>;
  };
  number?: number;
  rating?: number;
  text?: string;
  yesNo?: boolean;
  [key: string]: any; // Garbage type to make sure typescript can check values like an object
}

const answerKeys = ['choice', 'choices', 'number', 'rating', 'text', 'yesNo'];

export function getAnswerValue(
  answer?: Answer
): { answerValue: RatingQuestion | TextQuestion | MultipleChoiceQuestion | YesNoQuestion | NumberQuestion | TitleQuestion | undefined; answerType: string } {
  if (!answer) {
    return { answerType: 'none', answerValue: undefined };
  }
  const answerType = answerKeys.find((key) => answer.answer[key]);
  return {
    answerType: answerType || 'none',
    answerValue: answerType ? answer.answer[answerType] : undefined
  };
}

export interface AnswerOrGroup {
  answer?: Answer;
  group?: AnswerGroup;
}

export interface AnswerGroup {
  qgId: string;
  answers: Array<AnswerOrGroup>;
  intentionallyBlank?: {
    reason: 'UNKNOWN' | 'NO_CONSENT' | 'UNMET_DEPENDENCY' | 'UNRECOGNIZED';
  };
}

export interface QuestionOrGroup {
  question?: Question;
  group?: QuestionGroup;
}

export function isQuestionGroup(arg: QuestionOrGroup): boolean {
  return Boolean(arg && arg.group);
}

export interface DependencyData {
  number?: number;
  string?: string;
  op: string;
  qId: string;
}

export interface QuestionOrGroupMetaData {
  dependsOn?: DependencyData;
  needsConsent?: Array<string>;
  description: string;
  id: string;
}

export interface Question extends QuestionOrGroupMetaData {
  rating?: RatingQuestion;
  text?: TextQuestion;
  multipleChoice?: MultipleChoiceQuestion;
  yesNo?: YesNoQuestion;
  number?: NumberQuestion;
  title?: TitleQuestion;
  [key: string]: any; // Garbage type to make sure typescript can check values like an object
}

const questionTypes = ['multipleChoice', 'title', 'number', 'rating', 'text', 'yesNo'];

export function getQuestionValue(question: Question): { questionValue: RatingQuestion | TextQuestion | MultipleChoiceQuestion | YesNoQuestion | NumberQuestion | TitleQuestion; questionType: string } {
  const questionType = questionTypes.find((key) => question[key]);
  return {
    questionType: questionType || 'none',
    questionValue: questionType ? question[questionType] : undefined
  };
}

export interface QuestionGroup extends QuestionOrGroupMetaData {
  questions: Array<QuestionOrGroup>;
}
