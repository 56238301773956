import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthenticationViewType } from '../AuthenticationTypesInterfaces';
import firebase from 'firebase/app';
import { DEFAULT_QUESTIONNAIRE } from '../../../../constants/QuestionnaireConstants';

export default class AuthenticationFinishedView extends AuthenticationViewType<any, any> {
  static NEXT_VIEW = '';
  
  render() {
    const metadata = firebase.auth().currentUser?.metadata;
    if (metadata?.creationTime === metadata?.lastSignInTime) { //first login
      return <Redirect to={'/questionnaire/' + DEFAULT_QUESTIONNAIRE } />;
    }

    return <Redirect to="/" />;
  }
}
