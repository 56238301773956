import { ProfilePageTranslations } from '../interfaces/IProfilePageTranslations';

export const profilepage_translations_en: ProfilePageTranslations = {
  healthscore_header: 'Health Score',
  healthscore_insufficient_data: 'Insufficient data',
  healthscore_unknown: 'Health score is unknown',
  healthscore_not_found: 'No health score found',
  flavoridtest_header: 'FlavorID status',
  flavoridtest_description: 'Smarten up your FlavorID by completing the questionnaires',
  flavoridtest_review: 'Review',
  flavoridtest_complete: 'Complete',
  profilepage_taken_questionnaires: 'Completed actions',
  profilepage_new_questionnaire: 'Things to do',
  profilepage_active_integrations: 'Active integrations',
  profilepage_logout: 'Log out from FlavorID',
  profilepage_questionnaire_not_found: 'Error: could not find questionnaire!',
  integration_revoke_access: '[T] Revoke access',
  flavourid_not_available: 'FlavorID not available. Fill in the questionnaire to learn more.',
  flavourid_updating: 'FlavorID not available. Please wait until the necessary calculation are done.',
  flavorid_graph_title: 'Your FlavorID profile',
  check_out_happy_plate_title: "Go to Happy Plate",
  check_out_happy_plate_description: "Happy Plate is your guide to simple, tasty & healthier meals, personalized to fit your FlavorID",
  flavorid_welcome: "Welcome, {name}",
  flavorid_introduction: "Welcome to FlavorID, your universal flavor passport to discover new food that you love. ",
  flavorid_introduction_types: "FlavorID recognizes 13 main aroma types yet your answers reveal that you prefer {first_type} & {second_type} aromas more than any other.",
  flavorid_discover_type: "Discover all 13 aroma types and learn how to recognize them in food.",
  flavorid_unknown_aroma: "Unknown"
};
