import { Component } from 'react';
import * as React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { BackgroundColorProperty, PositionProperty, VisibilityProperty } from 'csstype';
import { theme } from '../../../styling/DefaultTheme';
import { AppIntegrationDocumentViewProps } from '../../../models/integration-models/internal/IntegrationModels';

const styles = {
  image: {
    width: '100%'
  },
  container: (props: Props) => ({
    borderRadius: 5,
    backgroundColor: props.integration.htmlColor,
    margin: 16,
    position: 'relative' as PositionProperty,
    [theme.breakpoints.up('xs')]: {
      height: 200
    }
  }),
  overlay: {
    '$container:hover &': {
      backgroundColor: theme.palette.grey[500] as BackgroundColorProperty,
      visibility: 'visible' as VisibilityProperty
    },
    position: 'absolute' as PositionProperty,
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    visibility: 'hidden' as VisibilityProperty,
    zIndex: 2
  },
  // revokeButton: {
  //   position: 'absolute' as PositionProperty,
  //   right: 8,
  //   bottom: 8
  // },
  integrationName: {
    position: 'absolute' as PositionProperty,
    top: 8,
    left: 8
  }
}; 

interface Props {
  integration: AppIntegrationDocumentViewProps;
  classes?: any;
}

class IntegrationComponent extends Component<Props, any, any> {
  render() {
    const { integration, classes } = this.props;
    return (
      <a href={integration.appUrl} target="_blank" rel="noopener noreferrer">
        <div className={`${classes.container} semibold`}>
          <Grid container className="fullHeight" justify="center" alignContent="center">
            <Grid item>
              <img style={{ width: '100%' }} src={integration.imageUrl} alt={integration.name} />
            </Grid>
          </Grid>
          <div className={classes.overlay}>
            <div className={classes.integrationName}>{integration.name}</div>
          </div>
        </div>
      </a>
    );
  }
}

export default withStyles(styles)(IntegrationComponent);
