import { CoreTranslations } from '../interfaces/ICoreTranslations';

export const core_translations_nl: CoreTranslations = {
  toolbar_avatar_popup_switch_or_add_user: 'Gebruiker toevoegen/wisselen',
  toolbar_avatar_popup_change_language: 'Taal wijzigen',
  toolbar_questionnaire_no_name: ' ',
  logout: 'Uitloggen',
  login: 'Inloggen',
  add_user_dialog_add_user: 'Gebruiker toevoegen',
  add_user_dialog_label: 'Naam van de nieuwe gebruiker',
  add_user_dialog_placeholder: 'Naam van de nieuwe gebruiker',
  add_user_dialog_create_user: 'Gebruiker toevoegen',
  add_user_dialog_username_too_short: 'Naam is te kort',
  add_user_dialog_username_too_long: 'Naam is te lang',
  remove_user_dialog_are_you_sure: 'Weet je zeker dat je deze gebruiker wil verwijderen?',
  remove_user_dialog_remove: 'Verwijder',
  switch_user_dialog_title: 'Gebruiker toevoegen/wisselen',
  switch_user_dialog_add: 'Toevoegen',
  change_language_dialog_title: 'Taal wijzigen',
  change_language_dialog_change: 'Wijzig'
};
