import * as React from 'react';
import AuthenticationPageView, {FORGOTPASSWORD} from '../../authentication/containers/AuthenticationPageView';
import ForgotPasswordForm from './ForgotPasswordForm';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Component } from 'react';

interface Props extends RouteComponentProps {}

class ForgotPasswordPage extends Component<Props> {
  render() {
    const { history } = this.props;
    return (
      <AuthenticationPageView headerType={FORGOTPASSWORD}>
        <ForgotPasswordForm onReset={() => history.push('/')} />
      </AuthenticationPageView>
    );
  }
}

export default withRouter(ForgotPasswordPage);
