import { Component } from 'react';
import * as React from 'react';
import { State } from '../../redux/Reducers';

import { connect } from 'react-redux';
import LoadingIndicator from '../components/static/LoadingIndicator';

interface Props {
  children: any;
  currentUser?: User;
}

interface ComponentState {
  authToken?: string;
}

export interface AuthenticatedProps {
  authToken?: string;
}

class AuthenticatedPageWithLoading extends Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = { authToken: undefined };
  }

  updateAuthToken() {
    const { currentUser } = this.props;
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        const { authToken } = this.state;
        if (authToken !== token) {
          this.setState({ authToken: token });
        }
      });
    }
  }

  componentDidMount(): void {
    this.updateAuthToken();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
    this.updateAuthToken();
  }

  render() {
    const { children, currentUser } = this.props;
    const { authToken } = this.state;

    let authenticatedChildren: any = [];
    if (currentUser && authToken) {
      const childrenArray = React.Children.toArray(children);
      for (let child of childrenArray) {
        // @ts-ignore
        authenticatedChildren.push(React.cloneElement(child, { ...child.props, authToken: authToken }));
      }
    }
    return <>{authToken ? authenticatedChildren : <LoadingIndicator />}</>;
  }
}

const mapStateToProps = (state: State) => {
  return {
    currentUser: state.authentication.currentUser
  };
};

export default connect(mapStateToProps, {})(AuthenticatedPageWithLoading);
