import { Component } from 'react';
import * as React from 'react';
import { AppBar, Box, createStyles, Grid, StyledComponentProps, Toolbar, withStyles } from '@material-ui/core';
import style from '../../../styling/Style';

const styles = createStyles({
  appBar: {
    backgroundColor: 'transparent'
  }
});

class AuthenticationNavbar extends Component<StyledComponentProps> {
  render() {
    const classes = this.props.classes || {};
    return (
      <AppBar elevation={0} className={classes.appBar}>
        <Toolbar>
          {/* Grid to make appbar appear smaller */}
          <Grid container>
            <Grid item xs={12} md={7}>
              <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>
                <Grid item xs={2}>
                  <img src={style.flavourIdLogo} alt={'Flavour ID'} />
                </Grid>
                <Grid item xs={10}>
                  <Box textAlign={'right'}>{this.props.children}</Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(AuthenticationNavbar);
