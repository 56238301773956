import React from "react";
import {Props} from './index.d';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles((theme: any)=>({
    root:{
        ...theme.cards.card
    }
}));

export const Card: React.FC<Props> =
    ({
        children
     }) =>
    {
        const classes = useStyles();

        return (
            <div className={classes.root}>
                {children}
            </div>
        )
    }
