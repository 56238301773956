import * as React from 'react';
import { Grid, Box, FormHelperText, Typography} from '@material-ui/core';
import firebase from 'firebase/app';
import { createStyles, withStyles } from '@material-ui/core/styles';
import StyledButton from '../../../styling/StyledButton';
import StyledTextField from '../../../styling/StyledTextField';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { withRouter, RouteComponentProps  } from 'react-router-dom';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import style from "../../../styling/Style";
import NormalBlock from "../../../styling/blocks/Normal";

interface Props extends RouteComponentProps {
  classes: {
    resetPasswordTitle: string
  };
  intl: IntlShape;
  actionCode?: string;
}

const styles = createStyles({
  resetPasswordTitle: {
    ...style.typography.greatPrimer
  }
});

const ResetPasswordSchema = (props: Props) => {
  const { intl } = props;
  return Yup.object().shape({
    password: Yup.string()
      .min(8, intl.formatMessage({ id: 'authentication_password_too_short' }))
      .max(50, 'authentication_password_too_long')
      .required(intl.formatMessage({ id: 'authentication_required' })),
    global: Yup.string().notRequired()
  });
};

enum Status {
  Unknown = "unknown",
  InProgress = "in_progress",
  Success = "success",
  Failure = "failure"
};

interface State {
  status: Status;
  accountEmail?: string;
}

class ResetPasswordForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {status: Status.Unknown, accountEmail: undefined};
  }

  componentDidMount() {
    const { actionCode } = this.props;
    const auth = firebase.auth();
    if (!actionCode) {
      this.setState({status: Status.Failure, accountEmail: undefined});
      return;
    }

    // Verify the password reset code is valid.
    auth.verifyPasswordResetCode(actionCode).then((email: string) => {
      this.setState({status: Status.InProgress, accountEmail: email});
    }).catch((error: any) => {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
      this.setState({status: Status.Failure, accountEmail: undefined});
    });
  }

  onSubmit(values: any, formikHelpers: FormikHelpers<any>) {
    const newPassword = values.password;
    const { actionCode } = this.props;
    const auth = firebase.auth();

    if (this.state.status === Status.InProgress && actionCode) {
      // Save the new password.
      auth.confirmPasswordReset(actionCode, newPassword).then((resp: any) => {
	// Password reset has been confirmed and new password updated.

	// TODO: Display a link back to the app, or sign-in the user directly
	// if the page belongs to the same domain as the app:
	// auth.signInWithEmailAndPassword(accountEmail, newPassword);

	// TODO: If a continue URL is available, display a button which on
	// click redirects the user back to the app via continueUrl with
	// additional state determined from that URL's parameters.
	this.setState({status: Status.Success});
      }).catch((error: any) => {
	// Error occurred during confirmation. The code might have expired or the
	// password is too weak.
	//this.setState({status: Status.Failure, accountEmail: undefined});
        const errorMessage = error.message;
        formikHelpers.setErrors({
          global: errorMessage
        });
        this.setState({status: Status.Failure, accountEmail: undefined});
      });
    } else {
      formikHelpers.setErrors({
        global: "Invalid reset code" // todo: translate
      });
      this.setState({status: Status.Failure, accountEmail: undefined});
    }
  }

  getQueryParams() {
    return new URLSearchParams(this.props.location.search);
  }

  render() {
    const { intl, history } = this.props;
    const { status } = this.state;
    const classes = this.props.classes || {};

    // todo: move success/failure to parent page, and move up status state to parent
    return (status === Status.Success || status === Status.Failure)? (
      <Grid container>
        <Grid container alignItems="center" justify="center">
        {/*
          <Grid item>
            {status === Status.Success && <VerifiedIcon />}
          </Grid>
          */}
          <Grid item>
            <NormalBlock title={<FormattedMessage id={`reset_password_title_${status}`} />}>
              <FormattedMessage id={`reset_password_callback_form_description_${status}`} />
            </NormalBlock>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <StyledButton size="lg" type="submit" onClick={() => history.push(status === Status.Success? "/signin": "/forgot-password")}>
              <FormattedMessage id={`reset_password_button_${status}`} />
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    ): (
      <Formik
        initialValues={{
          password: '',
          global: ''
        }}
        validationSchema={ResetPasswordSchema(this.props)}
        onSubmit={(values, formikHelpers) => this.onSubmit(values, formikHelpers)}
      >
        {({ handleSubmit, setErrors, values }) => {
          return (
            <Form translate="yes" onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Box textAlign="left" mb={8}>
                    <Typography className={classes.resetPasswordTitle}>
                      <FormattedMessage id={'reset_password_title'} />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1}>
                    <Field name="password" type="password">
                      {(field: FieldProps<any>) => (
                        <StyledTextField
                          fullWidth={true}
                          label={<FormattedMessage id={'authentication_password_label'} />}
                          placeholder={intl.formatMessage({ id: 'authentication_password_placeholder' })}
                          autoComplete="current-password"
                          margin="normal"
                          passwordToggle={true}
                          type="password"
                          {...field}
                        />
                      )}
                    </Field>

                  </Box>
                  <div>
                    <Field name="global">{(field: FieldProps<any>) => <FormHelperText error={true}>{field.meta.error}</FormHelperText>}</Field>
                  </div>
                </Grid>
                <Grid item xs={12} style={{marginTop:10}}>
                  <Box mt={5} mb={6}>
                    <StyledButton size="lg" type="submit" disabled={values.password.length<1}>
                      <FormattedMessage id={'reset_password_form_submit_button'} />
                    </StyledButton>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default injectIntl(withStyles(styles)(withRouter(ResetPasswordForm)));
