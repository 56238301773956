import translations_en from '../en/Translations_EN';
import translations_nl from '../nl/Translations_NL';
import { EN, NL } from '../../Languages';
import { HomepageTranslations } from './IHomePageTranslations';

export interface Translations extends HomepageTranslations, Record<string, string> {}

const translations: { [key: string]: Record<string, string> } = {
  [EN.locale]: translations_en,
  [NL.locale]: translations_nl
};

export default translations;
