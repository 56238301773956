import type { Props } from "./index.d";
import React from "react";
import Paragraph from "../../components/Paragraph";
import Typography from "../../components/Typography";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles((theme: any)=>({
    root:{
        ...theme.headings.heading
    },
    title:{
        ...theme.headings.title,
        textAlign: props => props.titleAlign
    },
    paragraph:{
        ...theme.headings.paragraph,
        textAlign: props => props.paragraphAlign
    }
}));

export const Heading: React.FC<Props> =
({
    children,
    title,
    paragraph,
    titleAlign = 'left',
    paragraphAlign = 'left',
    as = 'div',
    ...props
}) => {

    const classes = useStyles({titleAlign, paragraphAlign});

    return (
        <div className={classes.root} {...props}>
            {React.createElement(as, {className: classes.title}, title)}
            { paragraph && (
                <Paragraph className={classes.paragraph}>
                    <Typography text={paragraph} />
                </Paragraph>
            )}
            {children}
        </div>
    );
}
export default Heading;
