import * as React from 'react';
import { RatingQuestionViewProps } from '../../../../../models/questiontype-models/internal/QuestionTypeModels';
import QuestionTypeContainer from '../../QuestionTypeContainer';
import { QuestionProps } from '../../../../../models/questionnaire-models/internal/QuestionModels';
import RatingIconSetMap from '../RatingIconSetMap';
import I18NFromObjectComponent from '../../../../../shared/components/I18NFromObjectComponent';
import {FormattedMessage} from "react-intl";
import style from "../../../../../styling/Style";
import {StyledComponentProps, withStyles} from "@material-ui/core";
import RatingComponent from "@design-system/components/Rating";

interface Props extends QuestionProps, RatingQuestionViewProps, StyledComponentProps {}
interface State{
    isRatingHover: boolean;
}

const styles = {
    iconActive: {},
    dontKnowContainer:{
        ...style.typography.bravier,
        color:style.colors.grayscale[4]
    }
};

class RatingQuestionView extends QuestionTypeContainer<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isRatingHover: false
        }
    }
  render() {
    const { name, value, iconSet, dontKnowInstruction } = this.props;

    const min = this.props.min || 0;

    const valueFromZero = this.getValue(value, this.props) - min + 1 || null;

    const iconProps =
        iconSet && RatingIconSetMap[iconSet]
        ? {
            IconContainerComponent: RatingIconSetMap[iconSet](valueFromZero || undefined, this.state.isRatingHover)
          }
        : {
            IconContainerComponent: RatingIconSetMap['star'](valueFromZero || undefined, this.state.isRatingHover)
          };

    console.log(iconProps);

    return (
      <I18NFromObjectComponent object={name}>
        {(value: any) => (
            <>
                <RatingComponent
                    noSelection={
                        dontKnowInstruction ?
                            <I18NFromObjectComponent object={dontKnowInstruction}>
                                {(value: any) => value}
                            </I18NFromObjectComponent>
                            :
                            <FormattedMessage id={'skip_if_you_dont_know'} />
                    }
                    value={valueFromZero}
                    onChange={(value)=>{
                        this.handleChange(min + value - 1, this.props);
                    }}
                    items={[
                        {
                            value: 1,
                            icon: <img src={'/questionnaire/emotions/selected/dislike_very_much.svg'} alt="Dislike very much filled" style={{ margin: 9 }} />,
                            label: <FormattedMessage id={'rating_value_1'} />
                        },
                        {
                            value: 2,
                            icon: <img src={'/questionnaire/emotions/selected/dislike.svg'} alt="Dislike filled" style={{ margin: 9 }} />,
                            label: <FormattedMessage id={'rating_value_2'} />
                        },
                        {
                            value: 3,
                            icon: <img src={'/questionnaire/emotions/selected/neutral.svg'} alt="Neutral filled" style={{ margin: 9 }} />,
                            label: <FormattedMessage id={'rating_value_3'} />
                        },
                        {
                            value: 4,
                            icon: <img src={'/questionnaire/emotions/selected/like.svg'} alt="Like filled" style={{ margin: 9 }} />,
                            label: <FormattedMessage id={'rating_value_4'} />
                        },
                        {
                            value: 5,
                            icon: <img src={'/questionnaire/emotions/selected/like_very_much.svg'} alt="Like very much filled" style={{ margin: 9 }} />,
                            label: <FormattedMessage id={'rating_value_5'} />
                        }
                    ]}
                />
                {/*<Rating
                    name={value}
                    value={valueFromZero}
                    precision={precision || 1}
                    classes={{
                        iconActive: classes && classes.iconActive
                    }}
                    onChange={(e: object, value: any) => {
                        this.handleChange(min + value - 1, this.props);
                        this.setState({isRatingHover: false})
                    }}
                    onChangeActive={(event, newHover) => {
                        this.setState({isRatingHover: newHover > -1})
                    }}
                    max={newMax}
                    {...iconProps}
                />
                <div className={classes?.dontKnowContainer} style={{visibility: valueFromZero || this.state.isRatingHover ? 'hidden' : 'visible'}}>
                    {
                        dontKnowInstruction ?
                            <I18NFromObjectComponent object={dontKnowInstruction}>
                                {(value: any) => value}
                            </I18NFromObjectComponent>
                        :
                            <FormattedMessage id={'skip_if_you_dont_know'} />
                    }
                </div>*/}
            </>
        )}
      </I18NFromObjectComponent>
    );
  }
}

export default withStyles(styles)(RatingQuestionView);
