import { Component } from 'react';
import * as React from 'react';
import { StyledComponentProps } from '@material-ui/core';
import AuthenticationNavbar from "./AuthenticationNavbar";

interface Props extends StyledComponentProps {}

export default class AuthenticationNavbarHalf extends Component<Props, any, any> {
  render() {
    return <AuthenticationNavbar useSmallSize={true} />;
  }
}
