import { Component } from 'react';
import * as React from 'react';
import QuestionnaireApiDetailView, { ValueType } from '../../../API/containers/QuestionnaireAPIDetailFetcher';
import AuthenticatedPageWithLoading from '../../../shared/containers/AuthenticatedPageWithLoading';
import { RouteComponentProps } from 'react-router-dom';
import QuestionnaireFinishedView from '../components/QuestionnaireFinishedView';

interface MatchParams {
  id: string;
}

export default class QuestionnaireCompletePage extends Component<RouteComponentProps<MatchParams>> {
  render() {
    const { match } = this.props;
    return (
      <div>
        <AuthenticatedPageWithLoading>
          <QuestionnaireApiDetailView questionnaireId={match.params['id']}>{(values: ValueType) => <QuestionnaireFinishedView questionnaire={values.questionnaire} />}</QuestionnaireApiDetailView>
        </AuthenticatedPageWithLoading>
      </div>
    );
  }
}
