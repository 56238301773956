import { authenticationInitialState, AuthenticationState, AuthenticationReducers } from './authentication/LoginReducers';
import { firebaseInitialState, FirebaseReducers, FirebaseState } from './firebase/FirebaseReducers';
import { LanguageInitialState, LanguageReducers, LanguageState } from './language/LanguageReducers';
import { QuestionnaireInitialState, QuestionnaireReducers, QuestionnaireState } from './questionnaire/QuestionnaireReducers';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer } from 'redux-persist';

const allReducers: any = {
  ...AuthenticationReducers,
  ...FirebaseReducers,
  ...LanguageReducers,
  ...QuestionnaireReducers
};

export interface State {
  authentication: AuthenticationState;
  firebase: FirebaseState;
  language: LanguageState;
  questionnaire: QuestionnaireState;
}

const initialState: State = {
  authentication: authenticationInitialState,
  firebase: firebaseInitialState,
  language: LanguageInitialState,
  questionnaire: QuestionnaireInitialState
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication']
};

export const reduxStore = persistReducer(persistConfig, function reduxStore(state: State = initialState, action: any) {
  if (allReducers.hasOwnProperty(action.type)) {
    return allReducers[action.type](state, action);
  } else {
    return state;
  }
});
