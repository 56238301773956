import { Component } from 'react';
import * as React from 'react';
import { withStyles, Box } from '@material-ui/core';
import AuthenticatedPageWithLoading from '../../../shared/containers/AuthenticatedPageWithLoading';
import QuestionnaireProgressComponent from './QuestionnaireProgressComponent';
import QuestionnaireApiDetailView, { ValueType } from '../../../API/containers/QuestionnaireAPIDetailFetcher';
import StyledButton from '../../../styling/StyledButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DEFAULT_QUESTIONNAIRE } from '../../../constants/QuestionnaireConstants';
import style from '../../../styling/Style';
import { FormattedMessage } from 'react-intl';
import StyledParagraph from '../../../styling/StyledParagraph';
import StyledTitle from '../../../styling/StyledTitle';
import StyledCard from '../../../styling/StyledCard';

const styles = {};

interface Props extends RouteComponentProps {
  completed?: number;
  classes: any;
}

class FlavourIdTestComponent extends Component<Props, any, any> {
  render() {
    const { history } = this.props;
    return (
      <Box mx={4}>
        <StyledCard size="lg">
          <div style={{ textAlign: 'center' }}>
            <StyledTitle variation="small" element="h4">
              <FormattedMessage id={'flavoridtest_header'}></FormattedMessage>
            </StyledTitle>
            <AuthenticatedPageWithLoading>
              <QuestionnaireApiDetailView questionnaireId={DEFAULT_QUESTIONNAIRE}>
                {(questionnaire: ValueType) => (
                  <React.Fragment>
                    <QuestionnaireProgressComponent questionnaire={questionnaire.questionnaire} />
                    <Box p={6}>
                      <StyledParagraph variation="minion" color={style.colors.grayscale[2]}>
                        <FormattedMessage id={'flavoridtest_description'}></FormattedMessage>
                      </StyledParagraph>
                    </Box>
                    <StyledButton  onClick={() => history.push('/questionnaire/' + DEFAULT_QUESTIONNAIRE)}>
                      <FormattedMessage id={questionnaire.questionnaire.completed === 1 ? 'flavoridtest_review' : 'flavoridtest_complete'}></FormattedMessage>
                    </StyledButton>
                  </React.Fragment>
                )}
              </QuestionnaireApiDetailView>
            </AuthenticatedPageWithLoading>
          </div>
        </StyledCard>
      </Box>
    );
  }
}

export default withStyles(styles)(withRouter(FlavourIdTestComponent));
