import { PromiseState } from 'react-refetch';
import { Component, PropsWithChildren } from 'react';
import * as React from 'react';
import { AuthenticatedProps } from '../../../shared/containers/AuthenticatedPageWithLoading';
import { QuestionnaireInformation } from '../../../models/questionnaire-models/internal/QuestionnaireModels';
import PromiseStatePage from '../../../shared/containers/PromiseStatePage';

interface Props extends PropsWithChildren<any>, AuthenticatedProps {
  questionnaires: PromiseState<Array<QuestionnaireInformation>>;
}

export default class APIQuestionnaireOverview extends Component<Props, any> {
  /**
   * Wrapper that can be used to filter the questionnaires.
   * This wrapper takes all questionnaires from the store, other wrappers might filter based on properties.
   */
  render() {
    const { questionnaires, children } = this.props;
    return <PromiseStatePage promiseStateMapping={{ questionnaires: questionnaires }}>{children}</PromiseStatePage>;
  }
}
