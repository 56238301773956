import { IconContainerProps } from '@material-ui/lab/Rating';
import * as React from 'react';
import {FormattedMessage} from "react-intl";
import style from "../../../../styling/Style";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    ratingIconContainer:{
        position:'relative',
        color: style.colors.grayscale[1],
        '&:hover':{
            color: style.colors.brand.brandGreen,
            '& div':{
                visibility: 'visible'
            }
        }
    },
    ratingDescription:{
        fontSize: 14,
        position:'absolute',
        left:'50%',
        textAlign:'center'
    }
});

interface IconSetProps extends IconContainerProps {
  icons: {
    [key: number]: {
      icon: any;
      emptyIcon?: any;
    };
  };
  current?: number;
  isHover?: boolean;
}

interface SingleIconProps extends IconContainerProps {
  emptyIcon: any;
  icon: any;
}

export function IconArrayContainer(props: IconSetProps) {
  const { value, icons, ...other } = props;
  const filled = other && other.className ? other.className.includes('iconFilled') : false;
  return (
    <div>
      <span {...other} style={{ display: filled ? 'inherit' : 'none' }}>
        {icons[value].icon}
      </span>
      <span {...other} style={{ display: filled ? 'none' : 'inherit' }}>
        {icons[value].emptyIcon || icons[value].icon}
      </span>
    </div>
  );
}

export function IconArraysOnlyCurrentContainer(props: IconSetProps) {
  const { value, icons, current, isHover, ...other } = props;
  const classes = useStyles({});
  // const filled = other && other.className ? other.className.includes('iconFilled') : false;
  return (
    <div className={classes.ratingIconContainer}>
      <span {...other} style={{ display: current === value ? 'inherit' : 'none' }}>
        {icons[value].icon}
      </span>
      <span {...other} style={{ display: current === value ? 'none' : 'inherit' }}>
        {icons[value].emptyIcon || icons[value].icon}
      </span>
        <div style={{visibility: (current === value && ! isHover) ? 'visible' : 'hidden'}} className={classes.ratingDescription}>
            <div style={{position:'relative', left:'-50%', whiteSpace:'nowrap'}}>
                <FormattedMessage id={'rating_value_'+value} />
            </div>
        </div>
    </div>
  );
}

export function IconContainer(props: SingleIconProps) {
  const { emptyIcon, icon, ...other } = props;
  const filled = other && other.className ? other.className.includes('iconFilled') : false;

  return (
    <div>
      <span {...other} style={{ display: filled ? 'inherit' : 'none' }}>
        {icon}
      </span>
      <span {...other} style={{ display: filled ? 'none' : 'inherit' }}>
        {emptyIcon}
      </span>
    </div>
  );
}

const ratingIconSetMap: { [key: string]: (value?: number, isHover?: boolean) => (props: IconContainerProps) => any } = {
  smiley: (value?: number, isHover?: boolean) => (props: IconContainerProps) => (
    <IconArraysOnlyCurrentContainer
      current={value}
      isHover={isHover}
      icons={{
        1: {
          icon: <img src={'/questionnaire/emotions/selected/dislike_very_much.svg'} alt="Dislike very much filled" style={{ margin: 9 }} />,
          emptyIcon: <img src={'/questionnaire/emotions/unselected/dislike_very_much.svg'} alt="Dislike very much empty" style={{ margin: 9 }} />
        },
        2: {
          icon: <img src={'/questionnaire/emotions/selected/dislike.svg'} alt="Dislike filled" style={{ margin: 9 }} />,
          emptyIcon: <img src={'/questionnaire/emotions/unselected/dislike.svg'} alt="Dislike empty" style={{ margin: 9 }} />
        },
        3: {
          icon: <img src={'/questionnaire/emotions/selected/neutral.svg'} alt="Neutral filled" style={{ margin: 9 }} />,
          emptyIcon: <img src={'/questionnaire/emotions/unselected/neutral.svg'} alt="Neutral empty" style={{ margin: 9 }} />
        },
        4: {
          icon: <img src={'/questionnaire/emotions/selected/like.svg'} alt="Like filled" style={{ margin: 9 }} />,
          emptyIcon: <img src={'/questionnaire/emotions/unselected/like.svg'} alt="Like empty" style={{ margin: 9 }} />
        },
        5: {
          icon: <img src={'/questionnaire/emotions/selected/like_very_much.svg'} alt="Like very much filled" style={{ margin: 9 }} />,
          emptyIcon: <img src={'/questionnaire/emotions/unselected/like_very_much.svg'} alt="Like very much empty" style={{ margin: 9 }} />
        }
      }}
      {...props}
    />
  ),
  star: (value) => (props: IconContainerProps) => (
    <IconContainer
      emptyIcon={<img src={'/questionnaire/star/star_empty.svg'} alt="Empty icon" style={{ margin: 9 }} />}
      icon={<img src={'/questionnaire/star/star.svg'} alt="Filled icon" style={{ margin: 9 }} />}
      {...props}
    />
  )
};

export default ratingIconSetMap;
