import { Component } from 'react';
import * as React from 'react';
import AuthenticationView from '../../containers/AuthenticationView';
import { SIGNIN } from '../../AuthenticationViewTypes';

class SignInView extends Component<any, any> {
  render() {
    return <AuthenticationView type={SIGNIN} />;
  }
}

export default SignInView;
