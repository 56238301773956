import {AromaColorStyleInterface} from "./aroma";

const colors: AromaColorStyleInterface = {
    baseColorPalette: {
        white: '#FFFFFF',
        black: '#000000',
        transparent: 'transparent'
    },
    brand: {
        limeGreen: '#CADA20',
        brandGreen: '#2F998D',
        brandGreenLight: '#01B7AC',
        vividGreen: '#34C7B1',
        blueGreen: '#226073',
        darkGreen: '#104859'
    },
    grayscale: {
        1: '#2D3940',
        2: '#505D64',
        3: '#717D83',
        4: '#B8C3C8',
        5: '#CED5D9',
        6: '#E8EDED',
        7: '#F7F9FA'
    },
    system: {
        success: '#57A81A',
        danger: '#D83F2A',
        dangerLight: '#FDF1F4',
        warning: '#ECB90D',
        link: '#43a3e8'
    },
    accents: {
        lightblue: '#00ACEE',
        lovelyred: '#D44638',
        cutegreen: '#34C7B1',
        facebookblue: '#3b5998'
    },

    // Aromas and testes. These names are taken from the design itself and correspond to the color names.
    // These names should be changed to their function within the design to decouple the name from the color itself.
    aromas: {
        spicy: '#E04350',
        caramel: '#EA7949',
        fruity: '#FFA254',
        cheese: '#FFD74A',
        chemical: '#51506C',
        herbal: '#51849A',
        green: '#1F9C85',
        vegetable: '#A3C268',
        citrus: '#D4F156',
        floral: '#95207B',
        animal: '#D3295C',
        roasted: '#652831',
        woody: '#A9494D',
        nutty: '#CDA75D'
    },
    tastes: {
        salt: '#B8C3C8',
        bitter: '#776574',
        sour: '#D3E36F',
        sweet: '#E46E61',
        umami: '#614D3C',
        pungent: '#B23548'
    },
    flavorCircle:{
        background: '#2D3940',
        text: '#F7F9FA'
    }
};

export default colors;
