import * as React from 'react';
import { Grid } from '@material-ui/core';
import { AuthenticationViewProps, AuthenticationViewType } from '../authentication-types/AuthenticationTypesInterfaces';

import { createStyles, withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { PropsWithChildren } from 'react';
import style from '../../../styling/Style';
import LogoComponent from "../../../shared/components/static/LogoComponent";

interface State {
  email: string;
  password: string;
}

interface Props extends AuthenticationViewProps, PropsWithChildren<any> {
  onAuthenticate: (currentUser?: User) => void;
  headerType: 'signin' | 'signup' | 'resetpassword';
  classes: {
    appBar: string;
    landingImage: string;
    fullViewHeight: string;
    fullHeight: string;
  };
}

const styles = createStyles({
  appBar: {
    backgroundColor: 'transparent'
  },
  landingImage: {
    backgroundImage: `url(${style.homepageHeaderImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
    height: '100%',
    width: '100%'
  },
  fullViewHeight: {
    minHeight: '100vh'
  },
  fullHeight: {
    height: '100%'
  }
});

export const SIGNIN = 'signin';
export const SIGNUP = 'signup';
export const RESETPASSWORD = 'resetpassword';
export const QUESTIONNAIRE = 'questionnaire';
export const FORGOTPASSWORD = 'forgotpassword';

const AuthPageModes: any = {
  'signin':{
    showLogo: false,
    withImage: true
  },
  'signup':{
    showLogo: false,
    withImage: true
  },
  'resetpassword':{
    showLogo: false,
    withImage: false
  },
  'questionnaire':{
    showLogo: false,
    withImage: true
  },
  'forgotpassword':{
    showLogo: false,
    withImage: false
  }
}

class AuthenticationPageView extends AuthenticationViewType<Props, State> {

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;
    const headerType = this.props.headerType?.toLowerCase();
    const PageMode = AuthPageModes[headerType ? headerType : 'signin'];
    const withImage = PageMode.withImage;
    const showLogo = PageMode.showLogo;


    return (
      <Grid container className={classes.fullViewHeight}>

        {/* Left half */}
        <Grid item xs={12} sm={12} {...withImage? { md:7, lg:7 }:null}>
          {/* Form */}
          <Grid className={classes.fullHeight} style={{ paddingTop: 0 }} container alignItems={showLogo?'stretch':'center'} justify="center">
            { showLogo &&
            <Grid container justify="center" alignItems="center">
              <LogoComponent size="large" />
            </Grid>}
            <Grid item xs={10} style={{maxWidth:380}}>
              {this.props.children}
            </Grid>
          </Grid>
        </Grid>

        {/* Right half, hidden if >= sm */}
        {withImage &&
          <Hidden smDown>
            <Grid item md={5} lg={5}>
              <div className={classes.landingImage} />
            </Grid>
          </Hidden>}
      </Grid>
    );
  }
}

export default withStyles(styles)(AuthenticationPageView);
