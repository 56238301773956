import { Component } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../redux/Reducers';
import * as React from 'react';
import { Button } from '@material-ui/core';
import { Dispatch } from 'redux';
import { logout } from '../../../redux/authentication/LoginActionCreators';
import { FormattedMessage } from 'react-intl';

interface Props {
  isAuthenticated?: boolean;
  onClick?: any;
  buttonProps?: any;
  text?: any;
}

class LoginButton extends Component<Props, any> {
  render() {
    const { isAuthenticated, onClick, buttonProps, text } = this.props;
    return (
      <Button {...buttonProps} onClick={() => onClick(isAuthenticated)}>
        {text ? text : isAuthenticated ? <FormattedMessage id={'logout'} /> : <FormattedMessage id={'login'} />}
      </Button>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    isAuthenticated: state.authentication.authenticated
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onClick: (isAuthenticated: boolean) => {
      // TODO: update this to contain the correct login logic
      if (isAuthenticated) {
        dispatch(logout());
      } else {
        // TODO: do the redirect onClick
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);
