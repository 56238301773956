import { Component } from 'react';
import * as React from 'react';
import style from '../../../styling/Style';
import { NavLink } from 'react-router-dom';
import { StyledComponentProps, withStyles, Box } from '@material-ui/core';
import { theme } from '../../../styling/DefaultTheme';

interface Props extends StyledComponentProps {
  link?: boolean;
  size?: 'small' | 'normal' | 'large'
}

const styles = {
  imageContainer: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '110px',

      '& img': {
        maxWidth: '100%'
      }
    }
  }
};

class LogoComponent extends Component<Props> {
  render() {
    const classes = this.props.classes || {};

    return this.props.link ? (
      <NavLink to={'/'}>
        <Box pl={[0, 2, 2]} className={classes.imageContainer}>
          <img alt="Logo" src={style.flavourIdLogo} />
        </Box>
      </NavLink>
    ) : (
      <Box pl={[0, 2, 2]} className={classes.imageContainer}>
        <img alt="Logo" src={style.flavourIdLogo} />
      </Box>
    );
  }
}

export default withStyles(styles)(LogoComponent);
