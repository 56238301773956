import { Component } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Box, withStyles, StyledComponentProps } from '@material-ui/core';
import 'firebase/auth';
import Menu from '@material-ui/core/Menu';
import FunctionChildrenProps from '../../../utils/FunctionChildrenProps';
import { State } from '../../../redux/Reducers';
import CurrentUserAvatar from './CurrentUserAvatar';

const styles = {
  avatarIcon: {
    padding: 0,
    minWidth: 0
  }
};

interface Props extends StyledComponentProps, FunctionChildrenProps<any> {}

interface LocalState {
  anchorEl?: Element;
}

class ProfileAvatarComponent extends Component<Props, LocalState> {
  constructor(props: FunctionChildrenProps<any>) {
    super(props);
    this.state = {
      anchorEl: undefined
    };
  }

  render() {
    const classes = this.props.classes || {};
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handleClose = () => {
      this.setState({ anchorEl: undefined });
    };

    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        <Button onClick={handleClick} className={classes.avatarIcon}>
          <Box pr={[0, 1, 2, 2]}>
            <CurrentUserAvatar usesSecondaryUser={true} />
          </Box>
        </Button>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
        >
          {this.props.children(handleClose)}
        </Menu>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: State) => ({
    flavourIdUser: state.authentication.flavourIdUser
  }),
  () => ({})
)(withStyles(styles)(ProfileAvatarComponent));
