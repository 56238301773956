import { AppIntegrationActivation, AppIntegrationActivationDocument, AppIntegrationDocument, AppIntegrationEnumeration } from '../external/IntegrationModels';
import { AppIntegrationActivationDocumentViewProps, AppIntegrationActivationViewProps, AppIntegrationDocumentViewProps, AppIntegrationEnumerationViewProps } from '../internal/IntegrationModels';

export const transformIntegrationTypeProps: (inputData: any, integrationType: string) => any = (inputData, integrationType) => {
  return typeMapping[integrationType](inputData);
};

export const transformAppIntegrationActivationTypeProps: (inputData: AppIntegrationActivation) => AppIntegrationActivationViewProps = (inputData) => {
  return {
    appId: inputData.appId,
    enabled: inputData.enabled
  };
};

export const transformAppIntegrationActivationDocumentTypeProps: (inputData: AppIntegrationActivationDocument) => AppIntegrationActivationDocumentViewProps = (inputData) => {
  return {
    appActivations: inputData.appActivations
  };
};

export const transformAppIntegrationDocumentTypeProps: (inputData: AppIntegrationDocument) => AppIntegrationDocumentViewProps = (inputData) => {
  return {
    appUrl: inputData.appUrl,
    description: inputData.description,
    htmlColor: inputData.htmlColor,
    id: inputData.id,
    imageUrl: inputData.imageUrl,
    name: inputData.name,
    rgbColor: inputData.rgbColor
  };
};

export const transformAppIntegrationEnumerationTypeProps: (inputData: AppIntegrationEnumeration) => AppIntegrationEnumerationViewProps = (inputData) => {
  return {
    appIntegrations: inputData.appIntegrations
  };
};

interface TypeMapping {
  [key: string]: (data: any) => any;
}

const typeMapping: TypeMapping = {
  activation: transformAppIntegrationActivationTypeProps,
  activationDocument: transformAppIntegrationActivationDocumentTypeProps,
  document: transformAppIntegrationDocumentTypeProps,
  enumeration: transformAppIntegrationEnumerationTypeProps
};
