import {SvgIcon} from "@material-ui/core";
import React from "react";

export function EmailSentIcon(props: any) {
    return (
        <SvgIcon {...props} width="48" height="41" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.4014 0.0817133C47.2314 0.00812006 47.0442 -0.0164459 46.861 0.010792C46.6778 0.0380299 46.5058 0.115986 46.3646 0.235848L0.351271 23.7768C0.191024 23.9127 0.0779348 24.0959 0.0282287 24.3001C-0.0214774 24.5043 -0.00524606 24.719 0.0745936 24.9133C0.154433 25.1077 0.293778 25.2718 0.472638 25.3821C0.651498 25.4923 0.86068 25.5431 1.07018 25.5271L13.2862 28.4959L20.169 39.9885C20.2587 40.1296 20.3826 40.2459 20.5292 40.3264C20.6759 40.407 20.8404 40.4492 21.0077 40.4492C21.0962 40.4491 21.1844 40.4373 21.2699 40.4143C21.4228 40.3724 21.5635 40.2946 21.6802 40.1873C21.7969 40.08 21.8863 39.9464 21.9408 39.7975L26.3962 31.0628L41.056 34.2692C41.2054 34.3018 41.3603 34.2994 41.5087 34.2623C41.657 34.2253 41.7948 34.1545 41.9114 34.0554C42.028 33.9564 42.1201 33.8319 42.1807 33.6914C42.2413 33.551 42.2687 33.3985 42.2608 33.2457L48 0.942737C47.9904 0.75767 47.9293 0.578969 47.8235 0.426819C47.7178 0.27467 47.5715 0.155134 47.4014 0.0817133ZM3.63152 23.7768L46.3646 1.28077L14.0484 26.1576L3.63152 23.7768ZM21.6124 28.7445C21.5474 28.8739 21.5111 29.0158 21.5061 29.1606L20.9081 36.3484L15.8268 26.9462L46.3646 1.28077L21.6124 28.7445ZM23.9665 28.4959L46.861 1.789L40.2074 32.0479L23.9665 28.4959Z" fill="#2F998D"/>
        </SvgIcon>

    );
}
