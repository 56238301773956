export interface Language {
  locale: string;
  humanName: string;
}

export const EN: Language = {
  locale: 'en',
  humanName: 'English'
};

export const NL: Language = {
  locale: 'nl',
  humanName: 'Nederlands'
};

export const LANGUAGES = [EN, NL];
export const LANGUAGE_BY_NAME: { [key: string]: Language } = LANGUAGES.reduce((o, key: Language) => ({ ...o, [key.locale]: key }), {});
