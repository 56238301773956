import { Component } from 'react';
import * as React from 'react';
import QuestionnaireCardView from './QuestionnaireCardView';
import { Questionnaire } from '../../../models/questionnaire-models/internal/QuestionnaireModels';
import StyledButton from '../../../styling/StyledButton';
import { Grid, Typography, Box, StyledComponentProps, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect as reduxConnect } from 'react-redux';
import { State } from '../../../redux/Reducers';
import { setCurrentQuestionnaire } from '../../../redux/questionnaire/QuestionnaireActionCreator';
import style from '../../../styling/Style';
import { FormattedMessage } from 'react-intl';
import { spacing } from "@design-system/themes/aroma/styles"

interface Props extends RouteComponentProps, StyledComponentProps {
  questionnaire: Questionnaire;
  setCurrentQuestionnaire: (questionnaire: Questionnaire) => void;
}
const styles = {
  questionnarieCompleteTitle: {
    ...style.typography.doublePica,
    color: style.colors.grayscale[1],
    marginTop: spacing[6]
  },
  questionnarieProgress: {
    ...style.typography.body_strong
  }
};

class QuestionnaireFinishedView extends Component<Props> {
  componentDidMount(): void {
    this.props.setCurrentQuestionnaire(this.props.questionnaire);
  }


  render() {
    const { history } = this.props;
    const classes = this.props.classes || {};

    return (
      <QuestionnaireCardView>
        <Grid container style={{ width: '100%', height: '100%', textAlign: 'center' }} justify="center" alignContent="center">
          <Grid item>
            <Typography className={classes.questionnarieCompleteTitle}>
              <FormattedMessage id={'questionnaire_complete'} />
            </Typography>
            {/* Finished percentage, hide for #829 */}
            {/*<Typography className={classes.questionnarieProgress} style={{ color: questionnaire.completed * 100 === 100 ? style.colors.system.success : style.colors.system.danger }}>
              {((questionnaire.completed || 0) * 100).toFixed(0)}
              <FormattedMessage id={'questionnaire_progress'} />!
            </Typography>*/}
            <Box py={8}>
              <StyledButton onClick={() => history.push('/')}>
                <FormattedMessage id={'questionnaire_complete_profile_button'} />
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      </QuestionnaireCardView>
    );
  }
}

export default reduxConnect(
  (state: State) => ({}),
  (dispatch) => ({
    setCurrentQuestionnaire: (questionnaire: Questionnaire) => dispatch(setCurrentQuestionnaire(questionnaire))
  })
)(withStyles(styles)(withRouter(QuestionnaireFinishedView)));
