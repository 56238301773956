import { Component, ReactNode } from 'react';
import * as React from 'react';
import LoadingIndicator from '../components/static/LoadingIndicator';

interface Props {
  endpoint: string;
  children: (value: any) => ReactNode;
  loadingComponent?: ReactNode;
}

interface State {
  value: any;
}

export default class StaticFetchComponent extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: undefined
    };
  }

  async componentDidMount() {
    const response = await fetch(this.props.endpoint);
    const value = await response.text();
    this.setState({ value: value });
  }

  render() {
    const LoadingComponent = this.props.loadingComponent;
    return <div>{this.props.children && this.state.value ? this.props.children(this.state.value) : LoadingComponent || <LoadingIndicator />}</div>;
  }
}
