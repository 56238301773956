import { ForgotPasswordPageTranslations } from '../interfaces/IForgotPasswordPageTranslations';

export const forgotpasswordpage_translations_nl: ForgotPasswordPageTranslations = {
  forgot_password_title: 'Paswoord vergeten',
  forgot_password_description: 'Geen probleem! Geef je e-mail adres in, en we verzenden je link om je paswoord opnieuw in te stellen.',
  send_button: 'Verzenden',
  back_to_login: 'Terug',
  resend_button: 'Opnieuw verzenden',
  resend_password_title: 'Gelieve je e-mail te controleren',
  we_sent_an_email_to: 'We hebben een e-mail gestuurd naar',
  check_email_message: 'Controleer je e-mail and volg de instructies.'
};
