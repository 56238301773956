import { Component } from 'react';
import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { UseStyleProps, withUseStyles } from '../hooks/withUseStyles';
import { QuestionStatuses } from '../../redux/questionnaire/QuestionnaireReducers';
import { FlexWrapProperty, OverflowXProperty, ScrollbarWidthProperty } from 'csstype';
import { theme } from '../../styling/DefaultTheme';
import style from "../../styling/Style";

const classes = makeStyles({
  orb: {
    borderRadius: '100%',
    height: 8,
    width: 8,
    zIndex: 1000,
  },
  orbTodo: {
    backgroundColor: style.colors.grayscale[6],
  },
  orbSkipped: {
    backgroundColor: theme.palette.grey[500],
  },
  orbDone: {
    backgroundColor: theme.palette.grey[200]
  },
  orbCurrent: {
    height: 12,
    width: 12,
    backgroundColor: theme.palette.primary.main
  },
  orbActive: {
    backgroundColor: style.colors.brand.brandGreen
  },
  orbCompleted: {
    backgroundColor: style.colors.grayscale[2]
  },
  line: {
    height: '2px',
    width: 28,
  },
  lineTodo: {
    backgroundColor: style.colors.grayscale[7] // TODO: fix this
  },
  lineCompleted:{
    backgroundColor: style.colors.grayscale[4]
  },
  noOverflow: {
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none' as ScrollbarWidthProperty,
    overflowX: 'scroll' as OverflowXProperty,
    flexWrap: 'nowrap' as FlexWrapProperty
  },
  orbLine: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  }
});

export const ORB_DONE = 'orbDone';
export const ORB_ACTIVE = 'orbActive';
export const ORB_TODO = 'orbTodo';
export const ORB_SKIPPED = 'orbSkipped';

export const STATES = [ORB_DONE, ORB_ACTIVE, ORB_TODO, ORB_SKIPPED];

interface Props extends UseStyleProps {
  questions: string[]; // List of questions
  currentIndex: number;
  onClick?: (index: number) => void;
  isOverview?: boolean;
}

const MAPPING: any = {
  DONE: ORB_DONE,
  IDLE: ORB_TODO,
  SKIPPED: ORB_SKIPPED,
  UNRECOGNIZED: ORB_TODO
};

export function getStates(statuses: QuestionStatuses) {
  return statuses.map((status) => MAPPING[status]);
}

class QuestionOrbLine extends Component<Props, any, any> {

  renderOrbs(classes: any, questions: string[], currentIndex: number) {
    return questions.map((question: string, index: number) => {
      let classNames = this.props.isOverview ? `${classes.orb} ${classes[question]}`  : `${classes.orb}`;
      if (index === currentIndex) {
        classNames += ` ${classes.orbCurrent}`;
      }else if (index < currentIndex) {
        classNames += ` ${classes.orbCompleted}`;
      }else{
        classNames += ` ${classes.orbTodo}`;
      }

      let line;
      if (index < currentIndex || (this.props.isOverview && question === 'orbDone' && index < questions.length - 1)) {
        line = <div className={`${classes.line} ${classes.lineCompleted}`} />;
      } else if (index !== questions.length - 1) {
        line = <div className={`${classes.line} ${classes.lineTodo}`} />;
      } else {
        line = <div />;
      }

      let onClick =
        this.props.onClick ||
        function (index: number) {
        };

      return (
        <Grid item key={index}>
          <Grid wrap={'nowrap'} container alignItems={'center'} justify={'center'}>
            <Grid item onClick={() => onClick(index)}>
              <div className={classNames}></div>
            </Grid>
            <Grid item>{line}</Grid>
          </Grid>
        </Grid>
      );
    });
  }

  render() {
    const { questions, classes, currentIndex } = this.props;

    return (
      <Grid container alignItems="center" className={`${classes.noOverflow}, ${classes.orbLine}`}>
        {this.renderOrbs(classes, questions, currentIndex)}
      </Grid>
    );
  }
}

export default withUseStyles(QuestionOrbLine, classes);
