import { Component } from 'react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import style from './Style';

const styles = {
  root: {
    color: style.colors.system.link,
    textDecoration: 'none'
  }
};

interface Props extends StyledComponentProps {
  path: string;
  children: any;
}

class StyledLink extends Component<Props, any> {
  render() {
    const classes = this.props.classes || {};
    const { path, children } = this.props;

    return (
      <>
        <NavLink to={path} className={classes.root}>
          {children}
        </NavLink>
      </>
    );
  }
}

export default withStyles(styles)(StyledLink);
