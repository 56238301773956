import * as React from 'react';
import AuthenticationPageView, { RESETPASSWORD } from '../../authentication/containers/AuthenticationPageView';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Component } from 'react';
import ResetPasswordForm from "./ResetPasswordForm";
import AuthenticationNavbar from "../../authentication/components/AuthenticationNavbar";

interface Props extends RouteComponentProps {
  actionCode?: string;
}

class ResetPasswordPage extends Component<Props> {
  render() {
    return (
      <AuthenticationPageView headerType={RESETPASSWORD}>
        <AuthenticationNavbar />
        <ResetPasswordForm actionCode={this.props.actionCode} />
      </AuthenticationPageView>
    );
  }
}

export default withRouter(ResetPasswordPage);
