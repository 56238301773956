import { AuthenticationPageTranslations } from '../interfaces/IAuthenticationPageTranslations';

export const authenticationpage_translations_en: AuthenticationPageTranslations = {
  authentication_email_label: 'Email Address',
  authentication_email_placeholder: 'Your Email Address',
  authentication_password_label: 'Password',
  authentication_password_placeholder: 'Your Password',
  signin_form_title: 'Login',
  signin_form_description: 'Please login to your FlavorID account',
  signin_form_forgot_password: 'Forgot password?',
  signin_form_login_button: 'Login',
  signin_form_other_methods: 'Or login with',
  signin_form_or: 'Or',
  no_account: "Don't have an account?",
  signup_button: 'Sign up',
  signup_form_title: 'Welcome to FlavorID',
  signup_form_description: 'Create your account and start...',
  signup_form_agree: 'I agree to FlavorID',
  signup_form_tos: 'Terms of Services',
  signup_form_and: 'and',
  signup_form_pp: 'Privacy Policy',
  signup_form_signup_button: 'Signup',
  signup_form_other_methods: 'Or register with',
  already_have_account: 'Have an account?',
  signin_button: 'Log in',
  authentication_email_invalid: 'Invalid email',
  authentication_required: 'Required',
  authentication_password_too_short: 'Password is too short',
  authentication_password_too_long: 'Password is too long',
  authentication_agree_to_terms: 'Please agree with our terms',
  sign_up_successful: 'You have succesfully signed up! Please check your mailbox.',
  signin_which_language: 'In which language would you like to complete the questionnaire?',
  email_verified_button_failure: 'Retry',
  verify_callback_title_failure: 'Something went wrong',
  verify_callback_form_description_failure: 'Please, verify your email again.',
  email_verified_button_success: 'Create FlavorID',
  verify_callback_title_success: 'Email address confirmed!',
  verify_callback_form_description_success: 'You can now create your FlavorID.',
  email_verified_button_in_progress: 'Create FlavorID',
  verify_callback_title_in_progress: 'Confirming email address...',
  verify_callback_form_description_in_progress: 'Email confirmation in progress.',

  //Email verify
  confirm_your_email_address: 'Confirm your email address',
  we_sent_message_to: 'We sent an email to',
  check_your_email_account_for_email_verification: 'Check your email account and click on the confirmation link to continue.',
  question_didnt_receive_the_email: 'Didn’t receive the email?',
  change_email_button: 'Change Email',
  resend_email_button: 'Resend',
  resend_email_submit: 'Resend',
};
