import React, {ErrorInfo} from "react";
import GlobalErrorPageView from "./GlobalErrorPageView";

export default class ErrorBoundary extends React.Component<any, any, any> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true, error };
    }

    componentDidCatch(error:Error, errorInfo: ErrorInfo) {
    }

    render() {
        if (this.state.hasError) {
            return <GlobalErrorPageView error={this.state.error} />;
        }

        return this.props.children;
    }
}
