import * as React from 'react';
import { Checkbox, FormControlLabel, Grid, Box } from '@material-ui/core';
import { MultipleChoiceQuestionViewProps, ValueLabelTuple } from '../../../../../models/questiontype-models/internal/QuestionTypeModels';
import QuestionTypeContainer from '../../QuestionTypeContainer';
import { QuestionProps } from '../../../../../models/questionnaire-models/internal/QuestionModels';
import I18NFromObjectComponent from '../../../../../shared/components/I18NFromObjectComponent';
import StyledTitle from '../../../../../styling/StyledTitle';
import StyledParagraph from '../../../../../styling/StyledParagraph';

interface Props extends QuestionProps, MultipleChoiceQuestionViewProps {}

class MultipleChoiceQuestionView extends QuestionTypeContainer<Props, any> {
  renderCheckbox(valueTuple: ValueLabelTuple, size: any) {
    const { icon, checkedIcon, value, allowMultiple } = this.props;

    let arrayValue: Array<any> = this.getValue(value, this.props);

    return (
      <Grid item xs={12} sm={6} md={4} key={valueTuple.value}>
        <Box pl={[0, 0, 0, 6]}>
      <Grid container>
        <Grid item>
          <Box px={2} py={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={arrayValue.includes(valueTuple.value)}
                  onChange={(event) => this.addValue(valueTuple.value, arrayValue, event.target.checked, Boolean(allowMultiple))}
                  value={valueTuple.value}
                  icon={icon}
                  checkedIcon={checkedIcon}
                />
              }
              label={
                <Grid container alignItems="center" style={{textAlign: 'left'}}>
                  <I18NFromObjectComponent object={valueTuple.label}>{(label: string) => <StyledTitle variation="small">{label}</StyledTitle>}</I18NFromObjectComponent>
                  <I18NFromObjectComponent object={valueTuple.description || { en: 'no description available' }}>{(description: string) => <StyledParagraph>{description}</StyledParagraph>}</I18NFromObjectComponent>
                </Grid>
              }
              key={valueTuple.value}
            />
          </Box>
        </Grid>
      </Grid>
      </Box>

      </Grid>
    );
  }

  render() {
    const { values } = this.props;

    return (
      <Grid container justify="center">
        {values.map((value, index) => {
          if (values.length > 20) {
            return index < 10 ? this.renderCheckbox(value, 4) : this.renderCheckbox(value, 4);
          } else {
            return this.renderCheckbox(value, 12);
          }
        })}
      </Grid>
    );
  }

  private addValue(clickedValue: any, currentValue: Array<any>, isChecked: boolean, allowMultiple: boolean) {
    let newCurrentValue = currentValue.slice();
    if (isChecked) {
      if (allowMultiple) {
        newCurrentValue.push(clickedValue);
      } else {
        newCurrentValue = [clickedValue];
      }
    } else {
      if (allowMultiple) {
        newCurrentValue = newCurrentValue.filter((value) => value !== clickedValue);
      } else {
        newCurrentValue = [];
      }
    }
    this.handleChange(newCurrentValue, this.props);
  }
}

export default MultipleChoiceQuestionView;
