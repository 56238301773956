import { Component } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../redux/Reducers';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Avatar } from '@material-ui/core';
import { FlavourIDUser, SecondaryProfile } from '../../../models/user-models/UserModels';

interface Props {
  flavourIdUser?: FlavourIDUser;
  secondaryUser?: SecondaryProfile;
  usesSecondaryUser: Boolean;
}

class CurrentUserAvatar extends Component<Props> {
  render() {
    const { flavourIdUser, secondaryUser, usesSecondaryUser } = this.props;

    if (usesSecondaryUser && secondaryUser) {
      return <Avatar>{secondaryUser.name.charAt(0).toLocaleUpperCase()}</Avatar>;
    }

    const currentUser = firebase.auth().currentUser;
    if (flavourIdUser === undefined || !flavourIdUser.name || !currentUser) {
      return <div />;
    }

    return <Avatar src={currentUser.photoURL || undefined}>{flavourIdUser.name.charAt(0).toLocaleUpperCase()}</Avatar>;
  }
}

export default connect(
  (state: State) => ({
    flavourIdUser: state.authentication.flavourIdUser,
    secondaryUser: state.authentication.currentSecondaryProfile
  }),
  () => ({})
)(CurrentUserAvatar);
