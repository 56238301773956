import { Component, ReactElement } from 'react';
import * as React from 'react';
import { PromiseState } from 'react-refetch';
import { questionnaireDetailGetTransformer } from '../../models/questionnaire-models/transformers/QuestionnaireTransformersToInternal';
import { QuestionnaireDetail } from '../../models/questionnaire-models/external/QuestionModels';
import { Questionnaire } from '../../models/questionnaire-models/internal/QuestionnaireModels';
import { AuthenticatedProps } from '../../shared/containers/AuthenticatedPageWithLoading';
import PromiseStatePage from '../../shared/containers/PromiseStatePage';
import SecondaryProfileMiddleware from '../refetch/SecondaryProfileMiddleware';

export interface QuestionnaireDetailProps extends AuthenticatedProps {
  onComplete?: (questionnaire: Questionnaire) => void;
  questionnaire?: Questionnaire;
}

interface Props extends AuthenticatedProps {
  questionnaire: PromiseState<Questionnaire | undefined>;
  questionnaireId: number | string;
  onComplete?: (questionnaire: Questionnaire) => void;
  component?: ReactElement<QuestionnaireDetailProps>;
  children?: any;
}

export interface ValueType {
  questionnaire: Questionnaire;
}

class QuestionnaireAPIDetailFetcher extends Component<Props, any> {
  render() {
    const { questionnaire, authToken, onComplete, children } = this.props;
    const Component = this.props.component;
    return (
      <PromiseStatePage promiseStateMapping={{ questionnaire: questionnaire }}>
        {(values: ValueType) => {
          if (Component) {
            // @ts-ignore
            return <Component questionnaire={values.questionnaire} onComplete={onComplete} authToken={authToken} />;
          } else {
            return children(values, authToken);
          }
        }}
      </PromiseStatePage>
    );
  }
}

// @ts-ignore
export default SecondaryProfileMiddleware((props: any) => {
  return {
    questionnaire: {
      url: `/stop/questionnaire/${props.questionnaireId}`,
      authenticationToken: props.authToken,
      then: (questionnaire: any) => {
        const internalQuestionnaire = questionnaireDetailGetTransformer(questionnaire as QuestionnaireDetail);
        return {
          value: internalQuestionnaire
        };
      }
    }
  };
}, QuestionnaireAPIDetailFetcher);
