import {SvgIcon} from "@material-ui/core";
import React from "react";

export function VerifiedIcon(props: any) {
    return (
        <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M40.9151 21.1161C40.427 20.628 39.6355 20.628 39.1474 21.1161L27.0312 33.2322L17.3526 23.5536C16.8645 23.0655 16.073 23.0655 15.5849 23.5536C15.0967 24.0418 15.0967 24.8332 15.5849 25.3214L26.1474 35.8839C26.6355 36.372 27.427 36.372 27.9151 35.8839L40.9151 22.8839C41.4033 22.3957 41.4033 21.6043 40.9151 21.1161Z" fill="#2F998D"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M28 48C39.0457 48 48 39.0457 48 28C48 16.9543 39.0457 8 28 8C16.9543 8 8 16.9543 8 28C8 39.0457 16.9543 48 28 48ZM28 50C40.1503 50 50 40.1503 50 28C50 15.8497 40.1503 6 28 6C15.8497 6 6 15.8497 6 28C6 40.1503 15.8497 50 28 50Z" fill="#2F998D"/>
        </SvgIcon>
    );
}
