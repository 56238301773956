import firebase from 'firebase/app';
import 'firebase/auth';

import { setUserAndFetchData } from './redux/authentication/LoginActionCreators';
import { Dispatch, Store } from 'redux';
import { setAuthInitialised } from './redux/firebase/FirebaseActionCreators';

import firebaseConfig from './firebase/config';

export const firebaseDidInit: (dispatch: Dispatch) => void = (dispatch) => {
  dispatch(setAuthInitialised());
};

// eslint-disable-next-line
export default (store: Store) => {
  console.log("initialising firebase");
  firebase.initializeApp(firebaseConfig);
  console.log("initialising firebase done");
  firebase.auth().onIdTokenChanged(function (user: firebase.User | null) {
    const currentUser = user || firebase.auth().currentUser;
    console.log("fetching user...");
    // @ts-ignore
    store.dispatch(setUserAndFetchData(currentUser));
  });
};
