import React, { Component } from 'react';
import './App.css';
import { Route, Switch as RouterSwitch } from 'react-router-dom';
import AuthenticatedRoute from './shared/containers/AuthenticatedRoute';
import NoMatch from './features/staticpages/NoMatch';
import { connect, useSelector } from 'react-redux';
import { State } from './redux/Reducers';
import QuestionnairePageComponent from './features/questionnaire-page/containers/QuestionnairePageComponent';
import { Dispatch } from 'redux';
import { setLanguage } from './redux/language/LanguageActionCreator';
import { EN, Language, NL } from './Languages';
import { IntlProvider } from 'react-intl';
import translations from './translations/interfaces/Translations';
import SignInView from './features/authentication/authentication-types/signin/SignInView';
import SignUpView from './features/authentication/authentication-types/signup/SignUpView';
import DefaultNavbar from './shared/components/static/navbars/DefaultNavbar';
import QuestionnaireNavbar from './shared/components/static/navbars/QuestionnaireNavbar';
import NoNavBar from './shared/components/static/navbars/NoNavBar';
import DefaultElevatedNavBar from './shared/components/static/navbars/DefaultElevatedNavBar';
import ProfilePageComponent from './features/profilepage/components/ProfilePageComponent';
import FooterComponent from './shared/components/static/FooterComponent';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { PositionProperty } from 'csstype';
import ForgotPasswordPage from './features/forgot-password-page/components/ForgotPasswordPage';
import PrivacyPage from './features/staticpages/legal/privacy/PrivacyPage';
import TermsConditionsPage from './features/staticpages/legal/TermsConditionsPage';
import QuestionnaireCompletePage from './features/questionnaire-page/containers/QuestionnaireCompletePage';
import LoadingIndicator from './shared/components/static/LoadingIndicator';
import { PROFILE_PAGE } from './PageConfig';
import UserManagementPage from "./features/usermgmt/components/UserManagementPage";
import ErrorBoundary from "./features/errorhandler/ErrorBoundary";
import AuthenticationNavbar from "./shared/components/static/navbars/AuthenticationNavbar";
import AuthenticationNavbarHalf from "./shared/components/static/navbars/AuthenticationNavbarHalf";
import QuestionnaireCompletedNavbar from "./shared/components/static/navbars/QuestionnaireCompletedNavbar";
import VerifyEmailView from "./features/authentication/authentication-types/verifyemail/VerifyEmailView";
import AromasPageComponent from "./features/aromas/components/AromasPageComponent";

import { useLocation } from "react-router";
import { Userpilot } from 'userpilot'
import firebase from 'firebase';

interface Props extends StyledComponentProps {
  firebaseAuthInitialised: boolean;
  onSwitch?: (checked?: Boolean) => void;
  language: Language;
}

const styles = {
  grow: {
    flexGrow: 1,
    height: 'fit-content'
  },
  noGrow: {
    flexGrow: 0,
    alignSelf: 'flex-end',
    width: '100%',
    height: 'fit-content'
  },
  appbar: {
    position: 'static' as PositionProperty,
    top: 0,
    left: 0,
    right: 0
  }
};

class App extends Component<Props, any> {
  render() {
    const { firebaseAuthInitialised, language } = this.props;
    const classes = this.props.classes || {};

    let pageRendering = (
      <div style={{ height: '100vh', width: '100vw' }}>
        <LoadingIndicator />
      </div>
    );

    if (firebaseAuthInitialised) {
      pageRendering = (
        <div style={{ minHeight: '100vh' }}>
          <div className={classes.appbar}>
            <div style={{ height: '100%', width: '100%' }}>
              <RouterSwitch>
                <Route path="/questionnaire/:id" exact component={QuestionnaireNavbar} />
                <Route path="/questionnaire/:id/completed" exact component={QuestionnaireCompletedNavbar} />
                <Route path="/signin" exact component={AuthenticationNavbarHalf} />
                <Route path="/signup" exact component={AuthenticationNavbarHalf} />
                <Route path="/forgot-password" exact component={AuthenticationNavbar} />
                <Route path="/verify-email" exact component={AuthenticationNavbarHalf} />
                <Route path="/usermgmt" exact component={NoNavBar} />
                <Route path="/embedded-auth" exact component={NoNavBar} />
                <Route path="/" exact component={DefaultElevatedNavBar} />
                <Route path="/profile" exact component={DefaultElevatedNavBar} />
                <Route path="/aromas" exact component={DefaultElevatedNavBar} />
                <Route component={DefaultNavbar} />
              </RouterSwitch>
            </div>
          </div>
          <Grid container style={{ minHeight: 'calc(100vh - 79px)', height: 'fit-content' }}>
            <Grid xs={12} item classes={{ root: classes.grow }} style={{ minHeight: '100%' }}>
              <RouterSwitch>
                <AuthenticatedRoute path="/" exact>
                  <ProfilePageComponent {...PROFILE_PAGE} />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/profile" exact>
                  <ProfilePageComponent {...PROFILE_PAGE} />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/aromas" exact>
                  <AromasPageComponent />
                </AuthenticatedRoute>
                <Route path="/forgot-password" exact component={ForgotPasswordPage} />
                <Route path="/verify-email" exact component={VerifyEmailView} />
                <Route path="/usermgmt" exact component={UserManagementPage} />
                <Route path="/signin" exact component={SignInView} />
                <Route path="/signup" exact component={SignUpView} />
                <AuthenticatedRoute path="/questionnaire/:id" exact component={QuestionnairePageComponent} />
                <AuthenticatedRoute path="/questionnaire/:id/completed" exact component={QuestionnaireCompletePage} />
                <Route path="/privacy" component={PrivacyPage} />
                <Route path="/tos" component={TermsConditionsPage} />
                <Route component={NoMatch} />
              </RouterSwitch>
            </Grid>
            <Grid xs={12} item classes={{ root: classes.noGrow }}>
              <RouterSwitch>
                <Route path="/questionnaire/:id" component={NoNavBar} />
                <Route path="/signin" component={NoNavBar} />
                <Route path="/signup" component={NoNavBar} />
                <Route path="/forgot-password" component={NoNavBar} />
                <Route path="/verify-email" component={NoNavBar} />
                <Route path="/usermgmt" component={NoNavBar} />
                <Route path="/aromas" component={FooterComponent} />
                <Route path="/embedded-auth" component={NoNavBar} />
                <Route component={FooterComponent} />
              </RouterSwitch>
            </Grid>
          </Grid>
        </div>
      );
    }

    return (
      <ErrorBoundary>
        <div>
          <UserpilotWrapper>
            <IntlProvider locale={language.locale} messages={translations[language.locale]} defaultLocale={EN.locale}>
              {pageRendering}
            </IntlProvider>
          </UserpilotWrapper>
        </div>
      </ErrorBoundary>
    );
  }
}

const selectCurrentUser = (state: State) => {
  return state.authentication.currentUser;
};


// todo: maybe convert this to class based for consistency??
const UserpilotWrapper: React.FC<{}> = (props) => {
  const location = useLocation();
  const currentUser: (firebase.User|undefined) = useSelector<State, firebase.User|undefined>(selectCurrentUser);

  React.useEffect(() => {
    if (currentUser?.uid) {
      Userpilot.identify(
	currentUser.uid, {// Used to identify users
	  name: currentUser.displayName, // Full name
	  email: currentUser.email // Email address
          // we should also pass createdAt, but this is not returned by the backend.
        }
      );
    } else {
      Userpilot.anonymous({});
    }
  }, [currentUser]);

  React.useEffect(() => {
    Userpilot.reload({});
  }, [location]);
  return <>{props.children}</>;
};


const mapStateToProps = (state: State) => {
  return {
    firebaseAuthInitialised: state.firebase.authIsInitialised,
    language: state.language.language,
    languageChanged: state.language.languageChanged
  };
};

// TODO: remove when translation is in backend
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSwitch: (checked?: Boolean) => {
      checked ? dispatch(setLanguage(NL, true)) : dispatch(setLanguage(EN, true));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
