import * as React from 'react';
import { Component } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { theme } from '../../../styling/DefaultTheme';
import { FontFamilyProperty, FontStyleProperty, FontWeightProperty, LineHeightProperty } from 'csstype';

const styles = {
  image: (props: Props) => ({
    // MH: disabled to allow the image to center itself
    // width: props.width || '100%',
    //maxWidth: '100%',

  }),
  textStyle: {
    fontFamily: 'Open Sans' as FontFamilyProperty,
    fontStyle: 'normal' as FontStyleProperty,
    fontWeight: 'normal' as FontWeightProperty,
    fontSize: 24,
    lineHeight: '140%' as LineHeightProperty<any>,
    color: theme.palette.grey[200]
  }
};

interface Props extends StyledComponentProps {
  width?: number | string;
  percentage?: number;
}

export class HealthGraphComponent extends Component<Props> {
  polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  createArc(x: number, y: number, radius: number, spread: number, startAngle: number, endAngle: number) {
    const innerStart = this.polarToCartesian(x, y, radius, endAngle);
    const innerEnd = this.polarToCartesian(x, y, radius, startAngle);
    const outerStart = this.polarToCartesian(x, y, radius + spread, endAngle);
    const outerEnd = this.polarToCartesian(x, y, radius + spread, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return [
      'M',
      outerStart.x,
      outerStart.y,
      'A',
      radius + spread,
      radius + spread,
      0,
      largeArcFlag,
      0,
      outerEnd.x,
      outerEnd.y,
      'L',
      innerEnd.x,
      innerEnd.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      1,
      innerStart.x,
      innerStart.y,
      'L',
      outerStart.x,
      outerStart.y,
      'Z'
    ].join(' ');
  }

  render() {
    const { percentage } = this.props;
    const classes = this.props.classes || {};
    return (
      <div className={classes.image}>
        <svg width="128" height="125" viewBox="0 0 128 125" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d={this.createArc(64.1585, 63.9811, 51.766, 8, -120, 120)} fill="#CED5D9" />
          </g>
          <g>
            <path d={this.createArc(64.1585, 63.9811, 51.766, 8, -120, -120 + 240 * (percentage || 0))} fill="#2F998D" />
          </g>
          <text className={classes.textStyle} fill="#505D64" textAnchor="middle" x="50%" y="80%">
            {((percentage || 0) * 100).toFixed()}%
          </text>
          <path
            d="M82.9 34.5986C77.6699 29.4682 69.1415 29.4682 63.9113 34.5986C58.6812 29.4682 50.1527 29.4682 44.9226 34.5986C39.6925 39.729 39.6925 48.0947 44.9226 53.2251L63.9113 71.8517L82.9 53.2251C88.1302 48.0947 88.1302 39.7752 82.9 34.5986Z"
            fill="#104859"
          />
        </svg>
      </div>
    );
  }
}

export default withStyles(styles)(HealthGraphComponent);
