import React, { Component } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { isQuestionGroup, OPERATOR_MAP, Question, QuestionGroup, QuestionOrGroup } from '../../../models/questionnaire-models/internal/QuestionModels';
import QuestionOrGroupView from '../components/QuestionOrGroupView';
import { Questionnaire } from '../../../models/questionnaire-models/internal/QuestionnaireModels';
import { connect as reduxConnect } from 'react-redux';
import { QuestionnaireAnswers } from '../../../models/questionnaire-models/external/QuestionModels';
import { questionGroupToExternalAnswerGroup } from '../../../models/questionnaire-models/transformers/QuestionnaireTransformersToExternal';
import { questionTypesToQuestionAnswerTranslator } from '../../../models/questiontype-models/internal/QuestionTypeModels';
import StyledButton from '../../../styling/StyledButton';
import { State } from '../../../redux/Reducers';
import { connect as refetchConnect } from '../../../RefetchConfig';
import { setCurrentIndex, setCurrentQuestionnaire, setCurrentQuestionnaireName, setQuestionStatuses } from '../../../redux/questionnaire/QuestionnaireActionCreator';
import { IDLE, QuestionStatuses } from '../../../redux/questionnaire/QuestionnaireReducers';
import _ from 'lodash';
import { resolveDependency } from '../../../utils/QuestionTreeUtils';
import { getQueryParams, getRedux, SecondaryProfileMiddlewareProps } from '../../../API/refetch/SecondaryProfileMiddleware';
import { injectIntl, IntlShape } from 'react-intl';
import I18NFromObjectComponent from '../../../shared/components/I18NFromObjectComponent';
import style from '../../../styling/Style';

interface Props extends SecondaryProfileMiddlewareProps {
  questionnaire: Questionnaire;
  onComplete?: (questionnaire: Questionnaire) => void;
  postAnswers?: any;
  authToken?: string;
  setStatus: (status: QuestionStatuses) => void;
  currentStatus: QuestionStatuses;
  setCurrentIndex: (index: number) => void;
  setCurrentQuestionnaireName: (name: string) => void;
  setCurrentQuestionnaire: (questionnaire: Questionnaire) => void;
  currentIndex: number;
  intl: IntlShape;
}

interface ComponentState {
  currentQuestionIndex: number;
  isComplete: boolean;
  questionnaire: Questionnaire;
}

class QuestionnaireView extends Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    const { questionnaire } = props;
    this.state = {
      currentQuestionIndex: 0,
      isComplete: false,
      questionnaire: questionnaire
    };
  }

  onQuestionnaireComplete() {
    /**
     * Complete the questionnaire.
     * Run the onComplete prop with the current questionnaire state.
     */
    const { onComplete } = this.props;
    const { questionnaire } = this.state;
    this.setState({ isComplete: true });
    if (onComplete) {
      onComplete(questionnaire);
    }
  }

  searchQuestion(dependencyPath: string, questionnaire: Questionnaire): Question | undefined {
    /**
     * Search a question following the given dependency path.
     * (Used to check for dependencies)
     * Returns the question:Question
     */
    const foundQuestion = resolveDependency(dependencyPath, questionnaire.questions);
    return foundQuestion ? (foundQuestion.questionOrGroup as Question) : undefined;
  }

  checkDependencies(currentQuestionOrGroup: QuestionGroup) {
    /**
     * Check if the dependencies are valid.
     * Returns True or False depending on if the question has the correct dependencies or not.
     */
    const { questionnaire } = this.props;
    const currentQuestion = currentQuestionOrGroup;
    if (!currentQuestion.data.dependencies) {
      return true;
    } else {
      return currentQuestion.data.dependencies.every((dependency) => {
        const question = this.searchQuestion(dependency.dependsOn, questionnaire);
        let otherAnswer = undefined;
        if (question) {
          const otherValue = question.answer ? question.answer.value : undefined;
          const answerTranslator = new questionTypesToQuestionAnswerTranslator[question.type](question.data.props);
          otherAnswer = answerTranslator.getValue(otherValue, question.data.props);
        }
        const operator = OPERATOR_MAP[dependency.dependsOnOperator || '='];
        return operator(otherAnswer, dependency.dependsOnValue);
      });
    }
  }

  async saveQuestionnaire() {
    /**
     * Persist the question's answer to the backend.
     */
    const { questionnaire } = this.state;
    const { postAnswers } = this.props;
    const answer: any = questionnaire.questions
      .map((question) => {
        return questionGroupToExternalAnswerGroup(question as QuestionGroup);
      })
      // Filter empty answers out such that the API only gets actually filled in qg's
      .filter((x) => {
        return x.answers.length > 0;
      });
    await postAnswers({
      questionnaire: {
        id: questionnaire.id
      },
      answerGroups: answer
    });
  }

  nullifyAnswers(questions: QuestionOrGroup[]) {
    questions.forEach((questionOrQuestionGroup) => {
      if (isQuestionGroup(questionOrQuestionGroup)) {
        this.nullifyAnswers(questionOrQuestionGroup.questions);
      } else {
        const question = questionOrQuestionGroup.questionOrGroup as Question;
        question.answer = undefined;
      }
    });
  }

  searchValidQuestion(questions: Array<QuestionGroup>, currentIndex: number, stepSize: number) {
    /**
     * Search a valid (check dependencies) question
     * with the given step size (-1 for previous button, +1 for next button).
     * Return the index of the question that is valid.
     */
    let foundQuestion = false;
    let newCurrentIndex = currentIndex;
    while (0 <= newCurrentIndex && newCurrentIndex <= questions.length - 1 && !foundQuestion) {
      newCurrentIndex += stepSize;
      const currentQuestion = questions[newCurrentIndex];
      foundQuestion = this.checkDependencies(currentQuestion);
      if (!foundQuestion) {
        this.nullifyAnswers(currentQuestion.questions);
        currentQuestion.answers = {
          id: currentQuestion.data.id,
          intentionallyBlank: { reason: 'UNMET_DEPENDENCY' },
          answers: []
        };
      }
    }
    return newCurrentIndex;
  }

  updateQuestionIndex(currentIndex: number, questions: Array<QuestionGroup>) {
    /**
     * Update the question index state.
     * Check if the question is still within range, otherwise do the onComplete logic.
     */
    this.props.setCurrentIndex(currentIndex);
    if (currentIndex >= questions.length) {
      this.onQuestionnaireComplete();
    } else {
      this.setState({ currentQuestionIndex: currentIndex });
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  skip(currentIndex: number, questions: Array<QuestionGroup>) {
    const currentQuestion = questions[currentIndex];
    // TODO: check this
    if (currentQuestion.answers) currentQuestion.answers.intentionallyBlank = { reason: 'UNKNOWN' };
    this.nextQuestion(currentIndex, questions);
  }

  async nextQuestion(currentIndex: number, questions: Array<QuestionGroup>) {
    /**
     * Go to the next *valid* question.
     */
    if (currentIndex === questions.length - 1) {
      await this.saveQuestionnaire();
      this.onQuestionnaireComplete();
    } else {
      currentIndex = this.searchValidQuestion(questions, currentIndex, 1);
      this.saveQuestionnaire();
      this.updateQuestionIndex(currentIndex, questions);
    }
  }

  previousQuestion(currentIndex: number, questions: Array<QuestionGroup>) {
    /**
     * Go to the previous *valid* question.
     */
    if (currentIndex === 0) {
      this.saveQuestionnaire();
      return;
    }
    currentIndex = this.searchValidQuestion(questions, currentIndex, -1);
    this.saveQuestionnaire();
    this.updateQuestionIndex(currentIndex, questions);
  }

  componentDidMount(): void {
    this.props.setCurrentQuestionnaire(_.cloneDeep(this.props.questionnaire));
    this.props.setCurrentIndex(0);
    this.props.setStatus(this.props.questionnaire.questions.map(() => IDLE));
    this.props.setCurrentQuestionnaireName(this.props.questionnaire.description || '');
  }

  componentDidUpdate(oldProps: Props) {
    const newProps = this.props;
    if (oldProps.currentIndex !== newProps.currentIndex) {
      this.setState({ currentQuestionIndex: newProps.currentIndex });
    }
    if (!_.isEqual(oldProps.questionnaire, newProps.questionnaire)) {
      this.props.setCurrentQuestionnaire(_.cloneDeep(this.props.questionnaire));
    }
  }

  render() {
    const { onComplete, intl } = this.props;
    const { currentQuestionIndex, isComplete, questionnaire } = this.state;
    const questions = questionnaire.questions;

    if (isComplete && !onComplete) {
      return <Redirect to="/profile" />;
    }

    const currentQuestion = questions[currentQuestionIndex];

    // @ts-ignore
    return (
      <Box py={[6, 6, 9]} px={6}>
        <Grid container justify="center" alignContent="space-around" style={{ height: '100%', minHeight: '60vh' }}>
          {currentQuestionIndex===0?<Grid item style={{ textAlign: 'center' }} xs={12} md={8}>
            {(
                <I18NFromObjectComponent object={questionnaire.name}>
                  {(value: any) => <Typography style={{ ...style.typography.doublePica, color: style.colors.grayscale[1], marginBottom:24}}>{value}</Typography>}
                </I18NFromObjectComponent>
            ) || 'Untitled'}
            <I18NFromObjectComponent object={questionnaire.description}>
              {(value: any) => <Typography style={{ ...style.typography.pica_strong, color: style.colors.grayscale[2], marginBottom: 43 }}>{value}</Typography>}
            </I18NFromObjectComponent>
          </Grid>:null}
          <Grid item xs={12} lg={12}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item>
                <QuestionOrGroupView
                  questionOrGroup={{ questionOrGroup: currentQuestion }}
                  handleChange={(value) => {
                    const newQuestionnaire = Object.assign({}, questionnaire);
                    newQuestionnaire.questions[currentQuestionIndex] = value.questionOrGroup as QuestionGroup;
                    this.setState({
                      questionnaire: newQuestionnaire
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box py={9}>
                <Grid container justify="center">
                  <StyledButton
                    onClick={() => {
                      this.nextQuestion(currentQuestionIndex, questions);
                    }}
                  >
                    {currentQuestionIndex === questions.length - 1 ? intl.formatMessage({ id: 'questionnaire_finish_button' }) : intl.formatMessage({ id: 'questionnaire_next_button' })}
                  </StyledButton>
                </Grid>
              </Box>
            </Grid>
            {/*<Grid item>*/}
            {/*<StyledButton*/}
            {/*backgroundColor="accent"*/}
            {/*style={{float: 'left', margin: 8}}*/}
            {/*onClick={() => {this.previousQuestion(currentQuestionIndex, questions)}}*/}
            {/*variant="contained" color="primary"*/}
            {/*disabled={currentQuestionIndex === 0}*/}
            {/*>*/}
            {/*Previous Question*/}
            {/*</StyledButton>*/}
            {/*</Grid>*/}
            {/*<Grid item>*/}
            {/*<StyledButton*/}
            {/*backgroundColor="dark"*/}
            {/*style={{float: 'left', margin: 8}}*/}
            {/*onClick={() => {this.skip(currentQuestionIndex, questions)}}*/}
            {/*variant="contained" color="primary"*/}
            {/*>*/}
            {/*Skip*/}
            {/*</StyledButton>*/}
            {/*</Grid>*/}
            {/*<Grid item>*/}
            {/*<StyledButton*/}
            {/*backgroundColor="accent"*/}
            {/*style={{float: 'right', margin: 8}}*/}
            {/*onClick={() => {this.nextQuestion(currentQuestionIndex, questions)}}*/}
            {/*variant="contained" color="primary"*/}
            {/*>*/}
            {/*{currentQuestionIndex === questions.length - 1 ? "Finish Questionnaire" : "Next Question"}*/}
            {/*</StyledButton>*/}
            {/*</Grid>*/}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// @ts-ignore
const refetchConnected = getRedux(
  refetchConnect((props: Props) => ({
    postAnswers: (subject: QuestionnaireAnswers) => {
      return {
        postAnswerResult: {
          url: `/stop/questionnaire/${subject.questionnaire.id}/answers`,
          queryParams: {
            ...getQueryParams(props)
          },
          authenticationToken: props.authToken,
          method: 'POST',
          body: JSON.stringify({ ...subject }),
          then: (subject: any) => {
            const statuses = subject.summary.groups.map((group: any) => group.status || IDLE);
            props.setStatus(statuses);
          }
        }
      };
    }
  }))(QuestionnaireView)
);

export default injectIntl(
  reduxConnect(
    (state: State) => ({
      currentStatus: state.questionnaire.questionnaireQuestionStatuses,
      currentIndex: state.questionnaire.currentIndex
      // TODO: set title as well
    }),
    (dispatch) => ({
      setStatus: (status: QuestionStatuses) => {
        dispatch(setQuestionStatuses(status));
      },
      setCurrentIndex: (index: number) => dispatch(setCurrentIndex(index)),
      setCurrentQuestionnaire: (questionnaire: Questionnaire) => dispatch(setCurrentQuestionnaire(questionnaire)),
      setCurrentQuestionnaireName: (name: string) => dispatch(setCurrentQuestionnaireName(name))
    })
  )(refetchConnected)
);
