import * as React from 'react';
import { Component, ReactElement } from 'react';
import { PromiseState } from 'react-refetch';
import { AuthenticatedProps } from '../../shared/containers/AuthenticatedPageWithLoading';
import PromiseStatePage from '../../shared/containers/PromiseStatePage';
import { FlavourIdData, SecondaryProfile, FlavourIdStatus } from '../../models/user-models/UserModels';
import SecondaryProfileMiddleware, { addSecondaryProfileUrl } from '../refetch/SecondaryProfileMiddleware';

export interface FlavourIdDataDetailProps extends AuthenticatedProps {
  flavourIdData?: FlavourIdData;
}

interface Props extends AuthenticatedProps {
  flavourIdData: PromiseState<any | undefined>;
  flavourIdDataRefresh: any;
  component?: ReactElement<FlavourIdDataDetailProps>;
  children?: any;
  currentSecondaryProfile?: SecondaryProfile;
}

interface State {
  timeout?: any;
  refreshes: number;
}

class FlavourIdDataFetcher extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timeout: undefined,
      refreshes: 0
    };
  }

  componentWillUnmount(): void {
    clearInterval(this.state.timeout);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.currentSecondaryProfile !== this.props.currentSecondaryProfile) {
      this.refreshInterval();
    }
  }

  refreshFlavourID() {
    console.log('REFRESHING');
    this.props.flavourIdDataRefresh(this.props);
    this.setState({ refreshes: this.state.refreshes + 1 });
    this.refreshInterval();
  }

  refreshInterval() {
    const { flavourIdData } = this.props;
    const statusIsOk = (flavourIdData.value && flavourIdData.value.status === FlavourIdStatus.FLAVORID_STATUS_OK);
    const timeout = 30000;
    const REFRESHING_THRESHOLD = 1000 * 60 * 5; // 5 minutes
    if (this.state.timeout) {
      clearInterval(this.state.timeout);
    }
    if (this.state.refreshes * timeout <= REFRESHING_THRESHOLD && !statusIsOk) {
      this.setState({
        timeout: setInterval(() => this.refreshFlavourID(), timeout)
      });
    }
  }

  componentDidMount(): void {
    this.refreshInterval();
  }

  render() {
    const { flavourIdData, authToken, children } = this.props;
    const Component = this.props.component;
    return (
      <PromiseStatePage promiseStateMapping={{ flavourIdData: flavourIdData }}>
        {(values: { flavourIdData: FlavourIdData }) => {
          if (Component) {
            // @ts-ignore
            return <Component values={values} authToken={authToken} />;
          } else {
            return children(values, authToken);
          }
        }}
      </PromiseStatePage>
    );
  }
}

// @ts-ignore
export default SecondaryProfileMiddleware((props: any) => {
  return {
    flavourIdData: {
      url: `/stop/user/flavorid`,
      authenticationToken: props.authToken,
      then: (flavourIdData: FlavourIdData) => {
        return {
          value: flavourIdData
        };
      }
    },
    flavourIdDataRefresh: (props: Props) => ({
      flavourIdData: {
        url: addSecondaryProfileUrl(`/stop/user/flavorid`, props),
        authenticationToken: props.authToken,
        force: true,
        refreshing: true,
        then: (flavourIdData: FlavourIdData) => {
          return {
            value: flavourIdData
          };
        }
      }
    })
  };
}, FlavourIdDataFetcher);
