import { Component, ReactElement } from 'react';
import * as React from 'react';
import { PromiseState } from 'react-refetch';
import { AuthenticatedProps } from '../../shared/containers/AuthenticatedPageWithLoading';
import PromiseStatePage from '../../shared/containers/PromiseStatePage';
import { HealthScore } from '../../models/user-models/UserModels';
import SecondaryProfileMiddleware from '../refetch/SecondaryProfileMiddleware';

export interface HealthScoreDetailProps extends AuthenticatedProps {
  healthScore?: HealthScore;
}

interface Props extends AuthenticatedProps {
  healthScore: PromiseState<any | undefined>;
  component: ReactElement<HealthScoreDetailProps>;
}

class HealthScoreFetcher extends Component<Props, any> {
  render() {
    const { healthScore, authToken } = this.props;
    const Component = this.props.component;
    return (
      <PromiseStatePage promiseStateMapping={{ healthScore: healthScore }}>
        {
          // @ts-ignore
          <Component authToken={authToken} />
        }
      </PromiseStatePage>
    );
  }
}

// @ts-ignore
export default SecondaryProfileMiddleware((props: any) => {
  return {
    healthScore: {
      url: `/stop/user/health`,
      authenticationToken: props.authToken,
      then: (healthScore: HealthScore) => {
        const v2 = (healthScore?.version ?? 0) >= 2;
        return {
          value: v2 ? healthScore: ({
              healthScore: healthScore.healthScore / 100,
              status: healthScore.status
          })
        };
      }
    }
  };
}, HealthScoreFetcher);
