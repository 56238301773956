import { AuthenticationPageTranslations } from '../interfaces/IAuthenticationPageTranslations';

export const authenticationpage_translations_nl: AuthenticationPageTranslations = {
  authentication_email_label: 'E-mail adres',
  authentication_email_placeholder: 'Uw e-mail adres',
  authentication_password_label: 'Wachtwoord',
  authentication_password_placeholder: 'Uw wachtwoord',
  signin_form_title: 'Aanmelden',
  signin_form_description: 'Gelieve in the loggen met je FlavorID account',
  signin_form_forgot_password: 'WACHTWOORD VERGETEN',
  signin_form_login_button: 'Aanmelden',
  signin_form_other_methods: 'Of aanmelden met',
  signin_form_or: 'Of',
  no_account: "Heb je nog geen FlavorID account?",
  signup_button: 'ACCOUNT AANMAKEN',
  signup_form_title: 'Welkom bij FlavorID',
  signup_form_description: 'Maak je account aan en start...',
  signup_form_agree: 'I agree to FlavorID',
  signup_form_tos: 'Gebruiksvoorwaarden',
  signup_form_and: 'en',
  signup_form_pp: 'Privacybeleid',
  signup_form_signup_button: 'Aanmaken',
  signup_form_other_methods: 'Of registreer met',
  already_have_account: 'Heb je al een account?',
  signin_button: 'AANMELDEN',
  authentication_email_invalid: 'Ongeldige e-mail',
  authentication_required: 'Verplicht',
  authentication_password_too_short: 'Wachtwoord is te kort',
  authentication_password_too_long: 'Wachtwoord is te lang',
  authentication_agree_to_terms: 'Gelieve de voorwaarden te aanvaarden',
  sign_up_successful: 'De registratie is voltooid! Gelieve je e-mail te controleren..',
  signin_which_language: 'In welke taal wil je de vragenlijst invullen?',
  email_verified_button_failure: 'Opnieuw proberen',
  verify_callback_title_failure: 'E-mail adres bevestiging is gefaald',
  verify_callback_form_description_failure: 'E-mail validatie is gefaald.',
  email_verified_button_success: 'Maak uw FlavorID aan',
  verify_callback_title_success: 'E-mail address bevestigd!',
  verify_callback_form_description_success: 'You can now create your FlavorID.',
  email_verified_button_in_progress: 'FlavorID aanmaken',
  verify_callback_title_in_progress: 'Confirming e-mail address...',
  verify_callback_form_description_in_progress: 'Bezig met e-mail te valideren.',

  //Email verify
  confirm_your_email_address: 'Confirm your email address',
  we_sent_message_to: 'We sent an email to',
  check_your_email_account_for_email_verification: 'Check your email account and click on the confirmation link to continue.',
  question_didnt_receive_the_email: 'Didn’t receive the email?',
  change_email_button: 'Change Email',
  resend_email_button: 'Resend',
  resend_email_submit: 'Resend',

};
