import type { Props } from "./index.d";
import React from "react";

export const Typography: React.FC<Props> =
({
     text,
     children
 }) => {
    return (
        <>
            {text && text.split('\n').map((item, key)=>{
                return <span key={key}>{item}<br /></span>;
            })}
            {children}
        </>
    );
}
export default Typography;
