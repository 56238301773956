import { Component } from 'react';
import * as React from 'react';
import { StyledComponentProps, Box } from '@material-ui/core';
import StyledTitle from '../StyledTitle';
import StyledParagraph from '../StyledParagraph';
import style from '../Style';

interface Props extends StyledComponentProps {
  children?: any;
  title: any;
  titleColor?: string;
  textColor?: string;
}

class MediumBlock extends Component<Props, any> {
  render() {
    let { children, title, titleColor } = this.props;

    return (
      <React.Fragment>
        <Box m={4}>
          <StyledTitle element="h4" variation="medium" color={titleColor || style.colors.brand.blueGreen}>
            {title}
          </StyledTitle>
          <Box my={4}>
            <StyledParagraph variation="normal" color={style.colors.grayscale[1]}>
              {children}
            </StyledParagraph>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default MediumBlock;
