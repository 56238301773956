import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import bourdainTheme from './BourdainTheme';

class BourdainStyleProvider extends React.Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withStyles(bourdainTheme)(BourdainStyleProvider);
