import IFlavoursDescriptionTranslation from '../interfaces/IFlavoursDescriptionTranslation';

export const FlavoursDescriptionTranslation_NL: IFlavoursDescriptionTranslation = {
  spicy_description: "Aroma's die geassocieerd worden met specerijen zoals kruidnagel, anijszaad, komijn, kaneel en vanille.",
  caramel_description: "Het aroma van gekookte, licht gebrande suiker of esdoorn siroop.",
  fruity_description: "Deze zijn terug te vinden in diverse vruchten van aardbei en perzik tot tropische vruchten zoals ananas, banaan en kokosnoot.",
  cheese_description: "Aroma’s van gerijpte kazen, boter en room, maar ook zuren en gefermenteerde aroma’s van zuivelproducten en azijn.",
  chemical_description: "Minder gewenste aroma’s in voeding die verbrand ruiken of eerder aan rubber, plastiek of petroleum doen denken.",
  herbal_description: "Typisch voor verse kruiden zoals munt, tijm en lavendel",
  green_description: "Typische geur van verse, rauwe, onbehandelde ingrediënten. Omvat de geur van vers gemaaid gras of komkommer. Maar ook de geur van graan en de metaalachtige aroma’s in zeewier.",
  vegetable_description: "Omvat de geur van paprika, champignons en gekookte aardappel. Maar ook zwavelachtige aroma’s die doen denken aan ui, look en kool.",
  citrus_description: "Citrus aromas zijn typisch voor citrusvruchten, zoals citroen, sinaasappel en pompelmoes, maar ook in ingrediënten zoals korianderzaad en citroengras.",
  floral_description: "Bloemige aromas zijn typisch voor roos, violet, jasmijn en geranium",
  animal_description: "Aroma’s van bouillon, sterke dierlijke aroma’s van wild of gekookte lever, maar ook aroma’s van vis en schaaldieren.",
  roasted_description: "Geroosterde aroma’s die ontstaan terwijl ingrediënten gebakken worden. Omvat een geroosterde, gebakken geur, de geur van popcorn, koffie en chocolade en zelfs de aardse geur van rode biet.",
  woody_description: "Diverse hout-achtige aroma’s zoals cederhout en den, maar ook rokerige aroma’s.",
  nutty_description: "Aromas of nuts, typical for hazelnut, almond or the more perfumed Tonka bean."
};
