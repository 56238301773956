import React, { Component } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';

const styles = {
  iconContainer: {
    '& svg': {
      maxWidht: '100%'
    }
  }
};

interface Props extends StyledComponentProps {
  currentIconName: string;
  size?: any;
  iconSet: any;
}

class StyledIcon extends Component<Props> {
  render() {
    const { currentIconName, size, iconSet } = this.props;
    const classes = this.props.classes || {};

    return iconSet.map(
      (value: any, index: number) =>
        value.name === currentIconName && (
          <div style={{ width: `${size}px`, height: `${size}px` }} key={index} className={classes.iconContainer}>
            {value.icon}
          </div>
        )
    );
  }
}

export default withStyles(styles)(StyledIcon);
