// @ts-ignore
import React, {useState} from "react";
import type { Props } from "./index.d";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles((theme: any)=>({
    wrapper:{
        '&:hover $noSelectionVisible':{
            visibility: 'hidden'
        },
        '&:hover $itemContainerSelected $label':{
            visibility: 'hidden'
        }
    },
    iconsWrapper: {
        display: 'flex',
    },
    itemContainer:{
        position: 'relative',
        '&:hover':{
            '& $icon':{
                opacity: 1,
                transform: 'scale(1.5)',
                cursor: 'pointer'
            },
            '& $label':{
                display: 'block'
            }
        }
    },
    itemContainerSelected:{
        position: 'relative',
        '& $icon':{
            opacity: 1,
            cursor: 'pointer'
        },
        '& $label':{
            display: 'block',
            color: theme.colors.grayscale[1]
        },
        '&:hover':{
            '& $label':{
                visibility: 'visible!important'
            }
        }
    },
    icon: {
        opacity: .4,
        transition: 'transform .1s'
    },
    label: {
        left: '50%',
        position: 'absolute',
        textAlign: 'center',
        display: 'none',
        marginTop:6,
        color:theme.colors.brand.brandGreen,
        ...theme.typography.bravier
    },
    labelInner: {
        position: 'relative',
        left: '-50%',
        whiteSpace: 'nowrap'
    },
    noSelection:{
        color:theme.colors.grayscale[4],
        ...theme.typography.bravier,
        visibility: 'hidden',
    },
    noSelectionVisible:{
        color:theme.colors.grayscale[4],
        ...theme.typography.bravier,
        '&:hover':{
            visibility: 'visible!important'
        }
    }
}));

export const Rating: React.FC<Props> =
({
     value,
    items,
    noSelection,
     onChange,
     children,
     ...props
 }) => {

    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState(value);

    return <div className={classes.wrapper}>
        <div className={classes.iconsWrapper}>
            {items.map((ratingItem: any, itemKey)=>(
                <div
                    onClick={()=>{
                        const newValue = selectedValue===ratingItem.value?null:ratingItem.value;
                        setSelectedValue(newValue);
                        if(onChange){
                            onChange(newValue);
                        }
                    }}
                    key={itemKey}
                    className={selectedValue===ratingItem.value?classes.itemContainerSelected:classes.itemContainer}
                >
                    <div className={classes.icon}>
                        {ratingItem.icon}
                    </div>
                    <div className={classes.label}>
                        <div className={classes.labelInner}>
                            {ratingItem.label}
                        </div>
                    </div>
                </div>
            ))}
        </div>

        {
            noSelection &&
            <div className={
                selectedValue ? classes.noSelection : classes.noSelectionVisible
            }>
                {noSelection}
            </div>
        }

    </div>;
}
export default Rating;
