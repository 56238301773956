import { connect } from 'react-redux';
import { addQueryParams, connect as refetchConfigConnect } from '../../RefetchConfig';
import { State } from '../../redux/Reducers';
import { SecondaryProfile } from '../../models/user-models/UserModels';
import { MapPropsToRequestsToProps } from 'react-refetch';
import * as React from 'react';

export interface SecondaryProfileMiddlewareProps {
  currentSecondaryProfile?: SecondaryProfile;
}

export const getQueryParams: (props: any) => { [key: string]: string | undefined } = (props: any) => {
  return props.currentSecondaryProfile ? { userProfile: props.currentSecondaryProfile.id || '' } : {};
};

export const getRedux = connect(
  (state: State) => ({
    currentSecondaryProfile: state.authentication.currentSecondaryProfile
  }),
  {}
);

export const transformToMapping = (newX1: any, props: any) => {
  newX1.queryParams = {
    ...newX1.queryParams,
    ...getQueryParams(props)
  };
  newX1.comparison = `${newX1.comparison}:${Object.entries(getQueryParams(props))}`;
  return newX1;
};

export const addSecondaryProfileUrl = (baseUrl: string, props: any) => {
  return baseUrl + addQueryParams(getQueryParams(props));
};

// eslint-disable-next-line
export default (mapping: MapPropsToRequestsToProps<any>, element: React.ComponentType<any>) => {
  return getRedux(
    refetchConfigConnect((props: any, context: any) => {
      const newMapping: any = {};
      const originalMapping = mapping(props, context);

      Object.entries(originalMapping).forEach((x: [string, any]) => {
        const newX1 = x[1];
        newMapping[x[0]] = transformToMapping(newX1, props);
      });

      return newMapping;
    })(element)
  );
};
