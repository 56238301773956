import { Component } from 'react';
import * as React from 'react';
import { PromiseState } from 'react-refetch';
import _ from 'lodash';
import LoadingIndicator from '../components/static/LoadingIndicator';

interface PromiseStateProps {
  promiseStateMapping: {
    [key: string]: PromiseState<any>;
  };
  children: any;
  [key: string]: any;
}

export default class PromiseStatePage extends Component<PromiseStateProps, any> {
  render() {
    const { promiseStateMapping, children, ...otherProps } = this.props;
    if (!Object.values(promiseStateMapping).every((value) => value.fulfilled)) {
      return <LoadingIndicator />;
    }
    let props: { [key: string]: any } = { ...otherProps };
    Object.keys(promiseStateMapping).forEach((key) => {
      props[key] = promiseStateMapping[key].value;
    });

    let NewChild;

    if (_.isFunction(children)) {
      NewChild = children(props);
    } else {
      NewChild = React.cloneElement(children, { ...children.props, ...props });
    }

    return <React.Fragment>{NewChild}</React.Fragment>;
  }
}
