import { Component } from 'react';
import * as React from 'react';
import { AppBar, Grid, StyledComponentProps, Toolbar, withStyles, Box } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GridJustification } from '@material-ui/core/Grid';
import LogoComponent from '../LogoComponent';
import { theme } from '../../../../styling/DefaultTheme';
import { DisplayProperty, VisibilityProperty } from 'csstype';
import StopProfileAvatarComponent from '../../avatars/StopProfileAvatarComponent';
import LanguageToolbar from '../../structural/LanguageToolbar';
import AuthenticatedPageWithLoading from '../../../containers/AuthenticatedPageWithLoading';
import style from '../../../../styling/Style';

interface Props extends RouteComponentProps, StyledComponentProps {
  children?: any;
  elevated?: boolean;
  justify?: GridJustification;
  noLeft?: boolean;
  noRight?: boolean;
  noLeftWhenSmall?: boolean;
  noRightWhenSmall?: boolean;
}

const styles = {
  root: {
    backgroundColor: style.colors.baseColorPalette.white,
    height: 80,
    padding: '0 16px'
  },
  invisbleWhenSmall: {
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden' as VisibilityProperty,
      display: 'none' as DisplayProperty
    }
  },
  navBarContainer: {
    alignItems: 'center'
  }
};

class TopNavBar extends Component<Props, any, any> {
  render() {
    const { children, elevated, justify, noLeft, noLeftWhenSmall, noRightWhenSmall } = this.props;
    const classes = this.props.classes || {};
    let bar;

    let grid = (
      <Grid container justify={justify || 'space-between'} className={classes.navBarContainer}>
        {!noLeft ? (
          <Grid className={noLeftWhenSmall ? classes.invisbleWhenSmall : undefined} item md={2} lg={2} xl={1}>
            <LogoComponent link/>
          </Grid>
        ) : (
          <div />
        )}
        {children}
        {noLeftWhenSmall ? (
          <Grid item xs={3} sm={4} md={2} lg={2}>
            <Box pb={3}>
              <Grid container justify={'flex-end'} alignItems="center" wrap={'nowrap'}>
                <AuthenticatedPageWithLoading>
                  {
                    //@ts-ignore
                    <LanguageToolbar />
                  }
                </AuthenticatedPageWithLoading>
                {!noLeft ? (
                  <Grid item className={noRightWhenSmall ? classes.invisbleWhenSmall : undefined}>
                    <Box pl={3}>
                      <StopProfileAvatarComponent />
                    </Box>
                  </Grid>
                ) : (
                  <div />
                )}
              </Grid>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={6} sm={6} md={6}>
            <Grid container alignItems="center" justify={'flex-end'} wrap={'nowrap'}>
              <Box mr={3}>
                <AuthenticatedPageWithLoading>
                  {
                    //@ts-ignore
                    <LanguageToolbar />
                  }
                </AuthenticatedPageWithLoading>
              </Box>
              <Box pl={3}>
                <StopProfileAvatarComponent />
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
    if (elevated) {
      bar = (
        <AppBar className={classes.root} position="static">
          <Toolbar disableGutters style={{ height: '100%' }}>
            {grid}
          </Toolbar>
        </AppBar>
      );
    } else {
      bar = grid;
    }
    return <div>{bar}</div>;
  }
}

export default withStyles(styles)(withRouter(TopNavBar));
