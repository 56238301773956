import { Component } from 'react';
import * as React from 'react';
import LegalPage from '../LegalPage';
import ReactMarkdown from 'react-markdown';
import StaticFetchComponent from '../../../../shared/containers/StaticFetchComponent';

export default class PrivacyPage extends Component<any, any> {
  render() {
    return (
      <LegalPage>
        <StaticFetchComponent endpoint={'/legal/privacy.md'}>{(value: any) => <ReactMarkdown source={value} />}</StaticFetchComponent>
      </LegalPage>
    );
  }
}
