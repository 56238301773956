export interface Operator {
  operatorName: string;
  operator: (currentValue: any, requiredValue: any) => boolean;
}

const EqualsOperator: Operator = {
  operatorName: '=',
  operator: (currentValue: any, requiredValue: any) => String(currentValue) === String(requiredValue) // TODO: remove this when the api allows booleans
};

const NotEqualsOperator: Operator = {
  operatorName: '!=',
  operator: (currentValue: any, requiredValue: any) => currentValue !== requiredValue
};

const GreaterThanOperator: Operator = {
  operatorName: '>',
  operator: (currentValue: any, requiredValue: any) => currentValue > requiredValue
};

const LessThanOperator: Operator = {
  operatorName: '<',
  operator: (currentValue: any, requiredValue: any) => currentValue < requiredValue
};

const ALL_OPERATORS: Array<Operator> = [EqualsOperator, NotEqualsOperator, GreaterThanOperator, LessThanOperator];

interface OperatorMap {
  [key: string]: (currentValue: any, requiredValue: any) => boolean;
}

export const OPERATOR_MAP: OperatorMap = ALL_OPERATORS.reduce((obj, item) => {
  return Object.assign({}, obj, {
    [item.operatorName]: item.operator
  });
}, {});

export interface Answer {
  id: string | number;
  value: any;
}

export interface AnswerGroup {
  id: string | number;
  answers: Array<AnswerGroup | Answer>;
  intentionallyBlank?: {
    reason: 'UNKNOWN' | 'NO_CONSENT' | 'UNMET_DEPENDENCY' | 'UNRECOGNIZED';
  };
}

export function isAnswerGroup(arg: any): arg is AnswerGroup {
  return arg && arg.answers;
}

export interface Dependencies {
  dependsOn: string;
  dependsOnValue: any;
  dependsOnOperator?: string;
}

export interface QuestionData {
  props: any;
  dependencies?: Array<Dependencies>;
  needsConsent?: Array<string>;
  id: string;
}

export interface QuestionMetaData {
  imageUrl?: string;
}

export interface Question {
  type: string;
  data: QuestionData;
  metaData?: QuestionMetaData;
  answer?: Answer;
}

export interface QuestionProps {
  value?: any;
  handleChange: (value: any) => void;
  type: string;
  dontKnowInstruction?: any;
}

export interface QuestionGroup {
  questions: Array<QuestionOrGroup>;
  data: QuestionData;
  answers?: AnswerGroup;
}

export function isQuestionGroup(arg: any): arg is QuestionGroup {
  return arg && arg.questions;
}

export interface QuestionOrGroup {
  questionOrGroup: Question | QuestionGroup;
}
