import { State } from '../Reducers';
import { SET_AUTH_INITIALISED } from './FirebaseActions';

export interface FirebaseState {
  authIsInitialised: boolean;
}

export const firebaseInitialState: FirebaseState = {
  authIsInitialised: false
};

function authInitReduction(state: State) {
  return Object.assign({}, state, {
    firebase: { authIsInitialised: true }
  });
}

export const FirebaseReducers = {
  [SET_AUTH_INITIALISED]: authInitReduction
};
