import { Translations } from '../interfaces/Translations';

import { homepage_translations_nl } from './HomePageTranslations_NL';
import { profilepage_translations_nl } from './ProfilePageTranslations_NL';
import { authenticationpage_translations_nl } from './AuthenticationPageTranslations_NL';
import { forgotpasswordpage_translations_nl } from './ForgotPasswordPageTranslations_NL';
import { landingpage_translations_nl } from './LandingPageTranslations_NL';
import { questionnaire_translations_nl } from './QuestionnaireTranslations_NL';
import { nomatch_translations_nl } from './NoMatchPageTranslations_NL';
import { core_translations_nl } from './CoreTranslations_NL';
import { FlavoursTranslation_NL } from './FlavoursTranslation_NL';
import { FlavoursDescriptionTranslation_NL } from './FlavoursDescriptionTranslation_NL';
import {resetpasswordpage_translations_nl} from "./ResetPasswordPageTranslations_NL";
import {embedded_auth_NL} from "./EmbeddedAuthTranslations";

const translations_nl: Translations = {
  ...homepage_translations_nl,
  ...profilepage_translations_nl,
  ...authenticationpage_translations_nl,
  ...forgotpasswordpage_translations_nl,
  ...resetpasswordpage_translations_nl,
  ...landingpage_translations_nl,
  ...questionnaire_translations_nl,
  ...nomatch_translations_nl,
  ...core_translations_nl,
  ...FlavoursTranslation_NL,
  ...FlavoursDescriptionTranslation_NL,
  ...embedded_auth_NL
};

export default translations_nl;
