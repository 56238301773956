import { Component } from 'react';
import * as React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { theme } from '../../../styling/DefaultTheme';
import { TextAlignProperty } from 'csstype';
import FlavourIdTestComponent from './FlavourIdTestComponent';
import HealthScoreFetcher from '../../../API/containers/HealthScoreFetcher';
import AuthenticatedPageWithLoading from '../../../shared/containers/AuthenticatedPageWithLoading';
import HealthScoreComponent from './HealthScoreComponent';

const alignTextCenter: { textAlign: TextAlignProperty } = { textAlign: 'center' };

const styles = {
  healthScoreCard: {
    ...alignTextCenter,
    width: 'fit-content',
    heigth: 'fit-content',
    [theme.breakpoints.up('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  rightPaneContainer: {
    marginTop: 16
  }
};

class RightPaneContainer extends Component<any, any, any> {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={`${classes.rightPaneContainer}`} justify={'center'} spacing={3}>
        {/* Health Score component */}
        <Grid item xs={12}>
          <AuthenticatedPageWithLoading>
            <HealthScoreFetcher component={HealthScoreComponent} />
          </AuthenticatedPageWithLoading>
        </Grid>
        <Grid item xs={12}>
          <FlavourIdTestComponent />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(RightPaneContainer);
