import { Component } from 'react';
import * as React from 'react';
import { AppBar, Grid, StyledComponentProps, Toolbar, withStyles } from '@material-ui/core';
import LogoComponent from '../LogoComponent';
import { theme } from '../../../../styling/DefaultTheme';
import style from '../../../../styling/Style';
import LanguageToolbar from "../../structural/LanguageToolbar";

interface Props extends StyledComponentProps {
  useSmallSize?: boolean;
}

const styles = {
  root: {
    backgroundColor: 'transparent',
    height: 80,
    padding: '0 16px',
    boxShadow: 'none'
  },
  navBarContainer: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end'
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center'
    }
  },
  toolBar:{

  },
  toolBarHalf:{
    background:style.colors.baseColorPalette.white,
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      background: 'transparent',
    }
  },
  logoContainer: {
    [theme.breakpoints.down('sm')]: {
      height: 24,
      marginLeft: 20
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 55
    }
  }
};

class AuthenticationNavbar extends Component<Props, any, any> {
  render() {
    const classes = this.props.classes || {};
    const {useSmallSize} = this.props;

    return (
      <AppBar className={classes.root} position="fixed">
        <Toolbar disableGutters style={{ height: '100%' }} className={useSmallSize?classes.toolBarHalf:classes.toolBar}>
          <Grid container justify={'space-between'} className={classes.navBarContainer}>
            <Grid item md={2} lg={2} xl={1}>
              <div className={classes.logoContainer}>
                <LogoComponent />
              </div>
            </Grid>
            <Grid item md={2} lg={2} xl={1} style={{marginRight:10}}>
              {
                //@ts-ignore
                <LanguageToolbar switchLanguageDialog={false} />
              }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(AuthenticationNavbar);
