import { Component } from 'react';
import * as React from 'react';
import { Grid, withStyles, Typography, Box } from '@material-ui/core';
import { FlavourIdData, FlavourIdStatus } from '../../../models/user-models/UserModels';
import { FormattedMessage } from 'react-intl';
import style from '../../../styling/Style';
import StyledParagraph from '../../../styling/StyledParagraph';
import { chunkArray } from '../../../utils/Arrays';
import { flavourIDUrls } from './urlMappings';
import { flavourColors } from './flavourColors';
import StyledCard from '../../../styling/StyledCard';
import { capitalize } from '../../../utils/String';
import AromaTypeViz from "./AromaTypeViz";

const styles = (theme: any) => ({
  flavoursBlock: {
    position: 'relative' as 'relative',
    cursor: 'pointer'
  },
  colorBlock: {
    width: 11,
    height: 11,
    borderRadius: '50%',
    display: 'inline-flex'
  },
  colorBlockContainer: {
    display:'flex',
    width:24,
    height:20,
    position:'absolute' as 'absolute',
    alignItems:'center',
    justifyContent:'center'
  },
  fitContent: {
    width: 'fit-content'
  },
  colorTuplesTitle: {
    ...style.typography.minion,
    display: 'inline',
    marginLeft: 24
  },
  favourIdTasteTitle: {
    ...style.typography.greatPrimer,
    color: style.colors.grayscale[2]
  },
  flavourDescriptionCard: {
    backgroundColor: 'white',
    position: 'absolute' as 'absolute',
    display: 'none',
    top: 0,
    left: 90,
    zIndex: 9
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  descriptionCardText: {
    color: style.colors.grayscale[1],
    '& span': {
      ...style.typography.body_strong,
      paddingRight: 4
    }
  },
  flavorIdImageContainer: {
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '470px'
    },
  }
});

const loadingUrl = flavourIDUrls['Loading.png'];
const unavailableUrl = flavourIDUrls['Unavailable.png'];

interface Props {
  width?: string | number;
  profile?: any; // TODO: fill this in later
  classes?: any;
  withBlocks?: boolean;
  flavourIdData?: FlavourIdData;
}

interface State {
  hover?: boolean;
  currentKey?: string;
}

class FlavourIdComponent extends Component<Props, State, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hover: false,
      currentKey: ''
    };
  }

  flavourIDIsUpdating() {
    const { flavourIdData } = this.props;
    return flavourIdData && flavourIdData.status === FlavourIdStatus.FLAVORID_STATUS_UPDATING;
  }

  activateHover(currentKey: string) {
    this.setState({
      hover: true,
      currentKey: currentKey
    });
  }

  hideHover(currentKey: string) {
    if (this.state.currentKey === currentKey)
      this.setState({
        hover: false
      });
  }

  render() {
    const { classes, flavourIdData } = this.props;
    const categories = flavourIdData?.visualisation?.categories ?? [];
    const aggregated = categories.find((x: any) => x.category === 'aggregated')?.aromas ?? {};
    const topDrivers = categories.find((x: any) => x.category === 'top_drivers')?.aromas;
    const topTypesLower = topDrivers ? Object.entries(topDrivers)
      .sort((a: [string, number], b: [string, number]) => a[1] - b[1])
      .map(x => x[0]): null;
    const topTypes = topTypesLower?.map(capitalize);
    // const imageUrl = flavourIDUrls[imageName || ''] || flavourIDUrls[imageNameSwapped || ''] || unavailableUrl;

    const namesColorElement = Object.keys(flavourColors)
      .filter((key) => key !== 'Chemical' && aggregated[key] > 0)  // don't show chemical and only show drivers (> 0)
      .sort((a, b) => {
        const aggA = aggregated[a];
        const aggB = aggregated[b];
        return (aggB || - Infinity) - (aggA || - Infinity)
      })
      .map((key) => {
        const value = aggregated[key] || 0;
        // FIXME better scaling
        const size = value === 0.0 ? 5 : Math.max(Math.min(11 + (20 * value) / 10, 20), 7);
        const backgroundColor = value === 0.0 ? 'black' : flavourColors[key];
        return (
          <Grid item key={key} className={classes.flavoursBlock} onMouseEnter={() => this.activateHover(key)} onMouseLeave={() => this.hideHover(key)}>
            <Box p={1}>
              <div className={`${classes.colorBlockContainer}`}>
                <div className={`${classes.colorBlock}`} style={{ backgroundColor: backgroundColor, width: size, height: size }} />
              </div>
              <Typography className={classes.colorTuplesTitle}>
                <FormattedMessage id={key || "flavorid_unknown_aroma"} />
              </Typography>
            </Box>
            <div className={`${classes.flavourDescriptionCard} ${this.state.hover && key === this.state.currentKey ? classes.show : classes.hide}`}>
              <StyledCard size="sm" className={classes.descriptionCardText}>
                <span>{key}</span>
                <FormattedMessage id={`${key.toLowerCase()}_description`} />
              </StyledCard>
            </div>
          </Grid>
        );
      });

    const groupedNamesColorElement = chunkArray(4, namesColorElement).map((group: any[], index: number) => (
      <Grid item key={index} xs={4} lg={12}>
        <Grid container direction="column">
          <div>{group}</div>
        </Grid>
      </Grid>
    ));

    const blocks = (
      <>
        <Grid item>
          <Grid container>{groupedNamesColorElement}</Grid>
        </Grid>
      </>
    );

    return (
      <Grid container alignItems="center" style={{ height: '100%' }}>
        <Grid item xs={12}>
          {topTypes ? (
            <>
              {topTypes && topTypes.length === 2 && (
                <Typography className={classes.favourIdTasteTitle}>
                  {<FormattedMessage id={topTypes[0] || "flavorid_unknown_aroma"}/>} & {<FormattedMessage id={topTypes[1] || "flavorid_unknown_aroma"}/>}
                </Typography>
              )}
              <Grid container justify="center">
                <Grid item xs={12} md={12} lg={6}>
                  <Box py={[0, 0, 0, 6]}>
                    <AromaTypeViz containerClasses={classes.flavorIdImageContainer} topTypes={topTypes} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={5}>
                  <Box mt={[4, 4, 4, 10]} px={[0, 0, 8]}>
                    {blocks}
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={8}>
                <StyledParagraph>{
                  this.flavourIDIsUpdating() ? (
                    <FormattedMessage id={'flavourid_updating'} />
                  ): (
                    <FormattedMessage id={'flavourid_not_available'} />
                  )
                }</StyledParagraph>
                {this.flavourIDIsUpdating() ? (
                  <img alt="flavorid loading" src={loadingUrl} className={classes.flavorIdImageContainer} />
                ): (
                  <img alt="flavorid unavailable" src={unavailableUrl} className={classes.flavorIdImageContainer} />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, {withTheme: true})(FlavourIdComponent);
