import { Component } from 'react';
import * as React from 'react';
import { StyledComponentProps, withStyles, Card, Box } from '@material-ui/core';
import style from './Style';

const styles = {
  root: {
    border: '1px solid' + style.colors.grayscale[6],
    borderRadius: 12,
    boxShadow: 'none',
    width: '100%',
    backgroundColor: 'white'
  }
};

interface Props extends StyledComponentProps {
  fullWidth?: boolean;
  size: string;
  className?: any;
  children: any;
}

class StyledCard extends Component<Props, any> {
  render() {
    const classes = this.props.classes || {};
    const { size, children, className } = this.props;

    return (
      <>
        <Card className={`${classes.root} ${className}`}>
          {size === 'sm' && (
            <Box pt={4} pb={3} px={3}>
              {children}
            </Box>
          )}
          {size === 'lg' && <Box py={5} px={4}>{children}</Box>}
        </Card>
      </>
    );
  }
}

export default withStyles(styles)(StyledCard);
