import { Component } from 'react';
import * as React from 'react';
import { isQuestionGroup, Question, QuestionGroup, QuestionOrGroup } from '../../../models/questionnaire-models/internal/QuestionModels';
import QuestionRenderer from './QuestionRenderer';
import { State } from '../../../redux/Reducers';
import { connect } from 'react-redux';
import { FlavourIDUser } from '../../../models/user-models/UserModels';
import ConsentComponent from '../containers/ConsentComponent';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import AuthenticatedPageWithLoading from '../../../shared/containers/AuthenticatedPageWithLoading';
import style from '../../../styling/Style';
import StyledParagraph from '../../../styling/StyledParagraph';
import I18NFromObjectComponent from "../../../shared/components/I18NFromObjectComponent";

interface Props extends StyledComponentProps {
  questionOrGroup: QuestionOrGroup;
  handleChange: (newQuestionOrGroup: QuestionOrGroup) => void;
  flavourIdUser?: FlavourIDUser;
}

const styles = {
  consentOverlay: {
    position: 'absolute' as 'absolute',
    zIndex: 10,
    width: '100%',
    top: 79,
    left: 0
  }
};

class QuestionOrGroupView extends Component<Props, any> {
  renderQuestion(question: Question, handleChange: (value: any) => void) {
    return (
      <QuestionRenderer
        key={question.data.id}
        question={question}
        value={question.answer ? question.answer.value : undefined}
        handleChange={(value) => {
          const newQuestionOrGroup: Question = Object.assign({}, { ...question });
          if (!newQuestionOrGroup.answer) {
            newQuestionOrGroup.answer = {
              value: value,
              id: newQuestionOrGroup.data.id
            };
          } else {
            newQuestionOrGroup.answer.value = value;
          }
          handleChange({ questionOrGroup: newQuestionOrGroup });
        }}
      />
    );
  }

  handleGroupChange = (value: QuestionOrGroup, currentGroup: QuestionGroup, childGroup: QuestionOrGroup) => {
    const newQuestionOrGroup: QuestionGroup = Object.assign({}, { ...currentGroup });
    const id = childGroup.questionOrGroup.data.id;
    if (!newQuestionOrGroup.answers) {
      newQuestionOrGroup.answers = {
        answers: [],
        id: currentGroup.data.id
      };
    }
    const currentAnswerIndex = newQuestionOrGroup.answers.answers.findIndex((value1) => value1.id === id);
    let newAnswer;
    if (isQuestionGroup(childGroup)) {
      const group: QuestionGroup = value.questionOrGroup as QuestionGroup;
      if (group.answers) {
        newAnswer = { id: id, answers: group.answers.answers };
      }
    } else {
      const question: Question = value.questionOrGroup as Question;
      if (question.answer) {
        newAnswer = { id: id, value: question.answer.value };
      }
    }
    if (newAnswer) {
      if (currentAnswerIndex === -1) {
        newQuestionOrGroup.answers.answers.push(newAnswer);
      } else {
        newQuestionOrGroup.answers.answers[currentAnswerIndex] = newAnswer;
      }
    }
    return { questionOrGroup: newQuestionOrGroup };
  };

  hasConsents(group: QuestionGroup, user: FlavourIDUser) {
    return !(!group.data.needsConsent || group.data.needsConsent.every((consent: string) => user.consent && user.consent[consent].given));
  }

  renderGroup(group: QuestionGroup, handleChange: (value: any) => void) {
    const { flavourIdUser } = this.props;
    const classes = this.props.classes || {};
    return (
      <div>
        <div>
          {group.data.props.name ?
              <I18NFromObjectComponent object={group.data.props.name}>
                {(value: any) => <p style={style.typography.pica_strong}>{value}</p>}
              </I18NFromObjectComponent>
              : <div />}
          <div>
            {group.questions.map((questionOrGroup) => (
              <QuestionOrGroupView
                flavourIdUser={flavourIdUser}
                key={questionOrGroup.questionOrGroup.data.id}
                questionOrGroup={questionOrGroup}
                handleChange={(value) => {
                  handleChange(this.handleGroupChange(value, group, questionOrGroup));
                }}
              />
            ))}
          </div>
          <div className={this.hasConsents(group, flavourIdUser as FlavourIDUser) ? `${classes.consentOverlay}` : ``} />
        </div>
        {group.data.needsConsent ? (
          group.data.needsConsent
            .filter((consent: string) => {
              return !(flavourIdUser as FlavourIDUser).consent || !(flavourIdUser as FlavourIDUser).consent[consent] || !(flavourIdUser as FlavourIDUser).consent[consent].given;
            })
            .map((consent) => (
              <AuthenticatedPageWithLoading>
                {
                  // @ts-ignore
                  <ConsentComponent consent={consent} />
                }
              </AuthenticatedPageWithLoading>
            ))
        ) : (
          <div />
        )}
      </div>
    );
  }

  render() {
    const { questionOrGroup, handleChange } = this.props;
    if (this.props.flavourIdUser === undefined) {
      return <StyledParagraph>Invalid rendering, there exists no flavour id user</StyledParagraph>;
    }
    if (isQuestionGroup(questionOrGroup.questionOrGroup)) {
      return this.renderGroup(questionOrGroup.questionOrGroup as QuestionGroup, handleChange);
    } else {
      return this.renderQuestion(questionOrGroup.questionOrGroup as Question, handleChange);
    }
  }
}

const mapStateToProps = (state: State) => {
  return {
    flavourIdUser: state.authentication.flavourIdUser
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(QuestionOrGroupView));
