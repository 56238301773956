import { connect as refetchConnect } from 'react-refetch';
import firebase from 'firebase/app';

// const baseUrl = 'https://meus.internal.staging.foodpairing.com';
const baseUrl = '';

const createQueryParamString = (queryParams: { [key: string]: string | undefined }) => {
  return Object.entries(queryParams || {})
    .map((k) => `${k[0]}=${k[1] || ''}`)
    .join('&');
};

export const addQueryParams = (queryParams: { [key: string]: string | undefined }) => {
  const queryString = createQueryParamString(queryParams);
  if (queryString) {
    return '?' + queryString;
  }
  return '';
};

export const connect = refetchConnect.defaults({
  buildRequest: function (mapping) {
    const headers: { [key: string]: any } = { ...mapping.headers, Accept: 'application/json' };
    if (firebase.auth().currentUser !== null) {
      headers['authorization'] = 'Bearer ' + (mapping.authenticationToken || 'NO AUTHENTICATION NEEDED');
    }
    const options: object = {
      method: mapping.method,
      headers: headers,
      credentials: mapping.credentials,
      redirect: mapping.redirect,
      mode: mapping.mode,
      body: mapping.body
    };

    const url = baseUrl + mapping.url + addQueryParams(mapping.queryParams);

    return new Request(url, options);
  }
});
