import { AppIntegrationEnumeration } from '../../models/integration-models/external/IntegrationModels';
import { transformIntegrationTypeProps } from '../../models/integration-models/transformers/IntegrationTransformer';
import { Component, default as React, ReactElement } from 'react';
import SecondaryProfileMiddleware from '../refetch/SecondaryProfileMiddleware';
import PromiseStatePage from '../../shared/containers/PromiseStatePage';
import { AuthenticatedProps } from '../../shared/containers/AuthenticatedPageWithLoading';
import { PromiseState } from 'react-refetch';

interface Props extends AuthenticatedProps {
  integrations: PromiseState<AppIntegrationEnumeration | undefined>;
  component?: ReactElement;
  children?: any;
}

export interface ValueType {
  integrations: AppIntegrationEnumeration;
}

class IntegrationFetcher extends Component<Props, any> {
  render() {
    const { integrations, authToken, children } = this.props;
    const Component = this.props.component;
    return (
      <PromiseStatePage promiseStateMapping={{ integrations: integrations }}>
        {(values: ValueType) => {
          if (Component) {
            // @ts-ignore
            return <Component integrations={values.integrations} authToken={authToken} />;
          } else {
            return children(values, authToken);
          }
        }}
      </PromiseStatePage>
    );
  }
}

export default SecondaryProfileMiddleware((props: any) => {
  return {
    integrations: {
      url: '/stop/integration',
      authenticationToken: props.authToken,
      then: (integrations: any) => {
        const internalAppIntegrationEnumeration = transformIntegrationTypeProps(integrations, 'enumeration');
        return {
          value: internalAppIntegrationEnumeration
        };
      }
    }
  };
}, IntegrationFetcher);
