import { State } from '../Reducers';
import { SET_CURRENT_INDEX, SET_CURRENT_QUESTIONNAIRE, SET_CURRENT_QUESTIONNAIRE_NAME, SET_QUESTION_STATUSES } from './QuestionnaireActions';
import _ from 'lodash';
import { Questionnaire } from '../../models/questionnaire-models/internal/QuestionnaireModels';

export const IDLE = 'IDLE';
export const SKIPPED = 'SKIPPED';
export const DONE = 'DONE';
export const UNRECOGNIZED = 'UNRECOGNIZED';

export const STATES = [DONE, IDLE, UNRECOGNIZED, SKIPPED];

export type QuestionStatus = 'IDLE' | 'SKIPPED' | 'DONE' | 'UNRECOGNIZED';

export type QuestionStatuses = Array<QuestionStatus>;

export interface QuestionnaireState {
  questionnaireQuestionStatuses: QuestionStatuses;
  currentIndex: number;
  currentQuestionnaire?: Questionnaire;
  currentQuestionnaireName: string;
}

export const QuestionnaireInitialState: QuestionnaireState = {
  questionnaireQuestionStatuses: [],
  currentIndex: 0,
  currentQuestionnaire: undefined,
  currentQuestionnaireName: ''
};

function questionStatusesReduction(state: State, values: any) {
  const newState = _.cloneDeep(state);
  newState.questionnaire.questionnaireQuestionStatuses = values.questionnaireQuestionStatuses;
  return newState;
}

function currentIndexReduction(state: State, values: any) {
  const newState = _.cloneDeep(state);
  newState.questionnaire.currentIndex = values.currentIndex;
  return newState;
}

function currentQuestionnaireReduction(state: State, values: any) {
  const newState = _.cloneDeep(state);
  newState.questionnaire.currentQuestionnaire = values.currentQuestionnaire;
  return newState;
}

function currentQuestionnaireNameReduction(state: State, values: any) {
  const newState = _.cloneDeep(state);
  newState.questionnaire.currentQuestionnaireName = values.currentQuestionnaireName;
  return newState;
}

export const QuestionnaireReducers = {
  [SET_QUESTION_STATUSES]: questionStatusesReduction,
  [SET_CURRENT_INDEX]: currentIndexReduction,
  [SET_CURRENT_QUESTIONNAIRE]: currentQuestionnaireReduction,
  [SET_CURRENT_QUESTIONNAIRE_NAME]: currentQuestionnaireNameReduction
};
