import { Component } from 'react';
import * as React from 'react';
import { Dialog, FormHelperText, StyledComponentProps } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { theme } from '../../../styling/DefaultTheme';
import DialogTitle from '@material-ui/core/DialogTitle';
import StyledTextField from '../../../styling/StyledTextField';
import StyledButton from '../../../styling/StyledButton';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { connect } from '../../../RefetchConfig';
import { connect as ReduxConnect } from 'react-redux';
import { AuthenticatedProps } from '../../containers/AuthenticatedPageWithLoading';
import { State } from '../../../redux/Reducers';
import { FlavourIDUser, SecondaryProfile } from '../../../models/user-models/UserModels';
import { setFlavourIdUser } from '../../../redux/authentication/LoginActionCreators';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { Userpilot } from 'userpilot'

interface Props extends StyledComponentProps, AuthenticatedProps {
  open: boolean;
  onClose: () => void;
  flavourIdUser?: FlavourIDUser;
  setFlavourIdUser: (user: FlavourIDUser) => void;
  postSecondaryProfile: (username: string) => void;
  intl: IntlShape;
}

interface MyState {
  username: string;
}

const styles = {
  closeButton: {
    //position: 'absolute' as PositionProperty,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary
  },
  dialog: {
    minWidth: '300px',
    minHeight: '300px'
  }
};

const AddUserSchema = (props: Props) => {
  const { intl } = props;

  return Yup.object().shape({
    username: Yup.string()
      .min(2, intl.formatMessage({ id: 'add_user_dialog_username_too_short' }))
      .max(20, intl.formatMessage({ id: 'add_user_dialog_username_too_long' }))
      .required(intl.formatMessage({ id: 'authentication_required' })),
    global: Yup.string().notRequired()
  });
};

/*
const AddUserSchema = Yup.object().shape({
    username: Yup.string()
        .min(4, 'Username is too short')
        .max(20, 'Username is too long')
        .required('Required'),
    global: Yup.string().notRequired()
});
*/

// type UserName = Yup.InferType<typeof AddUserSchema>;

class AddUserDialog extends Component<Props, MyState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      username: ''
    };
  }

  handleClose(values: any, formikHelpers: FormikHelpers<any>) {
    const { onClose, postSecondaryProfile } = this.props;

    postSecondaryProfile(values.username);

    onClose();
  }

  render() {
    const { open, onClose, intl } = this.props;
    const classes = this.props.classes || {};

    return (
      <Dialog onClose={onClose} open={open}>
        <div className={`${classes.dialog}`}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <DialogTitle>
                <FormattedMessage id={'add_user_dialog_add_user'} />
              </DialogTitle>
            </Grid>
            <Grid item>
              <IconButton onClick={onClose} className={classes.closeButton}>
                <CloseIcon style={{ width: 24, height: 24, color: 'black' }} />
              </IconButton>
            </Grid>
          </Grid>

          <Formik
            initialValues={{
              username: '',
              global: ''
            }}
            validationSchema={AddUserSchema(this.props)}
            onSubmit={(values, formikHelpers) => this.handleClose(values, formikHelpers)}
          >
            {({ errors, touched, handleSubmit, setErrors }) => (
              <Form translate="yes" onSubmit={handleSubmit}>
                <Grid container style={{ width: '100%', height: '100%' }} justify="center" alignContent="center" direction="column">
                  <Grid item style={{ width: '100%', padding: 8 }}>
                    <Field name="username" type="username">
                      {(field: FieldProps<any>) => (
                        <StyledTextField
                          {...field}
                          fullWidth={true}
                          label={<FormattedMessage id={'add_user_dialog_label'} />}
                          placeholder={intl.formatMessage({ id: 'add_user_dialog_placeholder' })}
                          margin="normal"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item style={{ width: '100%', padding: 8 }}>
                    <Field name="global">{(field: FieldProps<any>) => <FormHelperText error={true}>{field.meta.error}</FormHelperText>}</Field>
                  </Grid>
                  <Grid item style={{ width: '100%', padding: 8, marginTop: 16 }}>
                    <StyledButton type="submit" size="lg">
                      <FormattedMessage id={'add_user_dialog_create_user'} />
                    </StyledButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

// @ts-ignore
const refetchConnected = connect((props: Props) => ({
  postSecondaryProfile: (name: string) => {
    return {
      resultConsents: {
        url: `/stop/user/secondary_profile/`,
        authenticationToken: props.authToken,
        method: 'POST',
        body: JSON.stringify({
          name: name
        }),
        then: (subject: { secondaryProfiles: SecondaryProfile[] }) => {
          const newUser = props.flavourIdUser!!;
          newUser.secondaryProfiles = subject.secondaryProfiles;
          props.setFlavourIdUser(newUser);
          if (newUser?.id) {
            Userpilot.identify(newUser.id, {});
            Userpilot.track("secondary_user_added", {name});
          }
        }
      }
    };
  }
}))(withStyles(styles)(AddUserDialog));

export default injectIntl(
  ReduxConnect(
    (state: State) => ({
      flavourIdUser: state.authentication.flavourIdUser
    }),
    (dispatch) => ({
      setFlavourIdUser: (flavourIdUser: FlavourIDUser) => {
        dispatch(setFlavourIdUser(flavourIdUser));
      }
    })
  )(refetchConnected)
);
