import type { Props } from "./index.d";
import React from "react";

export const Paragraph: React.FC<Props> =
({
    children,
    ...props
}) => {
    return (
        <p {...props}>
            {children}
        </p>
    );
}
export default Paragraph;
