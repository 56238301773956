import { Component } from 'react';
import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import StyledParagraph from '../../../styling/StyledParagraph';
import { FormattedMessage } from 'react-intl';
import StyledLink from '../../../styling/StyledLink';

interface Props {}

class AuthenticationSwitch extends Component<Props, any> {
  render() {
    const pathname = window.location.pathname;

    return (
      <>
        <Box my={7}>
          <Grid container>
            <StyledParagraph variation="minion">
              <FormattedMessage id={pathname.includes('/signup') ? 'already_have_account' : 'no_account'}></FormattedMessage>
            </StyledParagraph>
            <Box pl={2}>

            <StyledLink path={pathname.includes('/signup') ? '/signin' : '/signup'}>
              <StyledParagraph variation="minion">
                <FormattedMessage id={pathname.includes('/signup') ? 'signin_button' : 'signup_button'}></FormattedMessage>
              </StyledParagraph>
            </StyledLink>
            </Box>

          </Grid>
        </Box>
      </>
    );
  }
}

export default AuthenticationSwitch;
