import * as React from 'react';
import AuthenticationPage from '../../containers/AuthenticationPageView';
import { Box } from '@material-ui/core';
import SignInForm from './SignInForm';
import { AuthenticationViewType } from '../AuthenticationTypesInterfaces';
import firebase from 'firebase/app';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SIGNUP_QUESTIONNAIRE } from '../../AuthenticationViewTypes';
import NormalBlock from '../../../../styling/blocks/Normal';
import { FormattedMessage } from 'react-intl';
import {State} from "../../../../redux/Reducers";
import {connect} from "react-redux";
import {Redirect} from "react-router";

const providerMap: { [key: string]: firebase.auth.AuthProvider } = {
  [firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD]: new firebase.auth.GoogleAuthProvider(),
  [firebase.auth.TwitterAuthProvider.TWITTER_SIGN_IN_METHOD]: new firebase.auth.TwitterAuthProvider()
};

interface Props extends RouteComponentProps {
  onAction: any;
  isAuthenticated: boolean | undefined;
  isEmailVerified: boolean | undefined;
}

class SignInPageView extends AuthenticationViewType<Props, any> {
  static NEXT_VIEW: string = SIGNUP_QUESTIONNAIRE;

  getQueryParams() {
    return new URLSearchParams(this.props.location.search);
  }

  getEmail() {
    return this.getQueryParams().get('email') || undefined;
  }

  async signinWithEmail(email: string) {
    const methods = await firebase.auth().fetchSignInMethodsForEmail(email);
    const popupMethods = methods.filter((m) => providerMap[m]);
    if (popupMethods) {
      await firebase.auth().signInWithPopup(providerMap[popupMethods[0]]);
    }
  }

  componentDidMount() {
    const email = this.getEmail();
    const currentUser = firebase.auth().currentUser;

    if (!email) {
      return;
    }

    if (currentUser && currentUser.email !== email) {
      firebase
        .auth()
        .signOut()
        .then(() => this.signinWithEmail(email));
    } else if (!currentUser) {
      this.signinWithEmail(email);
    } else {
      this.props.history.replace('/profile');
    }
  }

  render() {
    const { onAction, isAuthenticated, isEmailVerified } = this.props;

    if(isAuthenticated && isEmailVerified){
      return <Redirect to={'/'} />;
    }

    return (
      <AuthenticationPage>
        <Box textAlign="left">
          <NormalBlock title={<FormattedMessage id={'signin_form_title'}></FormattedMessage>}>
            <FormattedMessage id={'signin_form_description'}></FormattedMessage>
          </NormalBlock>
        </Box>
        <SignInForm email={this.getEmail()} onAction={onAction} />
      </AuthenticationPage>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    isAuthenticated: state.authentication.authenticated,
    isEmailVerified: state.authentication.currentUser?.emailVerified
  };
};

export default withRouter(connect(mapStateToProps, {})(SignInPageView));
