export const flavourIDUrls:{ [key: string]: string; }  = {
  "Animal & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FAnimal%20%26%20Cheese.png?alt=media&token=7c5268af-3bb5-4691-9693-1cd0016f7283",
  "Animal & Citrus.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FAnimal%20%26%20Citrus.png?alt=media&token=f1f8bda5-6599-4656-b288-2c85da658fc9",
  "Animal & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FAnimal%20%26%20Floral.png?alt=media&token=eec7fa34-3115-473e-a88f-c6a1d64a2724",
  "Animal & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FAnimal%20%26%20Green.png?alt=media&token=94d6c276-bb4c-45dc-8c47-7b9dbcfebea7",
  "Animal & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FAnimal%20%26%20Nutty.png?alt=media&token=f0f1fe1c-9d53-4f6f-83c4-90d11ca6e673",
  "Animal & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FAnimal%20%26%20Spicy.png?alt=media&token=c2c88736-d76e-475e-8a8e-5acacaba5ca8",
  "Animal & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FAnimal%20%26%20Woody.png?alt=media&token=7bdd0744-fb11-4b26-b12e-66fac708df35",
  "Caramel & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Animal.png?alt=media&token=cf8f5018-f023-49b2-9fd1-524d313296c3",
  "Caramel & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Cheese.png?alt=media&token=39c56881-8077-4d8f-b93d-4ed1c596b563",
  "Caramel & Citrus.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Citrus.png?alt=media&token=50d374b1-c4d8-4bab-9d44-d78e9337639e",
  "Caramel & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Floral.png?alt=media&token=0bcd7acf-0b53-44eb-a8a2-f6cbf556f9bb",
  "Caramel & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Green.png?alt=media&token=85acef02-e2c0-4d8f-8392-86aa43c0ee93",
  "Caramel & Herbal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Herbal.png?alt=media&token=ffbb878b-a7b6-41ac-9f49-4b29f7ec5008",
  "Caramel & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Nutty.png?alt=media&token=7b46cad1-bd0c-4a76-a3fc-aa43c35853a0",
  "Caramel & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Spicy.png?alt=media&token=d20438f5-3fb8-435d-b7dc-078817cbe490",
  "Caramel & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaramel%20%26%20Woody.png?alt=media&token=da08355a-334d-4c6b-a376-818d89bcbcae",
  "Caraml & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCaraml%20%26%20Cheese.png?alt=media&token=277ebf5e-9f0f-49b6-965d-acec53c1a7d1",
  "Cheese & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCheese%20%26%20Animal.png?alt=media&token=88a81ba5-08c3-405e-90b0-c8c236d11247",
  "Cheese & Caramel.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCheese%20%26%20Caramel.png?alt=media&token=01c335f2-3a41-4c08-9529-bdebbf79e3b5",
  "Cheese & Citrus.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCheese%20%26%20Citrus.png?alt=media&token=72a5e18f-fb47-4ef4-8f20-e3403d4ba2aa",
  "Cheese & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCheese%20%26%20Floral.png?alt=media&token=4dd866d2-825e-4c2e-a98f-40adcd88a666",
  "Cheese & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCheese%20%26%20Green.png?alt=media&token=8b306c35-eb0e-4b66-a142-238c4f1febb2",
  "Cheese & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCheese%20%26%20Nutty.png?alt=media&token=9636b0e2-6e44-4fee-a93c-7f87a6ebd336",
  "Cheese & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCheese%20%26%20Spicy.png?alt=media&token=7a9051dc-c80f-4ef8-bc9b-3e52e691cdaf",
  "Cheese & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCheese%20%26%20Woody.png?alt=media&token=e8b473e2-bccd-48f1-b625-ab6bea0dceeb",
  "Citrus & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCitrus%20%26%20Animal.png?alt=media&token=46f45e22-f360-4745-9c94-f7a02365bce7",
  "Citrus & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCitrus%20%26%20Cheese.png?alt=media&token=ffba4c2d-3d04-4143-9b32-9505cafef60c",
  "Citrus & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCitrus%20%26%20Floral.png?alt=media&token=53b15acb-38a5-4f6e-8a57-069c01d8f83c",
  "Citrus & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCitrus%20%26%20Green.png?alt=media&token=f7cd6c6f-34ac-41ce-943d-c5cfbcde08ea",
  "Citrus & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCitrus%20%26%20Nutty.png?alt=media&token=cec0d8f7-553f-40d3-bbcf-0f613ef3934d",
  "Citrus & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCitrus%20%26%20Spicy.png?alt=media&token=ad94508b-2e86-4eb1-bc54-208bb4f8aba1",
  "Citrus & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FCitrus%20%26%20Woody.png?alt=media&token=6e902071-5100-46ff-b281-a0f1983ebcca",
  "FlavourID Loading.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFlavourID%20Loading.png?alt=media&token=e3effe31-327d-452e-a16d-b5d7227494a5",
  "Floral & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFloral%20%26%20Animal.png?alt=media&token=373c1e99-f00b-4c28-b2b7-d2bf9e217a64",
  "Floral & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFloral%20%26%20Cheese.png?alt=media&token=f3d8b38d-669a-449f-b2fa-ac0a83590f84",
  "Floral & Citrus.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFloral%20%26%20Citrus.png?alt=media&token=ca396819-4f48-4ba5-ba3d-a20b0aa26e1c",
  "Floral & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFloral%20%26%20Green.png?alt=media&token=229a5ef9-b283-4271-bbcd-f0d5926f8fa2",
  "Floral & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFloral%20%26%20Nutty.png?alt=media&token=76b9d2d9-7159-40cf-888b-056ee19c063e",
  "Floral & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFloral%20%26%20Spicy.png?alt=media&token=ad1b3971-93a7-4858-9b8d-1bae4af247e4",
  "Floral & Vegetable.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFloral%20%26%20Vegetable.png?alt=media&token=3fc3fe7e-b0eb-477b-9722-ddc52283b94e",
  "Floral & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFloral%20%26%20Woody.png?alt=media&token=fe5b0914-ef0e-42a0-bed7-e5be77f04008",
  "Fruity & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Animal.png?alt=media&token=2706fcf7-4507-493c-b07a-303c32e4d387",
  "Fruity & Caramel.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Caramel.png?alt=media&token=0a44e008-11f3-4798-9f8e-9bc37c8bc9e0",
  "Fruity & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Cheese.png?alt=media&token=0547c181-6c1c-40ab-ad98-1e5869e8416f",
  "Fruity & Citrus.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Citrus.png?alt=media&token=c06640e5-41f9-43fd-8068-db81a393c7f2",
  "Fruity & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Floral.png?alt=media&token=08775741-7b37-42eb-be57-537c066b4aa4",
  "Fruity & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Green.png?alt=media&token=27745a5b-0157-4376-98ee-48343a7ec7f7",
  "Fruity & Herbal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Herbal.png?alt=media&token=b2a15e4e-afc7-4bd1-a904-b192dbe6a35e",
  "Fruity & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Nutty.png?alt=media&token=70324806-e3c9-4f61-b66e-cc625742079d",
  "Fruity & Roasted.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Roasted.png?alt=media&token=6b20522b-f866-42e7-85ce-f308845a38cc",
  "Fruity & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Spicy.png?alt=media&token=85662918-14b1-4db6-9a6a-a22a4b7c48d5",
  "Fruity & Vegetable.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Vegetable.png?alt=media&token=50c0120a-17c7-426f-bfc8-34b84f22781a",
  "Fruity & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FFruity%20%26%20Woody.png?alt=media&token=2eca3e69-85ef-4a51-9644-f34c1432363f",
  "Green & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FGreen%20%26%20Cheese.png?alt=media&token=af29fb7f-c070-41cb-9621-9bcbca779af7",
  "Green & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FGreen%20%26%20Spicy.png?alt=media&token=b3d7c7bb-6edb-452d-80f5-2ebec409da7e",
  "Green & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FGreen%20%26%20Woody.png?alt=media&token=c8b728a4-dd31-4846-9e9b-4ec131599b3c",
  "Herbal & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Animal.png?alt=media&token=d3c3da21-c8bc-4c4f-9884-997a87dcc717",
  "Herbal & Caramel.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Caramel.png?alt=media&token=5c1d6deb-9b40-4fc1-8a3d-6f70f946d17e",
  "Herbal & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Cheese.png?alt=media&token=dbacf8fc-2e58-48ed-be42-03950791210c",
  "Herbal & Citrus.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Citrus.png?alt=media&token=1219775f-6053-45bd-bc7b-ed7260aa670c",
  "Herbal & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Floral.png?alt=media&token=6442565e-5cba-4c3b-8e78-6d8c94087bb7",
  "Herbal & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Green.png?alt=media&token=82c45359-888a-411c-8e51-28bf3b90d019",
  "Herbal & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Nutty.png?alt=media&token=01acb3b3-6143-4688-98c1-eb8e68db1afe",
  "Herbal & Roasted.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Roasted.png?alt=media&token=ae5304db-ca7f-4ea6-8ed4-d74ecb0ff1a2",
  "Herbal & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Spicy.png?alt=media&token=621df8d1-48ec-4f9a-a6ca-d573bf10c075",
  "Herbal & Vegetable.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Vegetable.png?alt=media&token=12547e22-ab7a-4f8d-ab9b-1a4297a9f4de",
  "Herbal & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FHerbal%20%26%20Woody.png?alt=media&token=d6315c9e-0b8e-40b9-8d7d-7b17b1c2037b",
  "Nutty & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FNutty%20%26%20Animal.png?alt=media&token=8411d900-d30b-448c-bf57-d942a46fdeba",
  "Nutty & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FNutty%20%26%20Floral.png?alt=media&token=95be227d-9af1-4778-ac45-83d20fbc5f6b",
  "Nutty & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FNutty%20%26%20Green.png?alt=media&token=7b809acf-b50a-4385-a52b-e46749389746",
  "Nutty & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FNutty%20%26%20Spicy.png?alt=media&token=1dda198b-39fe-4d0f-ad81-0178cdbe13b1",
  "Nutty & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FNutty%20%26%20Woody.png?alt=media&token=57c4f187-99ee-4791-800f-407b2ca5205f",
  "Roasted & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Animal.png?alt=media&token=831af9f6-6569-4ac5-94b2-0181ae5b04cb",
  "Roasted & Caramel.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Caramel.png?alt=media&token=294941b7-656f-44fe-9fb5-30930a119019",
  "Roasted & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Cheese.png?alt=media&token=0a028ca2-811c-4f13-9cbd-b06ce3cda6ea",
  "Roasted & Citrus.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Citrus.png?alt=media&token=787e43ab-5c96-40db-b126-5f2aaba3e0af",
  "Roasted & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Floral.png?alt=media&token=e8d6b1d3-885f-473f-995a-110cbfa3a7f9",
  "Roasted & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Green.png?alt=media&token=cf52ea0a-5fd5-4dc5-bd71-d20701467bfd",
  "Roasted & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Nutty.png?alt=media&token=f6edfeb8-1af4-4310-807d-b3be636b4769",
  "Roasted & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Spicy.png?alt=media&token=ee204ca2-c359-4e5e-80a1-ca4a12939e39",
  "Roasted & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FRoasted%20%26%20Woody.png?alt=media&token=a56a0a86-7441-4efd-93b3-94f376ec1054",
  "Spicy & Fruity.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FSpicy%20%26%20Fruity.png?alt=media&token=f0f241dc-e778-4946-8be8-d63f36bb9ca9",
  "Spicy & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FSpicy%20%26%20Green.png?alt=media&token=de9a4197-bfb1-4225-a565-f116fb700b08",
  "Spicy & Herbal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FSpicy%20%26%20Herbal.png?alt=media&token=a080c8de-460c-4e4e-96ec-7da2e4cdeb7d",
  "Vegetable & Animal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Animal.png?alt=media&token=8db580bd-bb0c-4ba9-98a5-8fbef3f20f37",
  "Vegetable & Caramel.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Caramel.png?alt=media&token=dec08b83-e9a6-45b5-8cb1-20847409f5d1",
  "Vegetable & Cheese.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Cheese.png?alt=media&token=ba4b7af3-0d0b-4f75-992d-c454770f3372",
  "Vegetable & Citrus.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Citrus.png?alt=media&token=a4e6df36-bb76-49e5-a9d7-aa8f4e211847",
  "Vegetable & Floral.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Floral.png?alt=media&token=acd0be2a-932f-4481-a904-edcf311e7a62",
  "Vegetable & Fruity.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Fruity.png?alt=media&token=b08542c9-afee-43c1-83fb-72ad8245b270",
  "Vegetable & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Green.png?alt=media&token=6700cd71-2b7f-4088-b243-c0ebc425e858",
  "Vegetable & Herbal.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Herbal.png?alt=media&token=1978bd18-9930-4e0d-9411-a29a7de145e8",
  "Vegetable & Nutty.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Nutty.png?alt=media&token=3b63917d-b223-443f-bae2-8c98d4101c88",
  "Vegetable & Roasted.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Roasted.png?alt=media&token=fa8b151c-9c78-49d3-bf34-93977cbaaecf",
  "Vegetable & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Spicy.png?alt=media&token=d13a864d-664d-4bbb-ad06-13cafb6f294e",
  "Vegetable & Woody.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FVegetable%20%26%20Woody.png?alt=media&token=7d8bf331-4bb5-4f48-b17d-d699014e3081",
  "Woody & Green.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FWoody%20%26%20Green.png?alt=media&token=8fc14024-d836-47c8-865c-4c0b8c90f729",
  "Woody & Roasted.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FWoody%20%26%20Roasted.png?alt=media&token=90983959-bcc4-4cb0-b23d-62d5d621c15e",
  "Woody & Spicy.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FWoody%20%26%20Spicy.png?alt=media&token=2fad2164-bf9a-438a-a675-6e6e3f27f041",
  "Unavailable.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FYour%20FlavourID%20Graph%20is%20Unavailable.png?alt=media&token=19729878-c8c8-4a4d-906e-bb561b2f6cc2",
  "Loading.png": "https://firebasestorage.googleapis.com/v0/b/data-platform-testing.appspot.com/o/assets%2Fflavorid%2Fprofile%2FYour%20FlavourID%20Graph%20is%20loading.png?alt=media&token=df478dce-9a7c-43fa-aab3-3105a6430086"
};
