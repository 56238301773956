import * as React from 'react';
import { AuthenticationViewProps, AuthenticationViewType } from '../AuthenticationTypesInterfaces';
import { Button, CardActions, CardContent } from '@material-ui/core';
import { SIGNUP_QUESTIONNAIRE } from '../../AuthenticationViewTypes';

export default class SignUpInfoView extends AuthenticationViewType<AuthenticationViewProps, any> {
  static NEXT_VIEW: string = SIGNUP_QUESTIONNAIRE;

  render() {
    // TODO: think about making this a questionnaire as well
    const { onAction } = this.props;
    return (
      <div>
        <CardContent>
          <h4>Add personal info</h4>
          <p>Fill this in with the required information (~name, address, ...)</p>
        </CardContent>
        <CardActions>
          <Button onClick={() => onAction()}>Add Information</Button>
        </CardActions>
      </div>
    );
  }
}
