import { Component } from 'react';
import { QuestionProps } from '../../../models/questionnaire-models/internal/QuestionModels';
import { questionTypesToQuestionAnswerTranslator } from '../../../models/questiontype-models/internal/QuestionTypeModels';

export default abstract class QuestionTypeContainer<A extends QuestionProps, B> extends Component<A, B> {
  handleChange(value: any, props: A) {
    const { handleChange } = this.props;
    handleChange(this.createValue(this.getValue(value, props), props));
  }

  getQuestionClass(): any {
    const { type } = this.props;
    return questionTypesToQuestionAnswerTranslator[type];
  }

  createValue(value: any, props: A) {
    const QuestionClass = this.getQuestionClass();
    return new QuestionClass(props).createValue(value, props);
  }

  getValue(value: any, props: A): any {
    const QuestionClass = this.getQuestionClass();
    return new QuestionClass(props).getValue(value, props);
  }
}
