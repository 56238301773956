import IFlavoursDescriptionTranslation from '../interfaces/IFlavoursDescriptionTranslation';

export const FlavoursDescriptionTranslation_EN: IFlavoursDescriptionTranslation = {
  spicy_description: "Associated with spices like clove, aniseed, cumin, cinnamon and vanilla.",
  caramel_description: "Aromas of cooked, slightly burnt sugar or maple syrup.",
  fruity_description: "Found in various fruits, from strawberry and peach to tropical fruits like pineapple, banana and coconut.",
  cheese_description: "Aromas of ripened cheese, butter and cream, but also of sour, fermented aromas such as dairy products and vinegar.",
  chemical_description: "Includes burnt notes or aromas of rubber, plastic and petrol, often less desired in ingredients.",
  herbal_description: "Typical for fresh herbs such as mint, thyme and lavender.",
  green_description: "Include the typical scent for fresh or raw, untreated ingredients. Includes the scent of freshly mown grass or cucumber, but also the scent of wheat and the metallic aromas in seaweed.",
  vegetable_description: "Vegetable aromas includes the scent of vegetables like bell peppers, mushrooms, cooked potatoes, but also sulphurous aromas that remind you of onion, garlic and cabbage.",
  citrus_description: "Typical for citrus fruits like lemon, orange and grapefruit, but also for ingredients such as coriander seeds and lemongrass.",
  floral_description: "Typical for rose, violet, jasmine, and geranium.",
  animal_description: "Includes the aroma of stock, strong animal aromas like venison or cooked liver, but also ﬁsh and shellfish aromas.",
  roasted_description: "Occur while roasting certain ingredients. Includes roasted meat or vegetables, popcorn, and even the earthy smell of beetroot, as well as coﬀee and chocolate.",
  woody_description: "Aromas like cedar wood, pine tree, but also smoky aromas.",
  nutty_description: "Aromas of nuts, typical for hazelnut, almond or the more perfumed Tonka bean."
};
