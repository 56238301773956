import { Component } from 'react';
import * as React from 'react';
import QuestionOrbLine, { getStates } from '../../QuestionOrbLine';
import TopNavBar from './TopNavBar';
import { connect as reduxConnect } from 'react-redux';
import { State } from '../../../../redux/Reducers';
import { QuestionStatuses } from '../../../../redux/questionnaire/QuestionnaireReducers';
import { setCurrentIndex } from '../../../../redux/questionnaire/QuestionnaireActionCreator';
import { Dispatch } from 'redux';
import { Grid, Typography, Box, StyledComponentProps, withStyles } from '@material-ui/core';
import { Questionnaire } from '../../../../models/questionnaire-models/internal/QuestionnaireModels';
import style from '../../../../styling/Style';
import I18NFromObjectComponent from '../../I18NFromObjectComponent';
import { FormattedMessage } from 'react-intl';
import { theme } from '../../../../styling/DefaultTheme';

const styles = {
  questionnarieTitle: {
    color: style.colors.grayscale[2],
    ...style.typography.minion_strong
  },
  orbLineContainer: {
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center'
    }
  }
};

interface Props extends StyledComponentProps {
  currentStatus: QuestionStatuses;
  currentIndex: number;
  currentQuestionnaireName: string;
  currentQuestionnaire?: Questionnaire;
  setCurrentIndex: (index: number) => void;
  hideOrbs?: boolean;
}

class QuestionnaireNavbar extends Component<Props> {
  // TODO: think about how to show the orbs? Probably using the store although this makes the questionnaires
  // more complex.
  render() {
    const classes = this.props.classes || {};
    const {hideOrbs} = this.props;
    return (
      <TopNavBar elevated noLeftWhenSmall noRightWhenSmall>
        <Grid item xs={9} sm={8}>
          <Grid container justify="center">
            <Grid xs={12} item className={classes.orbLineContainer}>
              {this.props.currentQuestionnaire ? (
                <Box py={2}>
                  <I18NFromObjectComponent object={this.props.currentQuestionnaire.name}>
                    {(value: any) => <Typography className={classes.questionnarieTitle}>{value}</Typography>}
                  </I18NFromObjectComponent>
                </Box>
              ) : (
                <Box py={4}>
                  <FormattedMessage id={'toolbar_questionnaire_no_name'} />
                </Box>
              )}
            </Grid>
            <Grid xs={12} item>
              <Box pb={4}>
                { hideOrbs !== true && <QuestionOrbLine onClick={(index: number) => this.props.setCurrentIndex(index)} questions={getStates(this.props.currentStatus)} currentIndex={this.props.currentIndex} /> }
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </TopNavBar>
    );
  }
}

export default reduxConnect(
  (state: State) => ({
    currentStatus: state.questionnaire.questionnaireQuestionStatuses,
    currentIndex: state.questionnaire.currentIndex,
    currentQuestionnaireName: state.questionnaire.currentQuestionnaireName,
    currentQuestionnaire: state.questionnaire.currentQuestionnaire
  }),
  (dispatch: Dispatch) => ({
    setCurrentIndex: (index: number) => dispatch(setCurrentIndex(index))
  })
)(withStyles(styles)(QuestionnaireNavbar));
