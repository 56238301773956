export interface ConsentObject {
  [key: string]: {
    given: boolean;
    savedAt: string;
  };
}

export interface ConsentPatchObject {
  [key: string]: boolean;
}

export interface SettingsObject {
  language: string;
}

export interface SecondaryProfile {
  addedAt: string;
  id: string;
  name: string;
}

export interface FlavourIDUser {
  email: string;
  id: string;
  name: string;
  settings: SettingsObject;
  consent: ConsentObject;
  secondaryProfiles: SecondaryProfile[];
}

export interface CustomTokenAuthResponse {
  appId: string;
  authToken: string;
}

export interface FlavourIdVisualisationCategory {
  category: string;
  aromas: { [name: string]: number };
}

export interface FlavourIdVisualisation {
  colors: { [name: string]: string };
  categories: FlavourIdVisualisationCategory[];
}

export interface FlavourIdData {
  visualisation?: FlavourIdVisualisation;
  status: FlavourIdStatus;
}

export enum FlavourIdStatus {
  FLAVORID_STATUS_UNKNOWN = 'FLAVORID_STATUS_UNKNOWN',
  FLAVORID_STATUS_INSUFFICIENT_DATA = 'FLAVORID_STATUS_INSUFFICIENT_DATA',
  FLAVORID_STATUS_UPDATING = 'FLAVORID_STATUS_UPDATING',
  FLAVORID_STATUS_OK = 'FLAVORID_STATUS_OK'
}

// const HEALTH_STATUS_UNKNOWN = 'HEALTH_STATUS_UNKNOWN';
// const HEALTH_STATUS_INSUFFICIENT_DATA = 'HEALTH_STATUS_INSUFFICIENT_DATA';
// const HEALTH_STATUS_OK = 'HEALTH_STATUS_OK';
// const UNRECOGNIZED = 'UNRECOGNIZED';

export type HealthScoreStatus = 'HEALTH_STATUS_UNKNOWN' | 'HEALTH_STATUS_INSUFFICIENT_DATA' | 'HEALTH_STATUS_OK' | 'UNRECOGNIZED';

export interface HealthScore {
  status: HealthScoreStatus;
  healthScore: number;
  version?: number;
  healthScoreFruit?: number;
  healthScoreVegetables?: number;
  insufficientKnowledgeFruit?: boolean;
  insufficientKnowledgeVegetables?: boolean;

}

export interface UserSetupConfig {
  enabledExperiments?: string[] | null;
  enabledIntegrations?: string[] | null;
}
