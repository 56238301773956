import { Component } from 'react';
import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

export default class LoadingIndicator extends Component {
  render() {
    return (
      <Grid container spacing={0} alignItems="center" justify="center" style={{ width: '100%', height: '100%', padding: 16 }}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
}
