import { Translations } from '../interfaces/Translations';

import { homepage_translations_en } from './HomePageTranslations_EN';
import { profilepage_translations_en } from './ProfilePageTranslations_EN';
import { authenticationpage_translations_en } from './AuthenticationPageTranslations_EN';
import { forgotpasswordpage_translations_en } from './ForgotPasswordPageTranslations_EN';
import { landingpage_translations_en } from './LandingPageTranslations_EN';
import { questionnaire_translations_en } from './QuestionnaireTranslations_EN';
import { nomatch_translations_en } from './NoMatchPageTranslations_EN';
import { core_translations_en } from './CoreTranslations_EN';
import { FlavoursTranslation_EN } from './FlavoursTranslation_EN';
import { FlavoursDescriptionTranslation_EN } from './FlavoursDescriptionTranslation_EN';
import {resetpasswordpage_translations_en} from "./ResetPasswordPageTranslations_EN";
import {embedded_auth_EN} from "./EmbeddedAuthTranslations";

const translations_en: Translations = {
  ...homepage_translations_en,
  ...profilepage_translations_en,
  ...authenticationpage_translations_en,
  ...forgotpasswordpage_translations_en,
  ...resetpasswordpage_translations_en,
  ...landingpage_translations_en,
  ...questionnaire_translations_en,
  ...nomatch_translations_en,
  ...core_translations_en,
  ...FlavoursTranslation_EN,
  ...FlavoursDescriptionTranslation_EN,
  ...embedded_auth_EN
};

export default translations_en;
