import { Component, MouseEventHandler } from 'react';
import * as React from 'react';
import { ListItem, StyledComponentProps, withStyles, Box } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import style from '../../../styling/Style';
import { BoxSizingProperty } from 'csstype';
import { VerifiedIcon } from '../../profilepage/components/ProfilepageIcons';

const styles = {
  item: {
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: style.colors.grayscale[7],
      '& path': {
        fill: style.colors.brand.brandGreen
      }
    },
    '&:active': {
      border: `1px solid ${style.colors.grayscale[4]}`,
      boxSizing: 'border-box' as BoxSizingProperty,
      boxShadow: '0px 8px 14px rgba(80, 93, 100, 0.21)',
      borderRadius: 4
    }
  },
  primaryText: {
    ...style.typography.body_strong
  },
  secondaryText: {
    ...style.typography.minion
  }
};

interface Props extends RouteComponentProps, StyledComponentProps {
  title: any; // for formattedmessage, need to figure out exact type
  description: any; // for formattedmessage, need to figure out exact type
  url: string;
  actionKey?: string;
  onClick?: MouseEventHandler;
}

class ActionListItem extends Component<Props> {
  render() {
    const { title, description, url, actionKey, onClick } = this.props;
    const classes = this.props.classes || {};
    const nameTranslated = title;
    const descriptionTranslated = description;
    const actionKeyClass = actionKey? `action-select-${actionKey}`: "";

    return (
      <ListItem onClick={onClick} className={`${classes.item} ${actionKeyClass}`} button component="a" href={url}>
        <Box pr={4} py={3}>
          <VerifiedIcon />
        </Box>
        <ListItemText
          classes={{
            primary: classes.primaryText,
            secondary: classes.secondaryText
          }}
          primary={nameTranslated}
          secondary={descriptionTranslated}
        />
      </ListItem>
    );
  }
}

export default withStyles(styles)(withRouter(ActionListItem));
