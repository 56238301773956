import * as React from 'react';
import {Component} from 'react';
import AuthenticatedPageWithLoading from '../../../shared/containers/AuthenticatedPageWithLoading';
import QuestionnaireApiDetailView, {ValueType} from '../../../API/containers/QuestionnaireAPIDetailFetcher';
import QuestionnaireCardView from '../components/QuestionnaireCardView';
import {withStyles} from '@material-ui/core';
import {Questionnaire} from '../../../models/questionnaire-models/internal/QuestionnaireModels';
import {withRouter} from 'react-router-dom';

interface Props {
  history: any;
  match: any;
  location: any;
}

class QuestionnairePageComponent extends Component<Props, any> {

  render() {
    const { match, history } = this.props;
    return (
      <>
        <AuthenticatedPageWithLoading>
          <QuestionnaireApiDetailView questionnaireId={match.params['id']}>
            {(value: ValueType, authToken: string) => (
                <QuestionnaireCardView
                    authToken={authToken}
                    questionnaire={value.questionnaire}
                    onComplete={(questionnaire: Questionnaire) => {
                      history.push(`/questionnaire/${questionnaire.id}/completed`);
                    }}
                />
            )}
          </QuestionnaireApiDetailView>
        </AuthenticatedPageWithLoading>
      </>
    );
  }
}

export default withStyles({})(withRouter(QuestionnairePageComponent));
