import { Component } from 'react';
import * as React from 'react';
import { QuestionMetaData } from '../../../models/questionnaire-models/internal/QuestionModels';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import style from '../../../styling/Style';
import I18NFromObjectComponent from '../../../shared/components/I18NFromObjectComponent';

const styles = {
  questionTitle: {
    ...style.typography.body_strong,
    color: style.colors.brand.brandGreen,
    marginBottom: 17,
    marginTop: 0
  }
};

export interface Props extends QuestionMetaData, StyledComponentProps {
  children: any;
  name: string;
}

class QuestionView extends Component<Props, any> {
  optionallyRenderImage() {
    const { imageUrl, name } = this.props;
    if (imageUrl) {
      return <img alt={'image for question ' + name} src={imageUrl} />;
    } else return;
  }

  render() {
    const { children, name } = this.props;
    const classes = this.props.classes || {};

    return (
      <div style={{ textAlign: 'center', marginBottom: 51 }}>
        <I18NFromObjectComponent object={name}>{(value: any) => <p className={classes.questionTitle}>{value}</p>}</I18NFromObjectComponent>
        {this.optionallyRenderImage()}
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(QuestionView);
