const ThemeDefaults = (theme: any) => ({

    breakpoints:theme.breakpoints,
    palette: theme.colors,
    typography: theme.typography,
    spacing: theme.spacing,
    colors: theme.colors,

    /*
    * Title Component
    */

    title:{
        title:{},
        big:{},
        normal:{},
        medium:{},
        small:{},
        minion:{}
    },

    /*
    * Heading Component
    */

    headings:{
        heading:{

        },
        title:{
            ...theme.typography.trafalgar
        },
        paragraph:{
            marginTop: theme.spacing[6],
            ...theme.typography.body
        }
    },

    /*
    * Aroma Dot Component
    */

    aromaDots:{
        aromaDot:{
            width: 16,
            height: 16,
            borderRadius: '100%',
            marginRight: theme.spacing[4],
            marginTop: 2
        }
    },

    /*
    * Aroma Cards
    */

    aromaCards:{
        aromaCard:{
            padding: theme.spacing[4],
            /*minHeight: 250*/
        },
        containerLeft:{
            paddingLeft:'35%',
            backgroundPositionX:'12%',
            backgroundPositionY:theme.spacing[4],
            backgroundRepeat:'no-repeat',
            backgroundSize: 'contain'
        },
        containerRight:{
            paddingRight:'35%',
            backgroundPositionX:'88%',
            backgroundPositionY:theme.spacing[4],
            backgroundRepeat:'no-repeat',
            backgroundSize: 'contain'
        },
        containerXS:{
            paddingRight: 0,
            paddingLeft: 0,
            paddingTop: theme.spacing[10],
            backgroundPositionX:'center',
            backgroundPositionY:'top',
            backgroundSize: 'contain'
        },
        titleContainer:{
            display: 'flex',
            alignItems: 'center',
            height: 48,
            marginBottom: theme.spacing[4]
        },
        title:{
            ...theme.typography.greatPrimer_strong
        },
        description:{
            ...theme.typography.body
        }
    },

    /*
    * Cards Theme
    */

    cards:{
        /* Card component styles */
        card:{
            padding: theme.spacing[5],
            boxShadow: theme.shadows.soft,
            backgroundColor: theme.colors.baseColorPalette.white,
            borderRadius: theme.rounded.normal
        }
    },

    /*
    * Icons Theme
    */

    icons:{
        /* Icon component styles */
        icon:{

        },
        small:{
            width: 12,
            height: 12
        },
        medium:{
            width: 24,
            height: 24,
        },
        large:{
            width: 32,
            height: 32
        },
        extraLarge:{
            width: 44,
            height: 44
        },
        extraLarge2:{
            width: 52,
            height: 52
        }
    },

    /*
    * NumberSpinner
     */

    numberSpinner:{
        container:{
            display:'flex',
            alignItems: 'center'
        },
        input:{
            ...theme.typography.pica,
            textAlign: 'center',
            backgroundColor: 'transparent',
            borderWidth: 0,
            outline: 0
        }
    },

    /*
    * Toggle
     */

    toggle:{
        container:{
            display:'flex',
            alignItems: 'center'
        },
        title:{
            marginLeft: theme.spacing[4],
            ...theme.typography.body
        },
        titleDisabled:{

        },
        toggle:{
            borderRadius: 13.5,
            width: 36,
            height: 20,
            position: 'relative',
            transition: ['background-color'],
            transitionDuration: 300,
        },

        handle:{
            position: 'absolute',
            borderRadius: '50%',
            width: 16,
            height: 16,
            marginTop: 2,
            marginLeft: 2,
            transition: ['transform'],
            transitionDuration: 300,
            transform: 'translateX(0)'
        },

        handleOn:{
            transform: 'translateX(100%)'
        },

        handleOff:{},
        handleDisabled:{},
        disabled:{},
        on:{},
        off:{}
    },

    /*
    * Buttons Theme
    */
    buttons:{
        /* Button component styles */
        button:{
            /* Spacing */
            padding: theme.spacing[3],

            /* Rounded borders */
            borderRadius: 12,

            /* Border style */
            borderWidth: 2,
            borderStyle: 'solid',

            /* Display options */
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            outline: 0,

            /* Typography */
            ...theme.typography.button,
        },
        label:{
            width: '100%'
        },
        primary:{
            normal:{},
            normalLabel:{},
            normalIcon:{},

            hover:{},
            hoverLabel:{},
            hoverIcon:{},

            active:{},
            activeLabel:{},
            activeIcon:{},

            disabled:{},
            disabledLabel:{},
            disabledIcon:{}
        },
        secondary:{
            normal:{},
            normalLabel:{},
            normalIcon:{},

            hover:{},
            hoverLabel:{},
            hoverIcon:{},

            active:{},
            activeLabel:{},
            activeIcon:{},

            disabled:{},
            disabledLabel:{},
            disabledIcon:{}
        },
        text:{
            normal:{},
            normalLabel:{},
            normalIcon:{},

            hover:{},
            hoverLabel:{},
            hoverIcon:{},

            active:{},
            activeLabel:{},
            activeIcon:{},

            disabled:{},
            disabledLabel:{},
            disabledIcon:{}
        },
        circle:{
            normal:{
                backgroundColor:'transparent',
                borderRadius:'50%',
                padding:4,
                borderWidth: 0,
            },
            normalLabel:{
                display:'none'
            },
            normalIconLeft:{
                paddingLeft:0,
                paddingRight:0
            },
            normalIconRight:{
                paddingLeft:0,
                paddingRight:0
            },

            hover:{},
            hoverLabel:{},
            hoverIconLeft:{},
            hoverIconRight:{},

            active:{},
            activeLabel:{},
            activeIconLeft:{},
            activeIconRight:{},

            disabled:{},
            disabledLabel:{},
            disabledIconLeft:{},
            disabledIconRight:{}
        }
    },

    /*
    * Divider
     */

    divider:{
        divider:{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid'
        }
    }

});

export default ThemeDefaults;
