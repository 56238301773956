import { SET_LANGUAGE } from './LanguageActions';
import { Language } from '../../Languages';

export function setLanguage(language: Language, languageChanged: boolean) {
  return {
    type: SET_LANGUAGE,
    language: language,
    languageChanged: languageChanged
  };
}
