import React, { Component } from 'react';
import { withStyles, ButtonBase, Typography } from '@material-ui/core';
import style from './Style';
import clsx from 'clsx';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import { theme } from '../styling/DefaultTheme';

interface ButtonProps {
  [key: string]: object;
}

const button: any = {
  padding: '12px 48px',
  backgroundColor: style.colors.grayscale[2],
  color: style.colors.baseColorPalette.white,
  border: '2px solid transparent',
  borderRadius: 12,
  '&:hover': {
    backgroundColor: style.colors.brand.brandGreenLight,
    border: '2px solid' + style.colors.brand.brandGreen
  },
  '&:active': {
    backgroundColor: style.colors.brand.brandGreenLight,
    border: '2px solid' + style.colors.brand.brandGreen
  }
};

const buttonLink: any = {
  padding: '12px 48px',
  backgroundColor: 'transparent',
  color: style.colors.grayscale[2],
  border: '2px solid transparent',
  borderRadius: 12,
  boxSizing: 'border-box',
  '&:hover': {
    backgroundColor: style.colors.grayscale[7],
  },
  '&:active': {
    backgroundColor: style.colors.grayscale[7],
  }
};

const size: ButtonProps = {
  sm: {
    padding: '1px 8px',
    width: 80,
    borderRadius: 4
  },
  lg: {
    width: '100%'
  }
};

const inactive: any = {
  backgroundColor: style.colors.grayscale[4],
  color: style.colors.baseColorPalette.white
};

const darkBackground: any = {
  backgroundColor: style.colors.baseColorPalette.white,
  color: style.colors.grayscale[1]
};

const classes = {
  button: button,
  buttonLink: buttonLink,
  size: (props: Props) => props.size && size[props.size],
  inactive: (props: Props) => (props.inactive||props.disabled) && inactive,
  darkBackground: (props: Props) => props.darkBackground && darkBackground
};

interface Props extends ButtonBaseProps {
  children?: any;
  classes?: any;
  size?: string;
  inactive?: boolean;
  href?: string;
  rounded?: string;
  darkBackground?: boolean;
  variant?: 'default' | 'link';
}

class StyledButton extends Component<Props, any, any> {
  render() {
    const { children, classes, variant, ...props } = this.props;
    const className = clsx(classes.variation, classes.size, classes.inactive, (variant==='link'?classes.buttonLink:classes.button), classes.darkBackground);

    return (
      <ButtonBase className={className} {...props}>
        <Typography style={theme.typography.button}>{children}</Typography>
      </ButtonBase>
    );
  }
}
// @ts-ignore
export default withStyles(classes)(StyledButton);
