import { ForgotPasswordPageTranslations } from '../interfaces/IForgotPasswordPageTranslations';

export const forgotpasswordpage_translations_en: ForgotPasswordPageTranslations = {
  forgot_password_title: 'Forgot Password?',
  forgot_password_description: 'It’s okay! Just enter your email adress and we will send you a reset link.',
  send_button: 'Send',
  back_to_login: 'Back',
  resend_button: 'Resend',
  resend_password_title: 'Please check your email',
  we_sent_an_email_to: 'We sent an email to',
  check_email_message: 'Check your email and click on the confirmation link to continue.'
};
