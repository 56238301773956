import { Component } from 'react';
import * as React from 'react';
import { ButtonBase, withStyles, InputAdornment } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { FieldProps } from 'formik';
import style from './Style';
import StyledIcon from "./StyledIcon";
import {EyeIcon} from "./icons/Eye";
import {EyeOffIcon} from "./icons/EyeOff";

const passwordMaskIconSet = [
  {
    name: 'eye',
    icon: <EyeIcon />
  }, {
    name: 'eyeoff',
    icon: <EyeOffIcon />
  }
]

const styles = {
  root: {
    '& .Mui-error': {
      ...style.typography.bravier_strong,
      textAlign: 'center',

      '&.MuiFormLabel-root': {
        color: style.colors.grayscale[1]
      },
      '& input': {
        background: style.colors.system.dangerLight,
        border: '1px solid transparent'
      }
    },
    '& .Mui-focused': {
      '&.MuiFormLabel-root': {
        color: style.colors.grayscale[1]
      }
    },
    '& .MuiInputLabel-shrink': {
      transform: 'none'
    },
    '& label': {
      color: style.colors.grayscale[1],
      ...style.typography.minion_strong
    }
  },
  textField: {
    position: 'relative' as 'relative',
    '& input': {
      ...style.typography.bravier,
      padding: '16px',
      marginTop: '8px',
      borderRadius: '12px',
      background: style.colors.baseColorPalette.white,
      border: '1px solid' + style.colors.grayscale[5],
      '&:focus': {
        border: '1px solid' + style.colors.grayscale[1]
      }
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute' as 'absolute',
      right: 0,
      marginRight: 12,
      marginTop: 8
    }
  },
  visibilityIcon: {
    width: 24,
    height: 24
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  textFieldWithPasswordToggle: {
    '& input': {
      padding: '16px 40px 16px 16px',
    }
  },
  passwordToggleButton: {
    width: '40px',
    height: '40px'
  }
};

interface Props extends FieldProps<any> {
  children?: any;
  fullWidth?: boolean;
  classes?: any;
  passwordViewToggle?: boolean;
  [key: string]: any;
}

interface State {
  passwordIsMasked: boolean;
}

class StyledTextField extends Component<Props, State, any> {

  constructor(props: Props) {
    super(props);

    this.state = {
      passwordIsMasked: true,
    };
  }

  togglePasswordMask(){
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  }

  render() {
    let { children, classes, passwordToggle, ...props } = this.props;
    const {passwordIsMasked} = this.state;
    let inputType = this.props.type || 'text';

    if(passwordToggle && !this.state.passwordIsMasked) inputType = 'text';

    return (
      <React.Fragment>
        {
          // @ts-ignore
          <TextField
            className={classes.root}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              className: classes.textField + ( passwordToggle ? ' ' + classes.textFieldWithPasswordToggle : '' ),
              disableUnderline: true,
              endAdornment: passwordToggle && (
                  <InputAdornment position="end" style={{position:'absolute', right:0}}>
                    <ButtonBase className={classes.passwordToggleButton} onClick={()=>{this.togglePasswordMask()}}>
                      <StyledIcon currentIconName={passwordIsMasked?'eye':'eyeoff'} size={16} iconSet={passwordMaskIconSet} />
                    </ButtonBase>
                  </InputAdornment>
              )
            }}
              /*classes={classes}*/
            {...{...props, type: inputType}}
          >
            {children}
          </TextField>
        }
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(StyledTextField);
