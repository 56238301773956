import { Component } from 'react';
import * as React from 'react';
import { connect } from '../../../RefetchConfig';
import { connect as reduxConnect } from 'react-redux';
import { AuthenticatedProps } from '../../../shared/containers/AuthenticatedPageWithLoading';
import { Grid, Paper, StyledComponentProps, Typography, withStyles } from '@material-ui/core';
import StyledButton from '../../../styling/StyledButton';
import { Dispatch } from 'redux';
import { FlavourIDUser } from '../../../models/user-models/UserModels';
import { setFlavourIdUser } from '../../../redux/authentication/LoginActionCreators';
import { theme } from '../../../styling/DefaultTheme';
import { FormattedMessage } from 'react-intl';
import style from '../../../styling/Style';

const styles = {
  consentDialog: {
    width: '100vw',
    maxWidth: 600,
    minHeight: 200,
    height: '100%',
    boxShadow: 'none',
    opacity: 1,
    zIndex: 11
    // position: 'absolute' as 'absolute',
    // left: 0,
    // top: '50%'
  },
  buttonContainer: {
    padding: 8,
    maxWidth: 300
  },
  paperContainer: {
    [theme.breakpoints.up('xs')]: {
      padding: 8
    },
    [theme.breakpoints.up('md')]: {
      padding: 32
    },
    [theme.breakpoints.up('lg')]: {
      padding: 64
    },
    borderRadius: 30
  },
  gridContainer: {
    marginTop: '-64px'
  },
  consentTitle: {
    ...style.typography.minion
  }
};

interface Props extends AuthenticatedProps, StyledComponentProps {
  postConsents: (consent: string, given: boolean) => void;
  consent: string;
  resultConsents: Array<string>;
  updateConsents: (flavourIdUser: FlavourIDUser) => { type: string; flavourIdUser: FlavourIDUser };
}

interface State {
  open: boolean;
}

class ConsentComponent extends Component<Props, State, any> {
  constructor(props: Props) {
    super(props);
    this.state = { open: true };
  }

  accepted() {
    const { postConsents, consent } = this.props;
    postConsents(consent, true);
  }

  refused() {
    const { postConsents, consent } = this.props;
    postConsents(consent, false);
  }

  render() {
    const { consent } = this.props;
    const classes = this.props.classes || {};

    let classesDialog: { [key: string]: any } = { paper: classes.consentDialog };
    if (this.state.open) {
      classesDialog['root'] = classes.root;
    }

    return (
      <Grid container justify="center" alignContent="flex-start" className={`${classes.gridContainer} fullwidth fullHeight`}>
        <Grid item className={`${classes.consentDialog}`}>
          <Paper className={classes.paperContainer}>
            <Typography className={classes.consentTitle} >
              {consent}
            </Typography>
            <p className="normal-text">
              <FormattedMessage id={'questionnaire_consent_info'} />
            </p>
            <Grid container justify="center">
              <Grid item xs={6} className={`${classes.buttonContainer}`}>
                <StyledButton size="lg" onClick={() => this.accepted()}>
                  <FormattedMessage id={'questionnaire_consent_accept'} />
                </StyledButton>
              </Grid>
              <Grid item xs={6} className={`${classes.buttonContainer}`}>
                <StyledButton size="lg" onClick={() => this.refused()}>
                  <FormattedMessage id={'questionnaire_consent_refuse'} />
                </StyledButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

// @ts-ignore
const refetchConnected = connect((props: Props) => ({
  postConsents: (consent: string, given: boolean) => {
    return {
      resultConsents: {
        url: `/stop/user/consent`,
        authenticationToken: props.authToken,
        method: 'PATCH',
        body: JSON.stringify({
          consent: {
            [consent]: given
          }
        }),
        then: (subject: any) => {
          props.updateConsents(subject);
        }
      }
    };
  }
}))(withStyles(styles)(ConsentComponent));

export default reduxConnect(
  () => {},
  (dispatch: Dispatch) => ({
    updateConsents: (flavourIdUser: FlavourIDUser) => dispatch(setFlavourIdUser(flavourIdUser))
  })
)(refetchConnected);
