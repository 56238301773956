import { Component } from 'react';
import * as React from 'react';
import { withStyles, Typography, StyledComponentProps } from '@material-ui/core';
import style from './Style';

const styles = {
  emphasis: {
    ...style.typography.body_highlight
  },
  normal: {
    ...style.typography.body
  },
  messageMedium: {
    ...style.typography.pica_strong
  },
  messageBig: {
    ...style.typography.greatPrimer_strong
  },
  small: {
    ...style.typography.micro
  },
  minion: {
    ...style.typography.bravier
  },
  listItem: {
    ...style.typography.bravier
  },
  navBarItem: {
    ...style.typography.bravier
  },
  navBarItemSelected: {
    ...style.typography.bravier_strong
  },
  default: {
    ...style.typography.body
  },
  defaultStrong: {
    ...style.typography.body_strong
  }
};

interface Props extends StyledComponentProps {
  children?: any;
  variation?: 'emphasis' | 'normal' | 'messageMedium' | 'messageBig' | 'small' | 'minion' | 'listItem' | 'navBarItem' | 'navBarItemSelected' | 'defaultStrong';
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'srOnly' | undefined;
  color?: any;
  style?: any;
}

class StyledParagraph extends Component<Props, any> {
  render() {
    let { children, element, style, color, ...props } = this.props;
    const classes = this.props.classes || {};

    return (
      <React.Fragment>
        {
          <Typography
            variant={element}
            className={props.variation ? classes[props.variation] : classes.default}
            style={{color: color, ...style}}
          >
            {children}
          </Typography>
        }
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(StyledParagraph);
