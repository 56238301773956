import { Component } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../redux/Reducers';
import { withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import style from '../../../styling/Style';
import { FlavourIdData, FlavourIDUser, SecondaryProfile } from '../../../models/user-models/UserModels';
import { flavourColors } from '../../../shared/components/visualisations/flavourColors';
import { capitalize } from '../../../utils/String';

import MediumBlock from '../../../styling/blocks/Medium';

interface Props extends RouteComponentProps {
  classes: any;
  flavourIdData?: FlavourIdData;
  flavourIdUser?: FlavourIDUser;
  secondaryUser?: SecondaryProfile;
}

const styles = {
  flavorText: {
    ...style.typography.body_strong
  }
};

const AromaName: React.FC<{className: string, name: string}> = ({className, name}) => {
  return (
    <span className={className} style={{color: flavourColors[name]}}>
      <FormattedMessage id={name || 'flavorid_unknown_aroma'}/>
    </span>
  );
};

class FlavourIdDescription extends Component<Props, any, any> {
  render() {
    const classes = this.props.classes || {};
    const { flavourIdData } = this.props;
    // todo: dedupe with logic in flavorid viz component
    const categories = flavourIdData?.visualisation?.categories ?? [];
    const topDrivers = categories.find((x: any) => x.category === 'top_drivers')?.aromas;
    const topTypesLower = topDrivers ? Object.entries(topDrivers)
      .sort((a: [string, number], b: [string, number]) => a[1] - b[1])
      .map(x => x[0]): null;
    const topTypes = topTypesLower?.map(capitalize);

    const { flavourIdUser, secondaryUser } = this.props;
    const userName = secondaryUser? secondaryUser.name: flavourIdUser?.name ?? "Unknown user";

    return (
      <MediumBlock title={<FormattedMessage id="flavorid_welcome" values={{name: userName}}/>} titleColor={style.colors.grayscale[1]}>
          <p>
            <FormattedMessage id="flavorid_introduction"/><br/>
            {
              topTypes?.length === 2 && (
                <>
                <FormattedMessage id="flavorid_introduction_types" values={{
                  first_type: <AromaName className={classes.flavorText} name={topTypes[0]}/>,
                  second_type: <AromaName className={classes.flavorText} name={topTypes[1]}/>
                }}/>
                </>
              )
            }
          </p>
          {
          <a style={{color: style.colors.system.link}} href="/aromas">
            <FormattedMessage id="flavorid_discover_type"/>
          </a>
          }
      </MediumBlock>
    );
  }
}

export default connect(
  (state: State) => ({
    flavourIdUser: state.authentication.flavourIdUser,
    secondaryUser: state.authentication.currentSecondaryProfile
  }),
  () => ({})
)(withStyles(styles)(withRouter(FlavourIdDescription)));
