import { MultipleChoiceQuestion, NumberQuestion, RatingQuestion, TextQuestion, YesNoQuestion } from '../external/QuestionTypeModels';
import { MultipleChoiceQuestionViewProps, NumberQuestionViewProps, RatingQuestionViewProps, TextQuestionViewProps, YesNoQuestionViewProps } from '../internal/QuestionTypeModels';

export const transformQuestionTypeProps: (inputData: any, questionType: string) => any = (inputData, questionType) => {
  return typeMapping[questionType](inputData);
};

const transformRatingQuestionTypeProps: (inputData: RatingQuestion) => RatingQuestionViewProps = (inputData) => {
  return {
    max: inputData.max,
    min: inputData.min,
    iconSet: inputData.iconSet
  };
};

const transformTextQuestionTypeProps: (inputData: TextQuestion) => TextQuestionViewProps = (inputData) => {
  return {
    maxLength: inputData.maxLength
  };
};

const transformYesNoQuestionTypeProps: (inputData: YesNoQuestion) => YesNoQuestionViewProps = (inputData) => {
  return {
    inversed: false,
    yesText: inputData.yesText,
    noText: inputData.noText
  };
};

const transformNumberQuestionTypeProps: (inputData: NumberQuestion) => NumberQuestionViewProps = (inputData) => {
  return {
    minimumValue: inputData.min,
    maximumValue: inputData.max,
    allowFractional: inputData.allowFractional
  };
};

const transformMultipleChoiceQuestionTypeProps: (inputData: MultipleChoiceQuestion) => MultipleChoiceQuestionViewProps = (inputData) => {
  return {
    allowMultiple: inputData.allowMultiple,
    values: inputData.choices.map((value) => {
      return {
        value: value.id,
        label: value.label,
        description: value.description
      };
    })
  };
};

interface TypeMapping {
  [key: string]: (data: any) => any;
}

const typeMapping: TypeMapping = {
  rating: transformRatingQuestionTypeProps,
  text: transformTextQuestionTypeProps,
  yesNo: transformYesNoQuestionTypeProps,
  number: transformNumberQuestionTypeProps,
  multipleChoice: transformMultipleChoiceQuestionTypeProps
};
