import { Answer as InternalAnswer, isQuestionGroup, Question, QuestionGroup, QuestionOrGroup } from '../internal/QuestionModels';
import { AnswerOrGroup as ExternalAnswerOrGroup, AnswerGroup as ExternalAnswerGroup, Answer as ExternalAnswer } from '../external/QuestionModels';

export const answerToExternalAnswer: (answer: InternalAnswer) => ExternalAnswer = (answer) => {
  return {
    qId: String(answer.id),
    ...answer.value
  };
};

export const questionGroupToExternalAnswerGroup: (questionGroup: QuestionGroup) => ExternalAnswerGroup = (questionGroup) => {
  const answers = collectAnswersToExternalAnswer(questionGroup.questions);
  return {
    qgId: questionGroup.data.id,
    answers: answers,
    intentionallyBlank: questionGroup.answers ? questionGroup.answers.intentionallyBlank : undefined
  };
};

export const collectAnswersToExternalAnswer: (questions: QuestionOrGroup[]) => Array<ExternalAnswerOrGroup> = (questions) => {
  const answers: Array<ExternalAnswerOrGroup> = [];
  questions.forEach((questionOrQuestionGroup) => {
    if (isQuestionGroup(questionOrQuestionGroup)) {
      answers.push({
        group: questionGroupToExternalAnswerGroup(questionOrQuestionGroup)
      });
    } else {
      const question = questionOrQuestionGroup.questionOrGroup as Question;
      if (question.answer && question.answer.value) {
        answers.push({
          answer: answerToExternalAnswer(question.answer)
        });
      }
    }
  });
  return answers;
};
