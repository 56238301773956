import { ProfilePageTranslations } from '../interfaces/IProfilePageTranslations';

export const profilepage_translations_nl: ProfilePageTranslations = {
  healthscore_header: 'Gezondheidsscore',
  healthscore_insufficient_data: 'Onvoldoende gegevens',
  healthscore_unknown: 'Gezondheidsscore is onbekend',
  healthscore_not_found: 'Geen gezondheidsscore gevonden',
  flavoridtest_header: 'FlavorID status',
  flavoridtest_description: 'Verbeter uw FlavorID door de vragenlijsten in te vullen',
  flavoridtest_review: 'Bekijk',
  flavoridtest_complete: 'Vervolledig',
  profilepage_taken_questionnaires: 'Voltooide acties',
  profilepage_new_questionnaire: 'Beschikbare acties',
  profilepage_active_integrations: 'Actieve integraties',
  profilepage_logout: 'Uitloggen van FlavorID',
  profilepage_questionnaire_not_found: 'Fout: Kon de vragenlijst niet vinden!',
  integration_revoke_access: '[T] Toegang intrekken',
  flavourid_not_available: 'FlavorID niet beschikbaar. Vul de vragenlijst in om meer te weten te komen',
  flavourid_updating: 'FlavorID niet beschikbaar. Wacht even tot de berekening voltooid is',
  flavorid_graph_title: 'Uw FlavorID profiel',
  check_out_happy_plate_title: "Happy plate bekijken",
  check_out_happy_plate_description: "Happy Plate helpt ouders met moeilijke eter om lekkere maaltijden met meer groenten te bereiden",
  flavorid_welcome: "Welkom, {name}",
  flavorid_introduction: "Welkom bij FlavorID, uw universeel smaakpaspoort, waarmee je nieuwe gerechten kan ontdekken. ",
  flavorid_introduction_types: "FlavorID herkent 13 aroma types, volgens uw antwoorden verkies je {first_type} & {second_type} meer dan de andere.",
  flavorid_discover_type: "Ontdek alle 13 aroma types en hoe je ze kan herkennen.",
  flavorid_unknown_aroma: "Onbekend"
};
