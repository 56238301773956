import IFlavoursTranslation from '../interfaces/IFlavoursTranslation';

export const FlavoursTranslation_EN: IFlavoursTranslation = {
  Spicy: 'Spicy',
  Caramel: 'Caramel',
  Fruity: 'Fruity',
  Cheese: 'Cheese',
  Chemical: 'Chemical',
  Herbal: 'Herbal',
  Green: 'Green',
  Vegetable: 'Vegetable',
  Citrus: 'Citrus',
  Floral: 'Floral',
  Animal: 'Animal',
  Roasted: 'Roasted',
  Woody: 'Woody',
  Nutty: 'Nutty'
};
