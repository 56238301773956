import style from './Style';

const bourdainTheme = {
  '@global': {
    '.large-text': style.typography.greatPrimer,
    '.normal-text': style.typography.body,
    '.small-text': style.typography.micro,
    '.bravier-text': style.typography.bravier,
    '.minion-text': style.typography.minion,
    '.semibold': {
      fontWeight: 600
    },
    '.fullwidth': {
      width: '100%'
    },
    '.fullHeight': {
      height: '100%'
    },
    '.text-success': {
      color: style.colors.system.success
    },
    '.text-error': {
      color: style.colors.system.danger,
      ...style.typography.bravier_strong
    }
  }
};

export default bourdainTheme;
