import { TextField } from '@material-ui/core';
import * as React from 'react';
import { NumberQuestionViewProps } from '../../../../../models/questiontype-models/internal/QuestionTypeModels';
import QuestionTypeContainer from '../../QuestionTypeContainer';
import { QuestionProps } from '../../../../../models/questionnaire-models/internal/QuestionModels';
import I18NFromObjectComponent from '../../../../../shared/components/I18NFromObjectComponent';

interface Props extends QuestionProps, NumberQuestionViewProps {}

export default class NumberQuestionView extends QuestionTypeContainer<Props, any> {
  render() {
    const { hint, value, minimumValue, maximumValue } = this.props;
    return (
      <TextField
        label={<I18NFromObjectComponent object={hint}>{(hint: string) => <>{hint}</>}</I18NFromObjectComponent>}
        value={this.getValue(value, this.props)}
        onChange={(event) => this.handleChange(event.target.value, this.props)}
        inputProps={{ min: minimumValue, max: maximumValue }}
        type="number"
        margin="normal"
      />
    );
  }
}
