import { Questionnaire, QuestionnaireInformation } from '../internal/QuestionnaireModels';
import { Dependencies, QuestionGroup, QuestionOrGroup as InternalQuestionOrGroup } from '../internal/QuestionModels';
import { QuestionnaireGETModel } from '../external/QuestionnairesGetModels';
import { Answer, DependencyData, getQuestionValue, isQuestionGroup, QuestionnaireDetail, QuestionOrGroup as ExternalQuestionOrGroup } from '../external/QuestionModels';
import { transformQuestionTypeProps } from '../../questiontype-models/transformers/QuestionTypeTransformer';

export const questionnaireGetTransformer: (model:QuestionnaireGETModel) => Array<QuestionnaireInformation> = (model) => {
    return (model.questionnaires || []).map(questionnaire => {
        return {
            id:questionnaire.metadata.id,
            progress: questionnaire.lastAnswers ? questionnaire.lastAnswers.metadata.progress * 100 : 0,
            description:questionnaire.metadata.description,
            title:questionnaire.metadata.title,
            questionStatuses: questionnaire.lastAnswers && questionnaire.lastAnswers.summary ?
                questionnaire.lastAnswers.summary.groups.map(group => group.status || "IDLE") :
                ['IDLE'] // TODO remove this, as it is the backend that is incorrect
        }
    })
};

export const questionnaireDetailGetTransformer: (model: QuestionnaireDetail) => Questionnaire = (model) => {
  return {
    name: model.metadata.title,
    questions: model.questionGroups
      .map((value, index) => {
        let answers: Answer[];
        if (model.lastAnswers && model.lastAnswers.answerGroups) {
          answers = (model.lastAnswers.answerGroups.find((x) => x.qgId === value.id) || { answers: [] }).answers;
        } else {
          answers = [];
        }
        return questionGroupTransformer({ group: value }, answers);
      })
      .map((value) => value.questionOrGroup as QuestionGroup),
    imageUrl: undefined,
    description: model.metadata.description,
    id: model.metadata.id,
    completed: model.lastAnswers && model.lastAnswers.metadata ? model.lastAnswers.metadata.progress : 0
  };
};

const getAnswers = (answers: Array<Answer>, value: ExternalQuestionOrGroup) => {
  return answers
    ? answers.find((answer) => {
        if (answer) {
          if (answer.answer && answer.answer.qId && value.question) {
            return answer.answer.qId === value.question.id;
          }
        }
        return false;
      })
    : undefined;
};

const questionGroupTransformer: (model: ExternalQuestionOrGroup, answers: Array<Answer>) => InternalQuestionOrGroup = (model, answers) => {
  if (isQuestionGroup(model)) {
    if (!model.group) throw Error('Group cannot be undefined');
    return {
      questionOrGroup: {
        questions: model.group.questions ? model.group.questions.map((value) => questionGroupTransformer(value, answers)) : [],
        data: {
          id: model.group.id,
          props: {
            name: model.group.description
          },
          dependencies: model.group.dependsOn ? [dependencyTransformer(model.group.dependsOn)] : [],
          needsConsent: model.group.needsConsent
        }
      }
    };
  } else {
    if (!model.question) throw Error('Question cannot be undefined');
    const { questionType, questionValue } = getQuestionValue(model.question);
    const questionAnswer = getAnswers(answers, model);
    const props = {
      ...transformQuestionTypeProps(questionValue, questionType),
      name: model.question.description
    };
    return {
      questionOrGroup: {
        type: questionType,
        data: {
          id: model.question.id,
          props: { dontKnowInstruction: model.question.dontKnowInstruction, ...props}
        },
        answer: {
          value: questionAnswer ? questionAnswer.answer : undefined,
          id: model.question.id
        },
        metaData: undefined
      }
    };
  }
};

const dependencyTransformer: (dependency: DependencyData) => Dependencies = (dependency) => {
  return {
    dependsOn: dependency.qId,
    dependsOnValue: dependency.number || dependency.string,
    dependsOnOperator: dependency.op
  };
};
