import React from 'react';

export interface UseStyleProps {
  classes: any;
  [key: string]: any;
}

export const withUseStyles = (Component: any, styles: any) => {
  return (props: any) => {
    const classes = styles();
    return <Component classes={classes} {...props} />;
  };
};
