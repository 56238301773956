import { ResetPasswordPageTranslations } from '../interfaces/IResetPasswordPageTranslations';

export const resetpasswordpage_translations_nl: ResetPasswordPageTranslations = {
  reset_password_title: 'Wachtwoord opnieuw installen',
  reset_password_title_success: 'Wachtwoord wijzigen voltooid',
  reset_password_title_failure: 'Watchwoord wijzigen mislukt',
  reset_password_form_submit_button: 'Instellen',
  reset_password_callback_form_description_failure: "Nieuw wachtwoord instellen mislukt",
  reset_password_callback_form_description_success: "Nieuw wachtwoord succesvol ingesteld",
  reset_password_button_failure: "Opnieuw proberen",
  reset_password_button_success: "Terug",
};
