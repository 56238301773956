import { Component } from 'react';
import * as React from 'react';
import ProfileAvatarComponent from './ProfileAvatarComponent';
import { MenuItem, StyledComponentProps, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoginButton from '../../../features/authentication/containers/LoginButton';
import SwitchAccountDialog from '../structural/SwitchAccountDialog';
import { FormattedMessage } from 'react-intl';

interface Props extends RouteComponentProps, StyledComponentProps {}

interface MyState {
  switchAccountDialogOpen: boolean;
}

const styles = {
  loginButtonRoot: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  }
};

class StopProfileAvatarComponent extends Component<Props, MyState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      switchAccountDialogOpen: false
    };
  }

  switchAccount() {
    this.setState({ switchAccountDialogOpen: true });
  }

  closeSwitchAccount() {
    this.setState({ switchAccountDialogOpen: false });
  }

  render() {
    const classes = this.props.classes || {};
    return (
      <ProfileAvatarComponent>
        {(handleClose: any) => (
          <div>
            <SwitchAccountDialog onClose={() => this.closeSwitchAccount()} open={this.state.switchAccountDialogOpen} />
            <MenuItem
              onClick={() => {
                if (!this.state.switchAccountDialogOpen) {
                  this.switchAccount();
                  handleClose();
                }
              }}
            >
              <FormattedMessage id={'toolbar_avatar_popup_switch_or_add_user'} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <LoginButton
                text={'Log out'}
                buttonProps={{
                  style: { width: '100%' },
                  variant: 'text',
                  disableRipple: true,
                  classes: {
                    root: classes.loginButtonRoot
                  }
                }}
              />
            </MenuItem>
          </div>
        )}
      </ProfileAvatarComponent>
    );
  }
}

export default withStyles(styles)(withRouter(StopProfileAvatarComponent));
