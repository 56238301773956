import * as React from 'react';
import { Component } from 'react';
import style from '../../../styling/Style';
import { Box } from '@material-ui/core';
import StyledParagraph from '../../../styling/StyledParagraph';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { theme } from '../../../styling/DefaultTheme';
import { FontFamilyProperty, FontStyleProperty, FontWeightProperty, LineHeightProperty } from 'csstype';

const styles = {
  image: (props: PropsV2) => ({
    // MH: disabled to allow the image to center itself
    // width: props.width || '100%',
    //maxWidth: '100%',

  }),
  textStyle: {
    fontFamily: 'Open Sans' as FontFamilyProperty,
    fontStyle: 'normal' as FontStyleProperty,
    fontWeight: 'normal' as FontWeightProperty,
    fontSize: 24,
    lineHeight: '140%' as LineHeightProperty<any>,
    color: theme.palette.grey[200]
  }
};

interface PropsV2 extends StyledComponentProps {
  width?: number | string;
  value: number;
  description: string;
}

export class HealthGraphComponentV2 extends Component<PropsV2> {
  polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  createArc(x: number, y: number, radius: number, spread: number, startAngle: number, endAngle: number) {
    const innerStart = this.polarToCartesian(x, y, radius, endAngle);
    const innerEnd = this.polarToCartesian(x, y, radius, startAngle);
    const outerStart = this.polarToCartesian(x, y, radius + spread, endAngle);
    const outerEnd = this.polarToCartesian(x, y, radius + spread, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return [
      'M',
      outerStart.x,
      outerStart.y,
      'A',
      radius + spread,
      radius + spread,
      0,
      largeArcFlag,
      0,
      outerEnd.x,
      outerEnd.y,
      'L',
      innerEnd.x,
      innerEnd.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      1,
      innerStart.x,
      innerStart.y,
      'L',
      outerStart.x,
      outerStart.y,
      'Z'
    ].join(' ');
  }

  render() {
    const { value, description } = this.props;
    // v2 has 4 categories, so value ranges from 0-3
    const classes = this.props.classes || {};
    // todo: use named colors
    const segmentsEnabled = [
      "#D2412D",
      "#DD5C23",
      "#E3831A",
      value > 0 ? "#EAA911": "#CED5D9",
      value > 0 ? "#ECC315": "#CED5D9",
      value > 1 ? "#EBD524": "#CED5D9",
      value > 1 ? "#EAE533": "#CED5D9",
      value > 1 ? "#E2EF3E": "#CED5D9",
      value > 1 ? "#BFE040": "#CED5D9",
      value > 1 ? "#9CD041": "#CED5D9",
      value > 1 ? "#7AC242": "#CED5D9",
      value > 2 ? "#5FA75B": "#CED5D9",
      value > 2 ? "#5FA75B": "#CED5D9",
      value > 2 ? "#5FA75B": "#CED5D9",
    ]; 
    return (
      <>
	<div className={classes.image}>
	  <svg width="162" height="126" viewBox="0 0 162 126" fill="none" xmlns="http://www.w3.org/2000/svg">
	    <text className={classes.textStyle} fill="#505D64" textAnchor="middle" x="50%" y="80%">
	    </text>
	      <path fillRule="evenodd" clipRule="evenodd" d="M8.10363 106.249L14.7087 103.931C16.6944 109.589 19.3661 114.881 22.6128 119.724L16.7984 123.621C13.2273 118.294 10.2879 112.473 8.10363 106.249Z" fill={segmentsEnabled[0]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M3.73071 82.3863C3.81284 85.594 4.09491 88.8322 4.58708 92.087C5.07925 95.3419 5.76719 98.5187 6.63712 101.607L13.375 99.7094C12.5836 96.8999 11.957 94.0073 11.5084 91.0404C11.0598 88.0736 10.8031 85.1251 10.7284 82.2072L3.73071 82.3863Z" fill={segmentsEnabled[1]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M6.92899 58.3521L13.6359 60.3566C11.9663 65.9429 10.9783 71.7874 10.7543 77.7797L3.75921 77.5183C4.00559 70.9266 5.09252 64.4967 6.92899 58.3521Z" fill={segmentsEnabled[2]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M17.4237 36.4905L23.1848 40.4667C19.8364 45.3181 17.0913 50.5884 15.0369 56.1565L8.46962 53.7334C10.7284 47.6115 13.7454 41.82 17.4237 36.4905Z" fill={segmentsEnabled[3]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M34.1379 18.9197L38.3943 24.4769C33.6825 28.0857 29.4595 32.2664 25.8149 36.8994L20.3132 32.5715C24.3169 27.4819 28.9575 22.8874 34.1379 18.9197Z" fill={segmentsEnabled[4]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M55.4667 7.38759L57.7847 13.9927C52.1265 15.9783 46.8353 18.6501 41.9924 21.8967L38.0945 16.0824C43.4215 12.5113 49.2426 9.57185 55.4667 7.38759Z" fill={segmentsEnabled[5]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M79.3296 3.01468L79.5088 10.0124C76.5908 10.0871 73.6424 10.3437 70.6755 10.7924C67.7086 11.241 64.8161 11.8676 62.0065 12.6589L60.1087 5.92108C63.1972 5.05115 66.374 4.36322 69.6289 3.87105C72.8838 3.37888 76.1219 3.0968 79.3296 3.01468Z" fill={segmentsEnabled[6]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M103.364 6.21296L101.359 12.9198C95.773 11.2502 89.9285 10.2623 83.9362 10.0383L84.1977 3.04317C90.7893 3.28955 97.2192 4.37648 103.364 6.21296Z" fill={segmentsEnabled[7]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M125.225 16.7076L121.249 22.4687C116.398 19.1204 111.128 16.3752 105.559 14.3208L107.983 7.75357C114.104 10.0123 119.896 13.0294 125.225 16.7076Z" fill={segmentsEnabled[8]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M142.796 33.4218L137.239 37.6782C133.63 32.9665 129.45 28.7435 124.817 25.0989L129.144 19.5972C134.234 23.6009 138.829 28.2414 142.796 33.4218Z" fill={segmentsEnabled[9]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M154.328 54.7507L147.723 57.0686C145.738 51.4104 143.066 46.1193 139.819 41.2763L145.634 37.3785C149.205 42.7055 152.144 48.5266 154.328 54.7507Z" fill={segmentsEnabled[10]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M158.701 78.6136L151.704 78.7927C151.629 75.8748 151.372 72.9263 150.924 69.9594C150.475 66.9925 149.848 64.1 149.057 61.2904L155.795 59.3926C156.665 62.4812 157.353 65.658 157.845 68.9129C158.337 72.1677 158.619 75.4059 158.701 78.6136Z" fill={segmentsEnabled[11]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M155.503 102.648L148.796 100.643C150.466 95.057 151.454 89.2125 151.678 83.2201L158.673 83.4816C158.426 90.0732 157.339 96.5031 155.503 102.648Z" fill={segmentsEnabled[12]}/>
	      <path fillRule="evenodd" clipRule="evenodd" d="M145.008 124.509L139.247 120.533C142.596 115.682 145.341 110.412 147.395 104.843L153.962 107.267C151.704 113.388 148.687 119.18 145.008 124.509Z" fill={segmentsEnabled[13]}/>
              {/* heart icon */}
	      <path d="M99.3537 64.6511C94.3166 59.783 86.1029 59.783 81.0658 64.6511C76.0287 59.783 67.815 59.783 62.7778 64.6511C57.7407 69.5193 57.7407 77.4574 62.7778 82.3256L81.0658 100L99.3537 82.3256C104.391 77.4574 104.391 69.5631 99.3537 64.6511Z" fill="#5FA75B"/>
	  </svg>
	</div>
	<Box p={6}>
	  <StyledParagraph variation="minion" color={style.colors.grayscale[2]}>
            {description}
	  </StyledParagraph>
	</Box>
      </>
    );
  }
}

export default withStyles(styles)(HealthGraphComponentV2);
