import { Component } from 'react';
import * as React from 'react';
import { Dialog, StyledComponentProps } from '@material-ui/core';
import { PositionProperty } from 'csstype';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { theme } from '../../../styling/DefaultTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import { FlavourIDUser, SecondaryProfile } from '../../../models/user-models/UserModels';
import { setFlavourIdUser } from '../../../redux/authentication/LoginActionCreators';
import { State } from '../../../redux/Reducers';
import { connect as ReduxConnect } from 'react-redux';
import { connect } from '../../../RefetchConfig';
import { AuthenticatedProps } from '../../containers/AuthenticatedPageWithLoading';
import DialogTitle from '@material-ui/core/DialogTitle';
import StyledButton from '../../../styling/StyledButton';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import StyledParagraph from '../../../styling/StyledParagraph';

const styles = {
  closeButton: {
    position: 'absolute' as PositionProperty,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary
  },
  dialog: {
    minWidth: '300px',
    minHeight: '200px'
  }
};

interface Props extends StyledComponentProps, AuthenticatedProps {
  open: boolean;
  onClose: () => void;
  flavourIdUser?: FlavourIDUser;
  setFlavourIdUser: (user: FlavourIDUser) => void;
  deleteSecondaryProfile: () => void;
  currentDeletingUser: SecondaryProfile;
}

class RemoveUserDialog extends Component<Props> {
  deleteUser() {
    this.props.deleteSecondaryProfile();
    this.props.onClose();
  }

  render() {
    const { open, onClose } = this.props;
    const classes = this.props.classes || {};

    return (
      <Dialog onClose={onClose} open={open}>
        <div className={`${classes.dialog}`}>
          <DialogTitle>
            <StyledParagraph>
              <FormattedMessage id={'remove_user_dialog_are_you_sure'} />
            </StyledParagraph>
            <IconButton onClick={onClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Grid container justify="center">
            <Grid item>
              <StyledButton onClick={() => this.deleteUser()}>
                <FormattedMessage id={'remove_user_dialog_remove'} />
              </StyledButton>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    );
  }
}

// @ts-ignore
const refetchConnected = connect((props: Props) => ({
  deleteSecondaryProfile: () => {
    return {
      resultConsents: {
        url: `/stop/user/secondary_profile/${props.currentDeletingUser.id}`,
        authenticationToken: props.authToken,
        method: 'DELETE',
        then: (subject: { secondaryProfiles: SecondaryProfile[] }) => {
          const newUser = props.flavourIdUser!!;
          newUser.secondaryProfiles = subject.secondaryProfiles;
          props.setFlavourIdUser(newUser);
        }
      }
    };
  }
}))(withStyles(styles)(RemoveUserDialog));

export default ReduxConnect(
  (state: State) => ({
    flavourIdUser: state.authentication.flavourIdUser
  }),
  (dispatch) => ({
    setFlavourIdUser: (flavourIdUser: FlavourIDUser) => {
      dispatch(setFlavourIdUser(flavourIdUser));
    }
  })
)(refetchConnected);
