import { Component } from 'react';
import * as React from 'react';
import AuthenticationView from '../../containers/AuthenticationView';
import { SIGNUP_START } from '../../AuthenticationViewTypes';

export default class SignUpView extends Component<any, any> {
  render() {
    return <AuthenticationView type={SIGNUP_START} />;
  }
}
