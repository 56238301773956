import { Component } from 'react';

// Interface of AuthenticationViewProps that should be extended by each Props interface
// that is linked to an authentication view.
export interface AuthenticationViewProps {
  // Every view has an action that needs to be propagated to its master, which collects the data
  onAction: (propagatedProps?: any) => void;
  propagatedProps?: any;
}

// Class that requires a NEXT_VIEW:string static variable.
// This variable is used by the AuthenticationView, which renders the AuthenticationViewType component,
// to know which view comes after the confirmation of the current AuthenticationViewType component.
// Using this, one can make an entire flow of views.
export class AuthenticationViewType<Props, State> extends Component<Props, State> {
  static NEXT_VIEW: string;
}
