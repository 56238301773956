import StyleVariablesInterface, { MarginStyleInterface } from './StyleVariablesInterface';
import {colors, typography} from '@design-system/themes/aroma/styles'

const margins: MarginStyleInterface = {
  small: 24,
  medium: 90,
  large: 162
};

const spacing: number[] = [0, 4, 8, 12, 16, 24, 32, 48, 64, 80, 128, 256];

const style: StyleVariablesInterface = {
  colors: colors,
  typography: typography,
  flavourIdLogo: '/flavorId_logo.svg',
  homepageHeaderImageUrl: '/headerImage.jpeg',
  foodpairingLogo: 'foodpairing_logo.svg',
  poweredByLight: '/powered_on_light.svg',
  poweredByDark: '/powered_on_dark.svg',
  margins,
  spacing
};

export default style;
