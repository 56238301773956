import * as React from 'react';
import {connect} from 'react-redux';
import {Box, Button, FormHelperText, Grid} from '@material-ui/core';
import firebase from 'firebase/app';
import {setUserAndFetchData} from '../../../../redux/authentication/LoginActionCreators';
import {AnyAction, Dispatch} from 'redux';
import {User} from 'firebase';
import {createStyles, withStyles} from '@material-ui/core/styles';
import StyledButton from '../../../../styling/StyledButton';
import {AuthenticationViewProps} from '../AuthenticationTypesInterfaces';
import StyledTextField from '../../../../styling/StyledTextField';
import {Field, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import {FormattedMessage, injectIntl, IntlShape} from 'react-intl';
import AuthenticationSwitch from '../../containers/AuthenticationSwitch';
import style from "../../../../styling/Style";
import {UserSetupConfig} from "../../../../models/user-models/UserModels";
import {userSetupConfigFromParams} from "../../../../utils/user";


interface Props extends AuthenticationViewProps, RouteComponentProps<{}> {
  onAuthenticate: (currentUser?: firebase.User | null, userSetupConfig?: UserSetupConfig | null) => AnyAction;
  classes: {};
  intl: IntlShape;
  email?: string;
}

interface State {
}

const styles = createStyles({});

const SigninSchema = (props: Props) => {
  const { intl } = props;
  return Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'authentication_email_invalid' }))
      .required(intl.formatMessage({ id: 'authentication_required' })),
    password: Yup.string()
      .min(2, intl.formatMessage({ id: 'authentication_password_too_short' }))
      .max(50, 'authentication_password_too_long')
      .required(intl.formatMessage({ id: 'authentication_required' })),
    global: Yup.string().notRequired()
  });
};

class SignInForm extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  getQueryParams() {
    return new URLSearchParams(this.props.location.search);
  }

  isMailVerified(user: any, formikHelpers: FormikHelpers<any>) {
    const { onAuthenticate } = this.props;
    if (user?.emailVerified) {
      const queryParams = this.getQueryParams();
      const userSetupConfig = userSetupConfigFromParams(queryParams);
      return onAuthenticate(user, userSetupConfig);
    } else {
      // XXX: also format query params!!!
      this.props.history.replace('/verify-email');
      formikHelpers.setErrors({
        global: 'Email is not verified'
      });
      return false;
    }
  }

  onSignIn(values: any, formikHelpers: FormikHelpers<any>) {
    const { onAction } = this.props;

    return firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(
        (value) => this.isMailVerified(value.user, formikHelpers),
        function (error) {
          // Handle Errors here.
          const errorMessage = error.message;
          formikHelpers.setErrors({
            global: errorMessage
          });
        }
      )
      .then((success) => {
        if (success) {
          onAction();
        }
      });
  }

  render() {
    const { intl, email } = this.props;

    return (
      <Formik
        initialValues={{
          password: '',
          email: email || '',
          global: ''
        }}
        validationSchema={SigninSchema(this.props)}
        onSubmit={(values, formikHelpers) => this.onSignIn(values, formikHelpers)}
      >
        {({ handleSubmit, setErrors, values }) => {
          return (
            <Form translate="yes" onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Box pt={5}>
                    <Field name="email" type="email">
                      {(field: FieldProps<any>) => (
                        <StyledTextField
                          {...field}
                          fullWidth={true}
                          label={<FormattedMessage id={'authentication_email_label'} />}
                          placeholder={intl.formatMessage({ id: 'authentication_email_placeholder' })}
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={6}>
                    <Field name="password" type="password">
                      {(field: FieldProps<any>) => (
                        <StyledTextField
                          fullWidth={true}
                          label={<FormattedMessage id={'authentication_password_label'} />}
                          placeholder={intl.formatMessage({ id: 'authentication_password_placeholder' })}
                          autoComplete="current-password"
                          passwordToggle={true}
                          type="password"
                          {...field}
                        />
                      )}
                    </Field>

                    <Box textAlign={'right'} mt={1}>
                      <NavLink style={{ textDecoration: 'none' }} to="/forgot-password">
                        <Button size={'small'} className={'bravier-text'} style={{color:style.colors.grayscale[3]}}>
                          <FormattedMessage id={'signin_form_forgot_password'}></FormattedMessage>
                        </Button>
                      </NavLink>
                    </Box>
                  </Box>
                  <div>
                    <Field name="global">{(field: FieldProps<any>) => <FormHelperText error={true}>{field.meta.error}</FormHelperText>}</Field>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={5} mb={6}>
                    <StyledButton size="lg" type="submit">
                      <FormattedMessage id={'signin_form_login_button'}></FormattedMessage>
                    </StyledButton>
                  </Box>
                </Grid>
                {/*
                <Grid item xs={12}>
                  <SocialLogin
                    onAuthenticated={(token, user) => (user ? onAction() : () => {})}
                    onAuthenticationFailure={(_, errorMsg) => {
                      setErrors({
                        global: errorMsg
                      });
                    }}
                  />
                </Grid>
                */}
                <Grid container justify="center">
                  <AuthenticationSwitch />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onAuthenticate: (currentUser?: firebase.User | null, userSetupConfig?: UserSetupConfig | null) => {
      // @ts-ignore
      return dispatch(setUserAndFetchData(currentUser, userSetupConfig));
    }
  };
};

export default withRouter(injectIntl(withStyles(styles)(connect(null, mapDispatchToProps)(SignInForm))));
