import { SET_CURRENT_INDEX, SET_CURRENT_QUESTIONNAIRE, SET_QUESTION_STATUSES, SET_CURRENT_QUESTIONNAIRE_NAME } from './QuestionnaireActions';
import { QuestionStatuses } from './QuestionnaireReducers';
import { Questionnaire } from '../../models/questionnaire-models/internal/QuestionnaireModels';

export function setQuestionStatuses(questionnaireQuestionStatuses: QuestionStatuses) {
  return {
    type: SET_QUESTION_STATUSES,
    questionnaireQuestionStatuses: questionnaireQuestionStatuses
  };
}

export function setCurrentIndex(currentIndex: number) {
  return {
    type: SET_CURRENT_INDEX,
    currentIndex: currentIndex
  };
}

export function setCurrentQuestionnaireName(currentQuestionnaireName: string) {
  return {
    type: SET_CURRENT_QUESTIONNAIRE_NAME,
    currentQuestionnaireName: currentQuestionnaireName
  };
}

export function setCurrentQuestionnaire(currentQuestionnaire: Questionnaire) {
  return {
    type: SET_CURRENT_QUESTIONNAIRE,
    currentQuestionnaire: currentQuestionnaire
  };
}
