import { Component } from 'react';
import * as React from 'react';
import { Questionnaire } from '../../../models/questionnaire-models/internal/QuestionnaireModels';
import QuestionnaireView from '../containers/QuestionnaireView';
import { QuestionnaireDetailProps } from '../../../API/containers/QuestionnaireAPIDetailFetcher';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { BackgroundPositionProperty, BackgroundSizeProperty } from 'csstype';
import { theme } from '../../../styling/DefaultTheme';

const styles = {
  questionCard: {
    backgroundColor: 'white',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    width: '100%',
    minHeight: 'calc(75vh - 79px)',
    zIndex: 1,
    paddingBottom: 32
  },
  imageContainer: (props: Props) => ({
    backgroundImage: 'url(/forks_over_knives.svg)', // TODO: fill in image here
    backgroundPosition: 'center' as BackgroundPositionProperty<any>,
    backgroundSize: 'cover' as BackgroundSizeProperty<any>,
    [theme.breakpoints.up('xs')]: {
      minHeight: '25vh'
    },
    marginBottom: -30
  }),
  outerContainer: {
    backgroundColor: theme.palette.grey[600],
    width: '100%',
    height: 'fit-content',
    minHeight: 'calc(100vh - 79px)'
  }
};

interface Props extends QuestionnaireDetailProps, StyledComponentProps {}

class QuestionnaireCardView extends Component<Props, any> {
  render() {
    const { authToken, questionnaire, onComplete } = this.props;
    const classes = this.props.classes || {};
    const image = undefined; // '/forks_over_knives.svg';
    return (
      <div className={classes.outerContainer}>
        <Grid container style={{ minHeight: 'calc(100vh - 79px)' }} justify='center'>
          <Grid item xs={12} sm={8} style={{'margin': '0 auto'}}>
            <Grid container style={{ minHeight: 'calc(100vh - 79px)' }}>
              {image ? <Grid xs={12} className={classes.imageContainer} item /> : <Grid xs={12} item style={{ height: 32 }} />}
              <Grid xs={12} item className={classes.questionCard}>
                {this.props.children || <QuestionnaireView onComplete={onComplete} authToken={authToken as string} questionnaire={questionnaire as Questionnaire} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(QuestionnaireCardView);
