import { Component } from 'react';
import * as React from 'react';
import { QuestionProps } from '../../../../../models/questionnaire-models/internal/QuestionModels';
import { MultipleChoiceQuestionViewProps } from '../../../../../models/questiontype-models/internal/QuestionTypeModels';
import MultipleChoiceQuestionView from './MultipleChoiceQuestionView';
import { Grid, Box } from '@material-ui/core';

interface Props extends QuestionProps, MultipleChoiceQuestionViewProps {}

export default class BourdainMultipleChoiceQuestionView extends Component<Props, any, any> {
  render() {
    const { icon, checkedIcon, ...props } = this.props;
    return (
      <Grid container justify="center" style={{ padding: '0 40px' }}>
        <Box pb={9} pt={8}>
          <MultipleChoiceQuestionView icon={<img alt="unchecked" src={'/questionnaire/checkbox/checkbox_unchecked.svg'} />} checkedIcon={<img alt="checked" src={'/questionnaire/checkbox/checkbox_checked.svg'} />} {...props} />
        </Box>
      </Grid>
    );  
  }
}
