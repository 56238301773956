import { Component } from 'react';
import * as React from 'react';
import { StyledComponentProps, Box } from '@material-ui/core';
import StyledTitle from '../StyledTitle';
import StyledParagraph from '../StyledParagraph';
import style from '../Style';

interface Props extends StyledComponentProps {
  children?: any;
  title: any;
  styles?: any;
}

class NormalBlock extends Component<Props, any> {
  render() {
    let { children, title, styles } = this.props;

    return (
      <React.Fragment>
        <Box my={5} style={styles}>
          <Box mb={4}>
            <StyledTitle element="h2" variation="normal" color={style.colors.grayscale[1]}>
              {title}
            </StyledTitle>
          </Box>
          <StyledParagraph variation="normal" color={style.colors.grayscale[1]}>
            {children}
          </StyledParagraph>
        </Box>
      </React.Fragment>
    );
  }
}

export default NormalBlock;
