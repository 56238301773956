import * as React from 'react';
import { Component } from 'react';
import { PromiseState } from 'react-refetch';
import { AuthenticatedProps } from '../../shared/containers/AuthenticatedPageWithLoading';
import PromiseStatePage from "../../shared/containers/PromiseStatePage";

interface Props extends AuthenticatedProps, AuthenticatedProps {
  requestAuthToken?: PromiseState<any | undefined>;
  children: any;
}

class CustomTokenAuth extends Component<Props, any> {
  render() {
    const { requestAuthToken, authToken, children } = this.props;

    if (!requestAuthToken) {
      return <></>;
    }

    return (
        <PromiseStatePage promiseStateMapping={{ requestAuthToken }}>
          {(values: any) => {
            return children(values.requestAuthToken, authToken);
          }}
        </PromiseStatePage>
    );
  }
}

export default CustomTokenAuth;
