import * as React from 'react';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { YesNoQuestionViewProps } from '../../../../../models/questiontype-models/internal/QuestionTypeModels';
import QuestionTypeContainer from '../../QuestionTypeContainer';
import { QuestionProps } from '../../../../../models/questionnaire-models/internal/QuestionModels';
import StyledButton from '../../../../../styling/StyledButton';
import I18NFromObjectComponent from '../../../../../shared/components/I18NFromObjectComponent';

const styles = {
  buttonClass: {
    padding: 16
  }
};

interface Props extends QuestionProps, YesNoQuestionViewProps, StyledComponentProps {}

class YesOrNoQuestionView extends QuestionTypeContainer<Props, any> {
  handleYes() {
    const { inversed } = this.props;
    return this.handleChange(!(inversed || false), this.props);
  }

  handleNo() {
    const { inversed } = this.props;
    return this.handleChange(inversed || false, this.props);
  }

  render() {
    const { yesText } = this.props;

    let yesTextIntl = <I18NFromObjectComponent object={yesText}>{(yesText: any) => yesText}</I18NFromObjectComponent>;

    let noTextIntl = <I18NFromObjectComponent object={yesText}>{(noText: any) => noText}</I18NFromObjectComponent>;

    const classes = this.props.classes || {};
    return (
      <div>
        <Grid container justify="center">
          <Grid item className={`${classes.buttonClass}`}>
            <StyledButton onClick={() => this.handleYes()}>
              {yesTextIntl || 'Yes'}
            </StyledButton>
          </Grid>
          <Grid item className={`${classes.buttonClass}`}>
            <StyledButton onClick={() => this.handleNo()}>
              {noTextIntl || 'No'}
            </StyledButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(YesOrNoQuestionView);
