import React from "react";
import ErrorPageNavBar from "./ErrorPageNavBar";
import {Grid} from "@material-ui/core";
import style from "../../styling/Style";
import StyledButton from "../../styling/StyledButton";

interface Props{
    error: Error;
}

export default class GlobalErrorPageView extends React.Component<Props, any, any> {
    render(){

        const {error} = this.props;
        const supportEmail = "appsupport@foodpairing.com";
        const supportURI = "mailto:"+supportEmail+"?" +
            "subject=" + encodeURIComponent("[Error report] "+error.message) +
            "&body=" + encodeURIComponent("\n\n---------\n\n"+JSON.stringify(error.stack));

        return (
            <div>
                <ErrorPageNavBar />
                <Grid container style={{ padding:40, paddingTop: 100 }} alignItems="center" justify="center">
                    <Grid item xs={12} md={8}>
                        <div style={{textAlign:'center', borderRadius:10, background:'#fbfbfb', padding:14, float:'right', marginBottom:10}}>
                            <StyledButton href={supportURI}>
                                Report error
                            </StyledButton>
                            <div style={{...style.typography.bravier, color:style.colors.grayscale[1], marginTop:10}}>
                                or contact us at <a style={{color:style.colors.system.link}} href={"mailto:"+supportEmail}>{supportEmail}</a>
                            </div>
                        </div>
                        <h1 style={{...style.typography.doublePica, color:style.colors.grayscale[1]}}>Something went wrong</h1>
                        <p style={{color:style.colors.system.danger}}><strong>{error.name}</strong>: {error.message}</p>
                        <div style={{wordBreak:'break-all', whiteSpace:'pre-wrap', color:style.colors.grayscale[2]}}>{error.stack}</div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
