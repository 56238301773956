import {AromaTypographyStyleInterface} from "./aroma";

const fontFamily = "'Open Sans', -apple-system, 'San Francisco', 'Helvetica Neue', 'Lucida Grande', sans-serif";

const typography: AromaTypographyStyleInterface = {
    canon: {
        fontFamily,
        fontSize: '68px',
        fontWeight: 600,
        fontStyle: 'normal',
        lineHeight: '74px'
    },
    trafalgar: {
        fontFamily,
        fontSize: '44px',
        fontWeight: 600,
        fontStyle: 'normal',
        lineHeight: '56px'
    },
    paragon: {
        fontFamily,
        fontSize: '32px',
        fontWeight: 600,
        fontStyle: 'normal',
        lineHeight: '46px'
    },
    doublePica: {
        fontFamily,
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '38px'
    },
    greatPrimer: {
        fontFamily,
        fontWeight: 400,
        fontSize: '22px',
        fontStyle: 'normal',
        lineHeight: '32px'
    },
    greatPrimer_strong: {
        fontFamily,
        fontWeight: 600,
        fontSize: '22px',
        fontStyle: 'normal',
        lineHeight: '32px'
    },
    pica: {
        fontFamily,
        fontWeight: 400,
        fontSize: '20px',
        fontStyle: 'normal',
        lineHeight: '30px'
    },
    pica_strong: {
        fontFamily,
        fontWeight: 600,
        fontSize: '20px',
        fontStyle: 'normal',
        lineHeight: '32px'
    },
    body: {
        fontFamily,
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '25px'
    },
    body_strong: {
        fontFamily,
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px'
    },
    body_highlight: {
        fontFamily,
        fontSize: '17px',
        fontStyle: 'italic',
        fontWeight: 400,
        lineHeight: '25px'
    },
    button: {
        fontFamily,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        textTransform: 'none'
    },
    paragraphText: {
        fontFamily,
        fontSize: '16px',
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: '22px'
    },
    bravier: {
        fontFamily,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    bravier_strong: {
        fontFamily,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px'
    },
    minion: {
        fontFamily,
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    minion_strong: {
        fontFamily,
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px'
    },
    micro: {
        fontFamily,
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14px'
    },
    flavorCircle: {
        fontFamily,
        fontWeight: 400,
        fontSize: '21px',
        fontStyle: 'italic',
    },
    flavorCircleSeparator: {
        fontFamily,
        fontWeight: 200,
        fontSize: '31px',
        fontStyle: 'italic',
        lineHeight: '35px',
    }
};

export default typography;
