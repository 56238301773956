import React from 'react';
import { connect as reduxConnect } from 'react-redux';
import { State } from '../../redux/Reducers';

interface Props {
  object: any;
  language: any;
  children: any;
}

class I18NFromObjectComponent extends React.Component<Props, any> {
  render() {
    const { object, language, children } = this.props;

    return children(object[language.locale] || object['en']);
  }
}

const mapStateToProps = (state: State) => {
  return {
    language: state.language.language
  };
};

export default reduxConnect(mapStateToProps, {})(I18NFromObjectComponent);
