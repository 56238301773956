import { Component } from 'react';
import * as React from 'react';
import { Question, QuestionMetaData } from '../../../models/questionnaire-models/internal/QuestionModels';
import QuestionView from './QuestionView';
import { questionTypesToQuestionView } from '../../../models/questiontype-models/internal/QuestionTypeModels';

interface Props {
  question: Question;
  value: any;
  handleChange: (value: any) => void;
}

export default class QuestionRenderer extends Component<Props, any> {
  private renderQuestion() {
    const { question, value, handleChange } = this.props;
    // @ts-ignore
    const ComponentClass = questionTypesToQuestionView[question.type];
    const metaData: QuestionMetaData = question.metaData || {};

    return (
      <div>
        <QuestionView name={question.data.props.name} {...metaData}>
          <ComponentClass type={question.type} value={value || (question.answer || { value: undefined }).value} {...question.data.props} questionViewProps={metaData} handleChange={handleChange} />
        </QuestionView>
      </div>
    );
  }

  render() {
    return this.renderQuestion();
  }
}
