import { Component } from 'react';
import React from 'react';
import { Container, Grid, List, StyledComponentProps, withStyles, Box, Typography } from '@material-ui/core';
import { theme } from '../../../styling/DefaultTheme';
import style from '../../../styling/Style';
import FlavourIdComponent from '../../../shared/components/visualisations/FlavourIdComponent';
import RightPaneContainer from './RightPaneContainer';
import AuthenticatedPageWithLoading from '../../../shared/containers/AuthenticatedPageWithLoading';
import LoginButton from '../../authentication/containers/LoginButton';
import FilterQuestionnaireOverview from '../../questionnaires/containers/FilterQuestionnaireOverview';
import QuestionnaireOverview from '../../questionnaires/containers/QuestionnaireOverview';
import QuestionnaireListItem from '../../questionnaire-page/components/QuestionnaireListItem';
import { FormattedMessage } from 'react-intl';
import FlavourIdDataFetcher from '../../../API/containers/FlavourIdDataFetcher';
import {FlavourIdData} from '../../../models/user-models/UserModels';
import StyledParagraph from '../../../styling/StyledParagraph';
import FlavourIdDescription from './FlavourIdDescription';
import IntegrationFetcher from 'API/containers/IntegrationFetcher';
import { AppIntegrationEnumerationViewProps } from 'models/integration-models/internal/IntegrationModels';
import IntegrationsOverviewComponent from './IntegrationsOverviewComponent';
import ActionListItem from "../../questionnaire-page/components/ActionListItem";
import SwitchAccountDialog from "../../../shared/components/structural/SwitchAccountDialog";

const styles = {
  questionnaireContainer: {
    [theme.breakpoints.up('xs')]: {
      marginLeft: style.margins.small,
      marginRight: style.margins.small,
      marginTop: style.margins.small
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: style.margins.medium,
      marginRight: style.margins.medium,
      marginTop: 50
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: style.margins.large,
      marginRight: style.margins.large
    }
  },
  buttonContainer: {
    [theme.breakpoints.up('xs')]: {
      marginTop: style.margins.small
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 50
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 200
    }
  },
  logoutButtonLabel: {
    textTransform: 'none' as any
  },
  flavourIdGraphTitle: {
    ...style.typography.paragon
  }
};

export interface Props extends StyledComponentProps {
  takenQuestionnaires: boolean;
  newQuestionnaires: boolean;
  integrations: boolean;
  logoutButton: boolean;
}

class ProfilePageComponent extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      switchAccountDialogOpen: false,
    };
  }

  componentDidMount(): void {
    // TODO: remove | This is for debugging purposes
    // // @ts-ignore
    // firebase.auth().currentUser.getIdToken().then((token) => {
    //     console.log(token);
    // })
  }
  
  switchAccount(open: boolean): void {
    this.setState({ switchAccountDialogOpen: open });
  }

  render() {
    const classes = this.props.classes || {};

    return (
      <Container>
        <Box mt={4}>
          <Box mb={[4, 4, 6, 8]}>
            <Grid container direction="row">
              <AuthenticatedPageWithLoading>
                <FlavourIdDataFetcher>
                  {(flavourIdData: { flavourIdData: FlavourIdData }) => (
                    <>
                      {/*Left hand side - FlavourID visualisation*/}
                      <Grid item xs={12} md={8}>
                        <Box px={4} pt={[0, 6, 6, 9, 9]}>
                          <Typography className={classes.flavourIdGraphTitle}>
                            <FormattedMessage id={'flavorid_graph_title'}></FormattedMessage>
                          </Typography>
                          <FlavourIdComponent withBlocks={true} flavourIdData={flavourIdData.flavourIdData} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box mt={[6, 6, 10, 10]}>
                          <Grid container direction="column">
                            {/* FlavourID taste description */}
                            <Grid item xs={12}>
                              <Grid container justify="center">
                                <Box mb={7}>
                                  <FlavourIdDescription flavourIdData={flavourIdData.flavourIdData}/>
                                </Box>
                              </Grid>
                            </Grid>
                            {/* Health score and test completion components */}
                            <Grid item xs={12}>
                              <Grid container justify="center">
                                <RightPaneContainer />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </>
                  )}
                </FlavourIdDataFetcher>
              </AuthenticatedPageWithLoading>
            </Grid>
          </Box>

          <Grid container direction="row-reverse" spacing={0} className={`${classes.container}`} justify="flex-start">
            {/* TAKEN QUESTIONNAIRES */}
            {this.props.takenQuestionnaires ? (
              <Grid item xs={12} md={4}>
                <Box py={6} px={4}>
                  <StyledParagraph variation="messageMedium" color={style.colors.grayscale[1]}>
                    <FormattedMessage id={'profilepage_taken_questionnaires'}></FormattedMessage>
                  </StyledParagraph>
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    <AuthenticatedPageWithLoading>
                      <FilterQuestionnaireOverview filter="NOT_NEW">
                        <QuestionnaireOverview />
                      </FilterQuestionnaireOverview>
                    </AuthenticatedPageWithLoading>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {/* NEW QUESTIONNAIRES */}
            {this.props.newQuestionnaires ? (
              <Grid item xs={12} sm={6} md={8}>
                <Box py={6} px={4}>
                  <StyledParagraph variation="messageMedium" color={style.colors.grayscale[1]}>
                    <FormattedMessage id={'profilepage_new_questionnaire'}></FormattedMessage>
                  </StyledParagraph>
                </Box>

                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <List>
                      <AuthenticatedPageWithLoading>
                        <FilterQuestionnaireOverview filter="NEW">
                          <QuestionnaireOverview component={QuestionnaireListItem} />
                        </FilterQuestionnaireOverview>
                        <div>
                          <SwitchAccountDialog onClose={() => this.switchAccount(false)} open={this.state.switchAccountDialogOpen} />
                          <ActionListItem
                            title={<FormattedMessage id={'toolbar_avatar_popup_switch_or_add_user'} />}
                            description={<FormattedMessage id={'toolbar_avatar_popup_switch_or_add_user'} />}
                            url={''}
                            onClick={(e) => {
                              e.preventDefault();
                              this.switchAccount(true)
                            }}
                            actionKey="switch_or_add_user"
                          />
                        </div>
                      </AuthenticatedPageWithLoading>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid container spacing={0} className={`${classes.container}`}>
            {/* ACTIVE INTEGRATIONS */}
            {this.props.integrations ? (
              <AuthenticatedPageWithLoading>
                <IntegrationFetcher>{(integrationsList: AppIntegrationEnumerationViewProps) => <IntegrationsOverviewComponent integrations={integrationsList} />}</IntegrationFetcher>
              </AuthenticatedPageWithLoading>
            ) : (
              <></>
            )}
          </Grid>

          {this.props.logoutButton ? (
            <div className={`${classes.buttonContainer}`}>
              <Grid container justify="flex-start">
                <Grid item xs={12} sm={6} lg={3}>
                  <LoginButton
                    text={<FormattedMessage id={'profilepage_logout'} />}
                    buttonProps={{
                      style: { width: '100%' },
                      variant: 'outlined',
                      classes: {
                        label: classes.logoutButtonLabel
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <></>
          )}
        </Box>
      </Container>
    );
  }
}

export default withStyles(styles)(ProfilePageComponent);
