/**
 * File that exports all Authentication view type names (as string) that can be rendered
 */

export const SIGNIN = 'SIGNIN';

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_VERIFY_EMAIL = 'SIGNUP_VERIFY_EMAIL';
export const SIGNUP_PERSONAL_DATA = 'SIGNUP_PERSONAL_DATA'; // TODO: personal data view if necessary
export const SIGNUP_QUESTIONNAIRE = 'SIGNUP_QUESTIONNAIRE';
export const FINISHED = 'FINISHED';
