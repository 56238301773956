import * as React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Box, Typography, Grid, FormControlLabel, Link, FormHelperText, StyledComponentProps } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import firebase from 'firebase/app';
import { AuthenticationViewProps, AuthenticationViewType } from '../AuthenticationTypesInterfaces';
import { AnyAction, Dispatch } from 'redux';
import { SIGNUP_PERSONAL_DATA } from '../../AuthenticationViewTypes';

import { setUserAndFetchData } from '../../../../redux/authentication/LoginActionCreators';
import { withStyles } from '@material-ui/core/styles';
import StyledButton from '../../../../styling/StyledButton';
import StyledTextField from '../../../../styling/StyledTextField';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Checkbox } from 'formik-material-ui';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import AuthenticationSwitch from '../../containers/AuthenticationSwitch';
import style from '../../../../styling/Style';
import {RouteComponentProps, withRouter} from "react-router-dom";
import { UserSetupConfig } from "../../../../models/user-models/UserModels";
import { userSetupConfigFromParams } from "../../../../utils/user";

interface State {
  errorMessage: string;
  unsubscribeAuthHandler?: () => void;
}

const messageText: string = 'Please agree to our terms.';

interface Props extends AuthenticationViewProps, StyledComponentProps, RouteComponentProps<{}> {
  onAuthenticate: (currentUser?: firebase.User | null, userSetupConfig?: UserSetupConfig | null) => AnyAction;
  classes: {
    fullWidth: string;
    signUpTitle: string;
    signupDescription: string;
    checkbox: string;
  };
  intl: IntlShape;
}

const styles = (theme: any) => ({
  fullWidth: {
    width: '100%'
  },
  signUpTitle: {
    ...style.typography.doublePica,
    marginBottom: '12px',
    color: style.colors.grayscale[1]
  },
  signupDescription: {
    ...style.typography.body,
    marginBottom: '12px',
    color: style.colors.grayscale[2]
  },
  checkbox: {
    "& span": {
      "& svg": {
        width: '24px',
        height: '24px'
      }
    }
  }
});

const SignupSchema = (props: Props) => {
  const { intl } = props;

  return Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'authentication_email_invalid' }))
      .required(intl.formatMessage({ id: 'authentication_required' })),
    password: Yup.string()
      .min(2, intl.formatMessage({ id: 'authentication_password_too_short' }))
      .max(50, intl.formatMessage({ id: 'authentication_password_too_long' }))
      .required(intl.formatMessage({ id: 'authentication_required' })),
    consent: Yup.bool().required('Please agree with our terms').oneOf([true], 'Please agree with our terms'),
    consentForSocial: Yup.string().notRequired(),
    global: Yup.string().notRequired(),
    success: Yup.bool()
  });
};

/*
const SigninSchema = (props:Props) => {
    const {intl} = props;
    return Yup.object().shape({
        email: Yup.string().email(intl.formatMessage({id: 'authentication_email_invalid'}))
            .required(intl.formatMessage({id: 'authentication_required'})),
        password: Yup.string().min(2, intl.formatMessage({id: 'authentication_password_too_short'}))
            .max(50, 'authentication_password_too_long')
            .required(intl.formatMessage({id: 'authentication_required'})),
        global: Yup.string().notRequired()
    });
};
*/

class SignUpForm extends AuthenticationViewType<Props, State> {
  static NEXT_VIEW: string = SIGNUP_PERSONAL_DATA;

  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: '',
      unsubscribeAuthHandler: undefined
    };
  }

  checkIfSelected(consentSelected: boolean) {
    this.setState({ errorMessage: !consentSelected ? messageText : '' });
  }

  getQueryParams() {
    return new URLSearchParams(this.props.location.search);
  }

  onSignUp(values: any, formikHelpers: FormikHelpers<any>) {
    const { onAuthenticate, history } = this.props;

    const queryParams = this.getQueryParams();

    const actionCodeSettings: any = {};

    const userSetupConfig = userSetupConfigFromParams(queryParams);

    return firebase
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then(
        (value) => onAuthenticate(value.user, userSetupConfig),
        function (error) {
          // Handle Errors here.
          const errorMessage = error.message;
          formikHelpers.setErrors({
            global: errorMessage
          });
        }
      )
      .then((success) => {
        if (success) {
          const unsubscribe = firebase.auth().onAuthStateChanged(function (user: firebase.firebase.User | null) {
            user
              ?.sendEmailVerification(actionCodeSettings)
              .then(function () {
                formikHelpers.setValues({
                  ...values,
                  success: true
                });
                /* Removed in order to enable auto log-in after email verification */
                /* Removing the logout after signup is how we can allow the auto login after
                   email verification ( on same browser ).
                   The email verification url contains only the activation token.
                   Firebase don't provide auth credentials with the verification token.
                   So if the user is disconnected we cannot automatically reconnect it
                   unless it was already connected
                */
                /* firebase
                  .auth()
                  .signOut()
                  .then(function () {
                    // Sign-out successful.
                    console.log(`signed out`);
                  }); */

                /* Go to verification page */
                history.push('/verify-email');
              })
              .catch(function (error) {
                formikHelpers.setErrors({
                  global: error.message
                });
              });
          });
          this.setState({unsubscribeAuthHandler: unsubscribe});
        }
      });
  }

  componentWillUnmount() {
    if (this.state.unsubscribeAuthHandler) {
      this.state.unsubscribeAuthHandler();
      this.setState({unsubscribeAuthHandler: undefined});
    }
  }

  render() {
    const { intl } = this.props;
    const classes = this.props.classes || {};

    return (
      <Formik
        initialValues={{
          password: '',
          email: '',
          consent: false,
          global: '',
          consentForSocial: false,
          success: false
        }}
        validationSchema={SignupSchema(this.props)}
        onSubmit={(values, formikHelpers) => this.onSignUp(values, formikHelpers)}
      >
        {({ handleSubmit, setErrors, values }) =>  {
          return (
            <Grid container className={`HUI: ${window.location.search.toString()}`}>
              <Grid item xs={12}>
                <Box textAlign="left">
                  <Typography className={classes.signUpTitle}>
                    <FormattedMessage id={'signup_form_title'}></FormattedMessage>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box textAlign="left">
                  <Typography className={classes.signupDescription}>
                    <FormattedMessage id={'signup_form_description'}></FormattedMessage>
                  </Typography>
                </Box>
              </Grid>
              <Form translate="yes" onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <Box pt={5}>
                    <Field name="email" type="email">
                      {(field: FieldProps<any>) => (
                        <StyledTextField
                          {...field}
                          fullWidth={true}
                          label={<FormattedMessage id={'authentication_email_label'} />}
                          placeholder={intl.formatMessage({ id: 'authentication_email_placeholder' })}
                          margin="normal"
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1}>
                    <Field name="password" type="password">
                      {(field: FieldProps<any>) => (
                        <StyledTextField
                          fullWidth={true}
                          label={<FormattedMessage id={'authentication_password_label'} />}
                          placeholder={intl.formatMessage({ id: 'authentication_password_placeholder' })}
                          autoComplete="current-password"
                          margin="normal"
                          type="password"
                          passwordToggle={true}
                          {...field}
                        />
                      )}
                    </Field>

                    <FormControlLabel
                      control={
                        <div>
                          <Field name="consent" type="checkbox">
                            {(field: FieldProps<any>) => (
                                <div>
                                  <Checkbox className={classes.checkbox}  {...field} disableRipple />
                                </div>
                            )}
                          </Field>
                        </div>
                      }
                      label={
                        <div className={'small-text'}>
                          {<FormattedMessage id={'signup_form_agree'} />}{' '}
                          <Link href={'/tos'}>
                            <FormattedMessage id={'signup_form_tos'} />
                          </Link>{' '}
                          {<FormattedMessage id={'signup_form_and'} />}{' '}
                          <Link href={'/privacy'}>
                            <FormattedMessage id={'signup_form_pp'} />
                          </Link>
                        </div>
                      }
                    />
                    <div className={'MuiFormHelperText-root Mui-error'}>
                      <ErrorMessage name="consent" />
                    </div>
                    {values.success && (
                      <Alert severity="success">
                        <FormattedMessage id={'sign_up_successful'} />
                      </Alert>
                    )}
                  </Box>
                </Grid>

                <div>
                  <Field name="global">{(field: FieldProps<any>) => <FormHelperText error={true}>{field.meta.error}</FormHelperText>}</Field>
                </div>

                <Grid item xs={12}>
                  <Box mt={5} mb={6}>
                    <StyledButton type="submit" size="lg">
                      <FormattedMessage id={'signup_form_signup_button'}></FormattedMessage>
                    </StyledButton>
                  </Box>
                </Grid>
              </Form>
              {/*
              <Grid item xs={12}>
                <SocialLogin
                  displayMessage={() => {
                    this.checkIfSelected(values.consentForSocial);
                  }}
                  onAuthenticated={(token, user) => (user ? onAction() : () => {})}
                  onAuthenticationFailure={(_, errorMsg) => {
                    setErrors({
                      global: errorMsg
                    });
                  }}
                  preventClick={!values.consentForSocial}
                />
              </Grid>
              <Grid>
                <FormControlLabel
                  onChange={() => this.checkIfSelected(!values.consentForSocial)}
                  control={
                    <div>
                      <Field name="consentForSocial" type="checkbox">
                        {(field: FieldProps<any>) => (
                            <div>
                              <Checkbox disableRipple className={classes.checkbox}  {...field} />
                            </div>
                        )}
                      </Field>
                    </div>
                  }
                  label={
                    <div className={'small-text'}>
                      {<FormattedMessage id={'signup_form_agree'} />}{' '}
                      <Link href={'/tos'}>
                        <FormattedMessage id={'signup_form_tos'} />
                      </Link>{' '}
                      {<FormattedMessage id={'signup_form_and'} />}{' '}
                      <Link href={'/privacy'}>
                        <FormattedMessage id={'signup_form_pp'} />
                      </Link>
                    </div>
                  }
                />
                <div className={'MuiFormHelperText-root Mui-error'}>{errorMessage}</div>
              </Grid>
              */}
              <Grid container justify="center">
                <AuthenticationSwitch />
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onAuthenticate: (currentUser?: firebase.User | null, userSetupConfig?: UserSetupConfig | null) => {
      // @ts-ignore
      return dispatch(setUserAndFetchData(currentUser, userSetupConfig));
    }
  };
};

export default withRouter(injectIntl(withStyles(styles)(connect(null, mapDispatchToProps)(SignUpForm))));
