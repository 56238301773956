import { Dispatch, ForwardedRef, PropsWithChildren, useEffect } from "react";
import * as React from "react";
import { Container, Grid, Hidden } from "@material-ui/core";
import { AromaCard } from '@design-system/components/AromaCard';
import { createUseStyles, useTheme } from "react-jss";
import Heading from '@design-system/components/Heading';
import AromaNavigator from '@design-system/components/AromaNavigator';
import { AromaDotItemInterface } from "@design-system/components/AromaDot";
import AnimatedScrollTo from "../../../@design-system/utils/AnimatedScrollTo";

interface ComponentProps {
}

const getAromas: ( theme: any ) => AromaDotItemInterface[] = (theme: any) => ([
    {
        title: 'Caramel',
        description: 'caramel_description',
        color: theme.palette.aromas.caramel,
        image: '/aromas/bg/Caramel.png'
    },
    {
        title: 'Spicy',
        description: 'spicy_description',
        color: theme.palette.aromas.spicy,
        image: '/aromas/bg/Spicy.png'
    },
    {
        title: 'Fruity',
        description: 'fruity_description',
        color: theme.palette.aromas.fruity,
        image: '/aromas/bg/Fruity.png'
    },
    {
        title: 'Cheese',
        description: 'cheese_description',
        color: theme.palette.aromas.cheese,
        image: '/aromas/bg/Cheese.png'
    },
    {
        title: 'Herbal',
        description: 'herbal_description',
        color: theme.palette.aromas.herbal,
        image: '/aromas/bg/Herbal.png'
    },
    {
        title: 'Green',
        description: 'green_description',
        color: theme.palette.aromas.green,
        image: '/aromas/bg/Green.png'
    },
    {
        title: 'Vegetable',
        description: 'vegetable_description',
        color: theme.palette.aromas.vegetable,
        image: '/aromas/bg/Vegetable.png'
    },
    {
        title: 'Citrus',
        description: 'citrus_description',
        color: theme.palette.aromas.citrus,
        image: '/aromas/bg/Citrus.png'
    },
    {
        title: 'Floral',
        description: 'floral_description',
        color: theme.palette.aromas.floral,
        image: '/aromas/bg/Floral.png'
    },
    {
        title: 'Animal',
        description: 'animal_description',
        color: theme.palette.aromas.animal,
        image: '/aromas/bg/Animal.png'
    },
    {
        title: 'Roasted',
        description: 'roasted_description',
        color: theme.palette.aromas.roasted,
        image: '/aromas/bg/Roasted.png'
    },
    {
        title: 'Woody',
        description: 'woody_description',
        color: theme.palette.aromas.woody,
        image: '/aromas/bg/Woody.png'
    },
    {
        title: 'Nutty',
        description: 'nutty_description',
        color: theme.palette.aromas.nutty,
        image: '/aromas/bg/Nutty.png'
    }
])

const useStyles = createUseStyles((theme:any)=>({
    headingContainer:{
        paddingLeft:'20%',
        paddingRight:'20%',
        marginBottom: theme.spacing[10],
        [`@media (max-width: ${theme.breakpoints.sm}px)`]:{
            paddingLeft:theme.spacing[2],
            paddingRight:theme.spacing[5],
            marginBottom: theme.spacing[7]
        }
    },
    aromaCardContainer:{
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            marginRight: theme.spacing[4]
        }
    }
}));

const AromasPageComponent: React.FC<ComponentProps> =
() => {
    const theme: any = useTheme();
    const aromas: AromaDotItemInterface[] = getAromas(theme);
    const classes = useStyles();
    const scrollToCard = ( index: number ) => {

        if(!Refs[index].current) return;

        const rect = Refs[index].current.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        const scrollToY = rect.y /*- rect.height + 20*/;

        AnimatedScrollTo((scrollToY + scrollTop))

        /* Removed because Safari Mobile don't support Smooth Scrolling */
        /*Refs[index] && Refs[index].current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest'
        });*/
    }

    /*
    * Moved the state update to the AromaNavigator component
    * using getActiveUpdater prop
    * to prevent Mobile Safari image flickering due to
    * component re-render
     */
    // const [activeAroma, setActiveAroma] = useState<number>();

    let setActiveAroma: Dispatch<number>;
    let getActiveAroma: () => number;

    const Refs: any[] = aromas.map((item, index) => React.createRef());

    const p = ''; // todo: add aroma description

    const RefAromaCard = React.forwardRef((props:PropsWithChildren<any>, ref:ForwardedRef<HTMLDivElement>)=>(
        <div ref={ref} className={classes.aromaCardContainer}>
            <AromaCard {...props} />
        </div>
    ));

    const handleScroll = () => {

        let selectedAroma: number = -1;

        Refs.map((item, index)=>{
            if(item.current){
                const rect = item.current.getBoundingClientRect();
                const cardPos = index ? rect.y - 40 : rect.y - rect.height ;
                if(cardPos <=0){
                    selectedAroma = index;
                }
            }
            return item;
        });

        if(setActiveAroma && getActiveAroma && getActiveAroma() !== selectedAroma){
            setActiveAroma(selectedAroma);
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    });

    return (
        <>
            <Hidden smUp>
                <AromaNavigator
                    aromas={aromas}
                    getActiveUpdater={(updater, getter)=>{
                        setActiveAroma = updater;
                        getActiveAroma = getter;
                    }}
                    onAromaDotClick={(aroma, index)=>{
                        scrollToCard(index);
                    }}
                />
            </Hidden>
            <Container maxWidth="lg" style={{paddingTop: theme.spacing[9]}}>
                <div className={classes.headingContainer}>
                    <Heading title="Aroma Types" paragraph={p} titleAlign="center" />
                </div>

                <Grid container direction="row">
                    <Grid item md={6}>
                        <Grid container direction="column">
                            {aromas.map((item, index)=>{
                                return index % 2 === 0  ? (
                                    <Grid key={index} item>
                                        <RefAromaCard title={item.title} description={item.description} aromaColor={item.color} aromaImage={item.image} ref={Refs[index]} />
                                    </Grid>
                                ) : (
                                    <Hidden key={index} mdUp>
                                        <Grid item key={index}>
                                            <RefAromaCard title={item.title} description={item.description} aromaColor={item.color} aromaImage={item.image} pictureRight={true} ref={Refs[index]} />
                                        </Grid>
                                    </Hidden>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item md={6} style={{paddingTop:theme?.spacing[8]}}>
                        <Grid container direction="column">
                            {aromas.map((item, index)=>{
                                return index % 2 !== 0  && (
                                    <Hidden smDown key={index}>
                                        <Grid item>
                                            <AromaCard title={item.title} description={item.description} aromaColor={item.color} aromaImage={item.image} pictureRight={true} />
                                        </Grid>
                                    </Hidden>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden smUp>
                    <div style={{marginBottom:200}}></div>
                </Hidden>
            </Container>
        </>
    );
}

export default AromasPageComponent;
