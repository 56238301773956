import style from '../../../styling/Style';

// TODO: use colors from visualisation
export const flavourColors: { [key: string]: string } = {
  Spicy: style.colors.aromas.spicy,
  Caramel: style.colors.aromas.caramel,
  Fruity: style.colors.aromas.fruity,
  Cheese: style.colors.aromas.cheese,
  Chemical: style.colors.aromas.chemical,
  Herbal: style.colors.aromas.herbal,
  Green: style.colors.aromas.green,
  Vegetable: style.colors.aromas.vegetable,
  Citrus: style.colors.aromas.citrus,
  Floral: style.colors.aromas.floral,
  Animal: style.colors.aromas.animal,
  Roasted: style.colors.aromas.roasted,
  Woody: style.colors.aromas.woody,
  Nutty: style.colors.aromas.nutty
};
