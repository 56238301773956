import { Component } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { State } from '../../redux/Reducers';
import { Redirect, Route } from 'react-router-dom';
import firebase from 'firebase/app';

class AuthenticatedRoute extends Component<any, any> {
  render() {
    const { isAuthenticated, component, children, ...rest } = this.props;
    const RenderedComponent = component;
    const isEmailVerified = firebase.auth().currentUser?.emailVerified;
    // @ts-ignore
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuthenticated || !isEmailVerified) {
            return (
              <Redirect
                to={{
                  pathname: '/signup',
                  state: { from: props.location }
                }}
              />
            );
          } else if (RenderedComponent) {
            // @ts-ignore
            return <RenderedComponent {...props} />;
          } else {
            return <>{this.props.children}</>;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    isAuthenticated: state.authentication.authenticated
  };
};

export default connect(mapStateToProps, {})(AuthenticatedRoute);
