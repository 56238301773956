import {ThemeOptions} from '@material-ui/core/styles';
import * as style from './styles';
import {createTheme} from "../utils";

export interface TestTheme extends ThemeOptions{

}

export const aroma = createTheme(style, {

    title:{
        big:{
            color: style.colors.grayscale[2],
            ...style.typography.trafalgar
        },
        normal:{
            color: style.colors.grayscale[1],
            ...style.typography.doublePica
        },
        medium:{
            color: style.colors.brand.blueGreen,
            ...style.typography.greatPrimer_strong
        },
        small:{
            color: style.colors.grayscale[1],
            ...style.typography.pica
        },
        minion:{
            color: style.colors.brand.blueGreen,
            ...style.typography.bravier_strong
        }
    },

    toggle:{
        on:{
            backgroundColor: style.colors.brand.brandGreenLight
        },
        off:{
            backgroundColor: style.colors.grayscale[2]
        },
        title:{
            color: style.colors.grayscale[1]
        },
        titleDisabled:{
            color: style.colors.grayscale[6]
        },
        disabled:{
            backgroundColor: style.colors.grayscale[6]
        },
        handle:{
            backgroundColor: style.colors.baseColorPalette.white
        }
    },

    headings:{
        title:{
            color: style.colors.grayscale[1]
        },
        paragraph:{
            color: style.colors.grayscale[1]
        }
    },

    aromaCards:{
        title:{
            color: style.colors.brand.blueGreen
        },
        description:{
            color: style.colors.grayscale[1]
        }
    },

    /*
    * Buttons Theme
    */
    buttons:{

        /* Primary variant */
        primary:{
            normal:{
                backgroundColor: style.colors.grayscale[2],
                borderColor: '#43525A',
                color: style.colors.baseColorPalette.white
            },
            hover:{
                backgroundColor: style.colors.brand.brandGreenLight,
                borderColor: '#00A9A3'
            },
            active:{
                backgroundColor: style.colors.brand.brandGreen,
                borderColor: '#218A7E'
            },
            disabled:{
                backgroundColor: style.colors.grayscale[4],
                borderColor: '#A6B7BF'
            }
        },

        /* Secondary variant */
        secondary:{
            normal:{
                backgroundColor: style.colors.baseColorPalette.white,
                borderColor: style.colors.brand.darkGreen,
                color: style.colors.grayscale[2]
            },
            hover:{
                backgroundColor: style.colors.baseColorPalette.white,
                borderColor: style.colors.brand.darkGreen,
                color: style.colors.grayscale[2]
            },
            active:{
                backgroundColor: style.colors.baseColorPalette.white,
                borderColor: style.colors.brand.brandGreen,
                color: style.colors.brand.brandGreen,
            },
            disabled:{
                backgroundColor: style.colors.baseColorPalette.white,
                borderColor: style.colors.grayscale[4],
                color: style.colors.grayscale[4]
            }
        },

        /* Icon variant */
        icon:{
            normal:{
                backgroundColor: style.colors.baseColorPalette.white,
                borderColor: style.colors.grayscale[2],
                color: style.colors.grayscale[2]
            },
            hover:{
                backgroundColor: style.colors.baseColorPalette.white,
                borderColor: style.colors.grayscale[2],
                color: style.colors.grayscale[2]
            },
            active:{
                backgroundColor: style.colors.baseColorPalette.white,
                borderColor: style.colors.grayscale[2],
                color: style.colors.grayscale[2],
            },
            disabled:{
                backgroundColor: style.colors.baseColorPalette.white,
                borderColor: style.colors.grayscale[4],
                color: style.colors.grayscale[4],
            }
        },

        /* Text only variant */
        text:{
            normal:{
                backgroundColor: style.colors.baseColorPalette.transparent,
                borderColor: style.colors.baseColorPalette.transparent,
                color: style.colors.grayscale[2]
            },
            hover:{
                backgroundColor: style.colors.baseColorPalette.transparent,
                borderColor: style.colors.baseColorPalette.transparent,
                color: style.colors.grayscale[2]
            },
            active:{
                backgroundColor: style.colors.baseColorPalette.transparent,
                borderColor: style.colors.baseColorPalette.transparent,
                color: style.colors.brand.brandGreenLight,
            },
            disabled:{
                backgroundColor: style.colors.baseColorPalette.transparent,
                borderColor: style.colors.baseColorPalette.transparent,
                color: style.colors.grayscale[4],
            }
        },

        circle:{
            normalIconLeft:{
                color: style.colors.grayscale[3]
            },
            disabledIconLeft:{
                color: style.colors.grayscale[5]
            }
        }
    },

    divider:{
        divider:{
            borderBottomColor: style.colors.grayscale[5]
        }
    }
});
