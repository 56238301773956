import {SvgIcon} from "@material-ui/core";
import React from "react";

export function ErrorIcon(props: any) {
    return (
        <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28 48C39.0457 48 48 39.0457 48 28C48 16.9543 39.0457 8 28 8C16.9543 8 8 16.9543 8 28C8 39.0457 16.9543 48 28 48ZM28 50C40.1503 50 50 40.1503 50 28C50 15.8497 40.1503 6 28 6C15.8497 6 6 15.8497 6 28C6 40.1503 15.8497 50 28 50Z" fill="#FF2929"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4493 37.2172C35.9374 37.7054 36.7289 37.7054 37.2171 37.2172C37.7052 36.7291 37.7052 35.9376 37.2171 35.4495L29.7676 28L37.2171 20.5506C37.7052 20.0624 37.7052 19.2709 37.2171 18.7828C36.7289 18.2946 35.9374 18.2946 35.4493 18.7828L27.9998 26.2322L20.5504 18.7828C20.0622 18.2946 19.2708 18.2946 18.7826 18.7828C18.2945 19.2709 18.2945 20.0624 18.7826 20.5506L26.2321 28L18.7826 35.4495C18.2945 35.9376 18.2945 36.7291 18.7826 37.2172C19.2708 37.7054 20.0622 37.7054 20.5504 37.2172L27.9998 29.7678L35.4493 37.2172Z" fill="#FF2929"/>
        </SvgIcon>
    );
}
