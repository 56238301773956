import RatingQuestionView from '../../../features/questionnaire-page/questionnaire-types/star-rating-question/containers/RatingQuestionView';
import TextQuestionView from '../../../features/questionnaire-page/questionnaire-types/text-question/containers/TextQuestionView';
import YesOrNoQuestionView from '../../../features/questionnaire-page/questionnaire-types/yes-or-no-question/container/YesOrNoQuestionView';
import NumberQuestionView from '../../../features/questionnaire-page/questionnaire-types/number-question/containers/NumberQuestionView';
import BourdainMultipleChoiceQuestionView from '../../../features/questionnaire-page/questionnaire-types/multiple-choice-question/container/BourdainMultipleChoiceQuestionView';

export interface NumberQuestionViewProps {
  hint?: string;
  minimumValue?: number;
  maximumValue?: number;
  allowFractional?: boolean; // TODO: this property is not used yet!
}

export interface ValueLabelTuple {
  value: string;
  label: {
    [key: string]: string;
  };
  description: string;
}

export interface MultipleChoiceQuestionViewProps {
  values: Array<ValueLabelTuple>;
  name?: string;
  icon?: any;
  checkedIcon?: any;
  allowMultiple?: boolean;
}

export interface RatingQuestionViewProps {
  emptyIcon?: any;
  filledIcon?: any;
  precision?: number;
  name?: string;
  max?: number;
  min?: number;
  iconSet?: string;
}

export interface TextQuestionViewProps {
  hint?: string;
  maxLength?: number;
}

export interface YesNoQuestionViewProps {
  inversed?: boolean;
  yesText?: string;
  noText?: string;
}

export abstract class AbstractQuestionAnswerTranslator<T> {
  abstract createValue(value: any, props: T): {};
  abstract getValue(value: any, props: T): any;

  props: T;
  constructor(props: T) {
    this.props = props;
  }
}

class RatingQuestionAnswerTranslator extends AbstractQuestionAnswerTranslator<RatingQuestionViewProps> {
  createValue(value: any): {} {
    return {
      rating: value
    };
  }

  getValue(value: any): any {
    if (typeof value === 'number') return value;
    if (!value) return undefined;
    return (value || { rating: undefined }).rating;
  }
}

class TextQuestionAnswerTranslator extends AbstractQuestionAnswerTranslator<TextQuestionViewProps> {
  createValue(value: any): {} {
    return {
      text: value
    };
  }

  getValue(value: any): any {
    if (!value) return undefined;
    if (typeof value === 'string') return value;
    return (value || { text: undefined }).text;
  }
}

class YesNoQuestionAnswerTranslator extends AbstractQuestionAnswerTranslator<YesNoQuestionViewProps> {
  createValue(value: boolean) {
    return {
      yesNo: value
    };
  }

  getValue(value: any): any {
    if (value === undefined) return false;
    if (typeof value === 'boolean') {
      return value;
    }
    return value.yesNo || false;
  }
}

class NumberQuestionAnswerTranslator extends AbstractQuestionAnswerTranslator<NumberQuestionViewProps> {
  createValue(value: any): {} {
    return {
      number: value
    };
  }

  getValue(value: any): any {
    if (!value) return undefined;
    if (typeof value === 'string') {
      return Number(value);
    }
    if (typeof value === 'number') {
      return value;
    }
    return (value || { number: undefined }).number;
  }
}

class MultipleChoiceQuestionAnswerTranslator extends AbstractQuestionAnswerTranslator<MultipleChoiceQuestionViewProps> {
  createValue(value: any): {} {
    const { allowMultiple } = this.props;
    if (allowMultiple) {
      return {
        choices: {
          choices: value
        }
      };
    } else {
      return {
        choice: value[0]
      };
    }
  }

  getValue(value: any): any {
    if (!value) return [];
    if (value instanceof Array) {
      return value;
    }
    if (value.choice) return [value.choice];
    if (!value.choices) return [];
    return value.choices.choices || [];
  }
}

export const questionTypesToQuestionAnswerTranslator: { [key: string]: any } = {
  rating: RatingQuestionAnswerTranslator,
  text: TextQuestionAnswerTranslator,
  yesNo: YesNoQuestionAnswerTranslator,
  number: NumberQuestionAnswerTranslator,
  multipleChoice: MultipleChoiceQuestionAnswerTranslator
};

export const questionTypesToQuestionView = {
  rating: RatingQuestionView,
  text: TextQuestionView,
  yesNo: YesOrNoQuestionView,
  number: NumberQuestionView,
  multipleChoice: BourdainMultipleChoiceQuestionView
};
