import {UserSetupConfig} from "../models/user-models/UserModels";


export function userSetupConfigFromParams(queryParams: URLSearchParams): UserSetupConfig {
      const enabledExperiments = (queryParams.get('enabled_experiments') || '').split(',').filter((x: string) => x.trim() !== "");
      const enabledIntegrations = (queryParams.get('enabled_integrations') || '').split(',').filter((x: string) => x.trim() !== "");
  return {
    enabledExperiments,
    enabledIntegrations
  };
}
