import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Component } from 'react';
import NormalBlock from '../../../styling/blocks/Normal';
import { FormattedMessage } from 'react-intl';
import { Box } from '@material-ui/core';
import ResetPasswordPage from "./ResetPasswordPage";
import EmailVerifyPage from "./EmailVerifyPage";

interface InnerProps {}

class UnknownAction extends Component<InnerProps> {
  render() {
    return (
      <Box textAlign="left">
        <NormalBlock title={<FormattedMessage id={'unknown_action_form_title'} />}>
          <FormattedMessage id={'unknown_action_form_description'} />
        </NormalBlock>
      </Box>
    );
  }
}

interface Props extends RouteComponentProps {}

class UserManagementPage extends Component<Props> {
  getQueryParams() {
    return new URLSearchParams(this.props.location.search);
  }

  render() {
    const queryParams = this.getQueryParams();
    const mode = queryParams.get('mode') || undefined;
    const actionCode = queryParams.get('oobCode') || undefined;

    return (
      <>
         {mode === 'resetPassword' ? <ResetPasswordPage actionCode={actionCode} />: (
          mode === 'verifyEmail' ? <EmailVerifyPage actionCode={actionCode} />: (
          <UnknownAction />))}
      </>
    );
  }
}

export default withRouter(UserManagementPage);
