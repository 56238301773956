import { QuestionnaireTranslations } from '../interfaces/IQuestionnaireTranslations';

export const questionnaire_translations_nl: QuestionnaireTranslations = {
  questionnaire_card_review: 'Bekijk',
  questionnaire_progress: '% voltooid',
  questionnaire_complete: 'Vragenlijst voltooid',
  questionnaire_complete_profile_button: 'Ga naar je profiel',
  questionnaire_consent_info: '[T] Accepteer om Foodpairing deze informatie te bewaren volgens ons privacybeleid.',
  questionnaire_consent_accept: "[T] Accepteer & vraag niet opnieuw",
  questionnaire_consent_refuse: '[T] Weiger',
  questionnaire_finish_button: 'Vragenlijst voltooien',
  questionnaire_next_button: 'Volgende',
  skip_if_you_dont_know: 'Sla over als je het niet kent',
  rating_value_1: 'Helemaal niet lekker',
  rating_value_2: 'Niet lekker',
  rating_value_3: 'Ok',
  rating_value_4: 'Lekker',
  rating_value_5: 'Heel lekker'
};
