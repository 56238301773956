import type { Props } from "./index.d";
import React from "react";
import {createUseStyles} from "react-jss";
import * as Icons from "../../icons";

const useStyles = createUseStyles((theme: any) => ({
    root:{
        fill: 'currentColor',
        verticalAlign: 'middle',
        flexShrink: 0, // prevent shrinking in flex-box
        ...theme.icons.icon,
        width: props => theme.icons[props.size].width,
        height: props => theme.icons[props.size].height
    }
}));

export const Icon: React.FC<Props> =
({
    children,
    viewBox,
    ariaHidden,
    ariaLabel,
    svgIcon = '',
    size = 'medium'
}) => {

    const classes = useStyles({size});

    if(svgIcon){
        // @ts-ignore
        if(!Icons[svgIcon]){
            return null;
        }
        // @ts-ignore
        return React.createElement(Icons[svgIcon], {size, ariaLabel, ariaHidden})
    }

    return (
        <svg
            className={classes.root}
            viewBox={viewBox}
            aria-hidden={ariaHidden ? "true" : undefined}
            aria-label={ariaLabel}
            preserveAspectRatio="xMidYMid meet"
        >
            {children}
        </svg>
    );
}
export default Icon;
