import {SvgIcon} from "@material-ui/core";
import React from "react";

export function PaperAirPlane(props: any) {
    return (
        <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.4014 8.08171C51.2314 8.00812 51.0442 7.98355 50.861 8.01079C50.6778 8.03803 50.5058 8.11599 50.3646 8.23585L4.35127 31.7768C4.19102 31.9127 4.07793 32.0959 4.02823 32.3001C3.97852 32.5043 3.99475 32.719 4.07459 32.9133C4.15443 33.1077 4.29378 33.2718 4.47264 33.3821C4.6515 33.4923 4.86068 33.5431 5.07018 33.5271L17.2862 36.4959L24.169 47.9885C24.2587 48.1296 24.3826 48.2459 24.5292 48.3264C24.6759 48.407 24.8404 48.4492 25.0077 48.4492C25.0962 48.4491 25.1844 48.4373 25.2699 48.4143C25.4228 48.3724 25.5635 48.2946 25.6802 48.1873C25.7969 48.08 25.8863 47.9464 25.9408 47.7975L30.3962 39.0628L45.056 42.2692C45.2054 42.3018 45.3603 42.2994 45.5087 42.2623C45.657 42.2253 45.7948 42.1545 45.9114 42.0554C46.028 41.9564 46.1201 41.8319 46.1807 41.6914C46.2413 41.551 46.2687 41.3985 46.2608 41.2457L52 8.94274C51.9904 8.75767 51.9293 8.57897 51.8235 8.42682C51.7178 8.27467 51.5715 8.15513 51.4014 8.08171ZM7.63152 31.7768L50.3646 9.28077L18.0484 34.1576L7.63152 31.7768ZM25.6124 36.7445C25.5474 36.8739 25.5111 37.0158 25.5061 37.1606L24.9081 44.3484L19.8268 34.9462L50.3646 9.28077L25.6124 36.7445ZM27.9665 36.4959L50.861 9.789L44.2074 40.0479L27.9665 36.4959Z" fill="#2F998D"/>
        </SvgIcon>
    );
}
