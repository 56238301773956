import * as React from 'react';
import { AuthenticationViewProps, AuthenticationViewType } from '../AuthenticationTypesInterfaces';
import { FINISHED } from '../../AuthenticationViewTypes';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import QuestionnaireAPIDetailView, { ValueType } from '../../../../API/containers/QuestionnaireAPIDetailFetcher';
import AuthenticatedPageWithLoading from '../../../../shared/containers/AuthenticatedPageWithLoading';
import QuestionnaireCardView from '../../../questionnaire-page/components/QuestionnaireCardView';
import { DEFAULT_QUESTIONNAIRE } from '../../../../constants/QuestionnaireConstants';
import { Questionnaire } from '../../../../models/questionnaire-models/internal/QuestionnaireModels';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends AuthenticationViewProps, RouteComponentProps {
  onAuthenticate: () => void; // TODO: update this if we store extra metadata of users in redux
}

class SignUpQuestionnaireView extends AuthenticationViewType<Props, any> {
  static NEXT_VIEW: string = FINISHED;

  signupDone(questionnaire: Questionnaire) {
    const { history, location } = this.props;
    const qs = new URLSearchParams(location.search);
    const redirect = qs.get('redirect');
    if (redirect) {
      window.location.href = redirect;
    } else {
      history.push(`/questionnaire/${questionnaire.id}/completed`);
    }
  }

  render() {
    // TODO: think about making this a questionnaire as well
    return (
      <div>
        <AuthenticatedPageWithLoading>
          <QuestionnaireAPIDetailView questionnaireId={DEFAULT_QUESTIONNAIRE}>
            {(value: ValueType, authToken: string) => (
              <QuestionnaireCardView
                authToken={authToken}
                questionnaire={value.questionnaire}
                onComplete={(questionnaire: Questionnaire) => {
                  this.signupDone(questionnaire);
                }}
              />
            )}
          </QuestionnaireAPIDetailView>
        </AuthenticatedPageWithLoading>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onAuthenticate: () => {
      return; // TODO: update this if we store extra metadata of users in redux
    }
  };
};

export default connect(null, mapDispatchToProps)(withRouter(SignUpQuestionnaireView));
