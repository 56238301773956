import React, { Component } from 'react';
import style from '../../../styling/Style';
import { FormattedMessage } from 'react-intl';
import { Grid, Box } from '@material-ui/core';
import IntegrationComponent from './IntegrationComponent';
import { AppIntegrationDocumentViewProps } from '../../../models/integration-models/internal/IntegrationModels';
import StyledParagraph from '../../../styling/StyledParagraph';

interface Props {
  integrations: any;
}

class IntegrationsOverviewComponent extends Component<Props, any> {
  render() {
    const { integrations } = this.props;
    const appIntegrations = integrations.integrations.appIntegrations || [];
    return (
      appIntegrations.length > 0 && (
        <div>
          <Grid item xs={12}>
            <Box py={6}>
              <StyledParagraph variation="messageMedium" color={style.colors.grayscale[1]}>
                <FormattedMessage id={'profilepage_active_integrations'}></FormattedMessage>
              </StyledParagraph>
            </Box>
            <Grid container>
              {appIntegrations.map((integration: AppIntegrationDocumentViewProps) => {
                return (
                  <Grid item xs={12} sm={6} key={integration.id}>
                    <IntegrationComponent integration={integration} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </div>
      )
    );
  }
}

export default IntegrationsOverviewComponent;
