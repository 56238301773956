import { QuestionnaireTranslations } from '../interfaces/IQuestionnaireTranslations';

export const questionnaire_translations_en: QuestionnaireTranslations = {
  questionnaire_card_review: 'Review',
  questionnaire_progress: '% Completed',
  questionnaire_complete: 'Test Finished',
  questionnaire_complete_profile_button: 'Go to your profile',
  questionnaire_consent_info: '[T] By clicking accept, you allow FoodPairing to store this information according to the privacy policy.',
  questionnaire_consent_accept: "[T] Accept & Don't ask again",
  questionnaire_consent_refuse: '[T] Refuse',
  questionnaire_finish_button: 'Finish Questionnaire',
  questionnaire_next_button: 'Next',
  skip_if_you_dont_know: 'Skip if N/A',
  rating_value_1: 'Really dislike',
  rating_value_2: 'Dislike',
  rating_value_3: 'Ok',
  rating_value_4: 'Like',
  rating_value_5: 'Really Like'
};
