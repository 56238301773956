import AuthenticationFinishedView from './authentication-types/authentication-finished/AuthenticationFinishedView';
import SignUpInfoView from './authentication-types/signup/SignUpInfoView';
import {
  FINISHED,
  SIGNIN,
  SIGNUP_PERSONAL_DATA,
  SIGNUP_QUESTIONNAIRE,
  SIGNUP_START,
  SIGNUP_VERIFY_EMAIL
} from './AuthenticationViewTypes';
import SignUpQuestionnaireView from './authentication-types/signup/SignUpQuestionnaireView';
import SignInPageView from './authentication-types/signin/SignInPageView';
import SignUpPageView from './authentication-types/signup/SignUpPageView';
import * as React from 'react';
import QuestionnaireNavbar from '../../shared/components/static/navbars/QuestionnaireNavbar';
import VerifyEmailPageView from "./authentication-types/verifyemail/VerifyEmailPageView";

// Map of <key:string> to <view:AuthenticationView>
interface ComponentMap {
  [key: string]: any;
}

// Exports a map of all the names to their respective Authentication view type component
export const AUTHENTICATION_TYPE_TO_COMPONENT_MAP: ComponentMap = {
  [FINISHED]: AuthenticationFinishedView,
  [SIGNUP_START]: SignUpPageView,
  [SIGNUP_PERSONAL_DATA]: SignUpInfoView,
  [SIGNUP_QUESTIONNAIRE]: SignUpQuestionnaireView,
  [SIGNUP_VERIFY_EMAIL]: VerifyEmailPageView,
  [SIGNIN]: SignInPageView
};

export const AUTHENTICATION_TYPE_TO_COMPONENT_HEADER: ComponentMap = {
  [FINISHED]: undefined,
  [SIGNUP_QUESTIONNAIRE]: <QuestionnaireNavbar />,
};
