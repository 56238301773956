import { QuestionGroup, QuestionOrGroup } from '../models/questionnaire-models/internal/QuestionModels';

const searchChild = function (qg: QuestionGroup, id: string) {
  return searchChildInArray(qg.questions, id);
};

const searchChildInArray = function (qog: QuestionOrGroup[], id: string) {
  return qog.find((q) => q.questionOrGroup.data.id === id);
};

export const resolveDependencyQuestionGroup = (dependencyPath: string[], qog: QuestionOrGroup) => {
  let currentElement: QuestionOrGroup | undefined = qog;
  for (const breadcrumb of dependencyPath) {
    currentElement = searchChild(currentElement.questionOrGroup as QuestionGroup, breadcrumb);
    if (!currentElement) {
      return undefined;
    }
  }
  return currentElement;
};

export const resolveDependency = function (dependencyString: string, questions: QuestionGroup[]) {
  const dependencyPath = dependencyString.split('.');
  const firstBreadcrumb = dependencyPath.shift();
  const firstQuestion = questions.find((q) => q.data.id === firstBreadcrumb);

  if (!firstQuestion) return undefined;

  return resolveDependencyQuestionGroup(dependencyPath, { questionOrGroup: firstQuestion });
};
