import React from "react";
import {Props} from './index.d';
import {Card} from "../../components";
import {createUseStyles} from "react-jss";
import Typography from "../Typography";
import Paragraph from "../Paragraph";
import AromaDot from "../AromaDot";
import {FormattedMessage} from "react-intl";

const useStyles = createUseStyles((theme: any)=>({
    containerLeft:{
        ...theme.aromaCards.containerLeft,
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            ...theme.aromaCards.containerXS
        },
    },
    containerRight:{
        ...theme.aromaCards.containerRight,
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            ...theme.aromaCards.containerXS
        },
    },
    aromaCard:{
        ...theme.aromaCards.aromaCard
    },
    title:{
        ...theme.aromaCards.title
    },
    titleContainer:{
        ...theme.aromaCards.titleContainer
    },
    description:{
        ...theme.aromaCards.description
    }
}));

export const AromaCard: React.FC<Props> =
({
    title,
    description,
    aromaColor,
    pictureRight = false,
    aromaImage,
    ...props
}) => {
    const classes = useStyles();
    const containerClass = pictureRight? classes.containerRight : classes.containerLeft;

    return (
        <div className={containerClass} style={{backgroundImage:`url(${aromaImage})`}} {...props}>
            <div className={classes.aromaCard}>
                <Card>
                    <div className={classes.titleContainer}>
                        <AromaDot color={aromaColor} />
                        <div className={classes.title}>
                            <FormattedMessage id={title} />
                        </div>
                    </div>
                    <Paragraph className={classes.description} style={{marginBottom:0}}>
                        <Typography>
                            <FormattedMessage id={description} />
                        </Typography>
                    </Paragraph>
                </Card>
            </div>
        </div>
    )
}
