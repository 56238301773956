import { Component } from 'react';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import LegalPage from './LegalPage';
import StaticFetchComponent from '../../../shared/containers/StaticFetchComponent';

export default class TermsConditionsPage extends Component<any, any> {
  render() {
    return (
      <LegalPage>
        <StaticFetchComponent endpoint={'/legal/tos.md'}>{(value: any) => <ReactMarkdown source={value} />}</StaticFetchComponent>
      </LegalPage>
    );
  }
}
