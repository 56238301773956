import { Component } from 'react';
import * as React from 'react';
import AuthenticationView from '../../containers/AuthenticationView';
import { SIGNUP_VERIFY_EMAIL } from '../../AuthenticationViewTypes';

class VerifyEmailView extends Component<any, any> {
    render() {
        return <AuthenticationView type={SIGNUP_VERIFY_EMAIL} />;
    }
}

export default VerifyEmailView;
