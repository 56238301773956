import * as React from 'react';
import AuthenticationPage from '../../containers/AuthenticationPageView';
import { FormHelperText} from '@material-ui/core';
import { AuthenticationViewType } from '../AuthenticationTypesInterfaces';
import firebase from 'firebase/app';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SIGNUP_QUESTIONNAIRE } from '../../AuthenticationViewTypes';
import {FormattedMessage, injectIntl, IntlShape} from 'react-intl';
import {State} from "../../../../redux/Reducers";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {PaperAirPlane} from "../../../../styling/icons/PaperAirPlane";
import StyledParagraph from "../../../../styling/StyledParagraph";
import style from "../../../../styling/Style";
import StyledButton from "../../../../styling/StyledButton";
import StyledTextField from "../../../../styling/StyledTextField";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import StyledTitle from "../../../../styling/StyledTitle";

interface Props extends RouteComponentProps {
    onAction: any;
    isAuthenticated: boolean | undefined;
    isEmailVerified: boolean | undefined;
    email: string | null | undefined;
    intl: IntlShape;
}

interface States{
    showForm: boolean;
}

const ResendSchema = (props: Props) => {
    const { intl } = props;
    return Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'authentication_email_invalid' }))
            .required(intl.formatMessage({ id: 'authentication_required' })),
        global: Yup.string().notRequired()
    });
};

class VerifyEmailPageView extends AuthenticationViewType<Props, States> {
    //static NEXT_VIEW: string = SIGNUP_LANGUAGE_CHOOSER;
    static NEXT_VIEW: string = SIGNUP_QUESTIONNAIRE;

    constructor(props: Props) {
        super(props);
        this.state = {
            showForm: false
        }
    }

    showResendForm(){
        this.setState({showForm: true});
    }

    reloadPage(){
        this.setState({showForm: false});
    }

    componentDidMount() {

    }

    onSendVerificationEmail(values: any, formikHelpers: FormikHelpers<any>) {
        const currentUser: firebase.firebase.User | null = firebase.auth().currentUser;

        if(!currentUser) return;

        if(!currentUser?.emailVerified){

            if(currentUser.email === values.email){
                return currentUser
                .sendEmailVerification()
                .then(()=>{
                    this.reloadPage();
                }).catch((error)=>{
                    const errorMessage = error.message;
                    formikHelpers.setErrors({
                        global: errorMessage
                    });
                });
            }else{
                return currentUser
                .updateEmail(values.email)
                .then(()=>{
                    currentUser
                        .sendEmailVerification()
                        .then(()=>{
                            this.reloadPage();
                        }).catch((error)=>{
                        alert(error);
                    })

                }).catch((error)=>{
                    const errorMessage = error.message;
                    formikHelpers.setErrors({
                        global: errorMessage
                    });
                });
            }
        }
    }

    render() {
        const { email, isEmailVerified, intl } = this.props;

        if(isEmailVerified){
            return <Redirect to={'/'} />;
        }

        return (
            <AuthenticationPage>
                <div style={{textAlign:'center'}}>
                    <PaperAirPlane />
                </div>
                <div style={{marginTop:style.spacing[6]}}>
                    <StyledTitle style={{marginBottom:style.spacing[3]}}>
                        <FormattedMessage id={'confirm_your_email_address'} />
                    </StyledTitle>
                    <StyledParagraph variation="normal" style={{textAlign:'center'}}>
                        <FormattedMessage id={'we_sent_message_to'} /> <strong>{email}</strong><br />
                        <FormattedMessage id={'check_your_email_account_for_email_verification'} />
                    </StyledParagraph>
                </div>
                {
                    this.state.showForm ?
                        <div style={{marginTop:style.spacing[8]}}>
                            <Formik
                                initialValues={{
                                    email: email || '',
                                    global: ''
                                }}
                                validationSchema={ResendSchema(this.props)}
                                onSubmit={(values, formikHelpers) => this.onSendVerificationEmail(values, formikHelpers)}
                            >
                                {({ handleSubmit, setErrors, values }) => {
                                    return (
                                        <Form translate="yes" onSubmit={handleSubmit}>
                                            <div>
                                                <Field name="global">{(field: FieldProps<any>) => <FormHelperText error={true}>{field.meta.error}</FormHelperText>}</Field>
                                            </div>
                                            <Field name="email" type="email">
                                                {(field: FieldProps<any>) => (
                                                    <StyledTextField
                                                        fullWidth={true}
                                                        label={<FormattedMessage id={'authentication_email_label'} />}
                                                        placeholder={intl.formatMessage({ id: 'authentication_email_placeholder' })}
                                                        {...field}
                                                    />
                                                )}
                                            </Field>

                                            <StyledButton size="lg" type="submit" style={{width:'100%',marginTop:style.spacing[6]}}>
                                                <FormattedMessage id={'resend_email_submit'} />
                                            </StyledButton>

                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    :
                    <div style={{marginTop:style.spacing[8]}}>
                        <StyledParagraph variation="defaultStrong" style={{textAlign:'center',marginBottom:style.spacing[6]}}>
                            <FormattedMessage id={'question_didnt_receive_the_email'} />
                        </StyledParagraph>
                        <StyledButton style={{width:'100%'}} onClick={()=>{this.showResendForm()}}><FormattedMessage id={'resend_email_button'} /></StyledButton>
                        <StyledButton variant="link" style={{width:'100%',marginTop:style.spacing[7]}} onClick={()=>{this.showResendForm()}}><FormattedMessage id={'change_email_button'} /></StyledButton>
                    </div>
                }
            </AuthenticationPage>
        );
    }
}

const mapStateToProps = (state: State) => {
    return {
        isAuthenticated: state.authentication.authenticated,
        isEmailVerified: state.authentication.currentUser?.emailVerified,
        email: state.authentication.currentUser?.email
    };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(VerifyEmailPageView)));
