import { Component } from 'react';
import * as React from 'react';
import { StyledComponentProps, Grid, withStyles } from '@material-ui/core';
import { Language, LANGUAGE_BY_NAME, LANGUAGES } from '../../../Languages';
import { connect as ReduxConnect } from 'react-redux';
import { SettingsObject } from '../../../models/user-models/UserModels';
import { State } from '../../../redux/Reducers';
import { AuthenticatedProps } from '../../containers/AuthenticatedPageWithLoading';
import { connect } from '../../../RefetchConfig';
import { setLanguage } from '../../../redux/language/LanguageActionCreator';
import style from '../../../styling/Style';
import StyledParagraph from '../../../styling/StyledParagraph';

const styles = {
  languageButton: {
    '& button': {
      ...style.typography.body,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 0,
      outline: 'none',
      color: style.colors.grayscale[4]
    },
    '& .active': {
      color: style.colors.grayscale[2]
    },

    '&:last-child': {
      '& span': {
        display: 'none'
      }
    }
  },
  separator: {
    ...style.typography.bravier_strong,
    color: style.colors.grayscale[2]
  }
};

interface Props extends StyledComponentProps, AuthenticatedProps {
  languageUpdated: (language: Language) => void;
  setLanguage: (language: Language, languageChanged: boolean) => void;
  languageChanged: boolean;
  initialLanguage: Language;
}

interface MyState {
  changeLanguageDialogOpen: boolean;
  currentLanguage: string;
}

class LanguageToolBar extends Component<Props, MyState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      changeLanguageDialogOpen: props.languageChanged ? false : true,
      currentLanguage: props.initialLanguage.locale
    };
  }

  changeLanguage() {
    this.setState({ changeLanguageDialogOpen: true });
  }

  closeChangeLanguage() {
    this.setState({ changeLanguageDialogOpen: false });
  }

  render() {
    const { languageUpdated } = this.props;
    const classes = this.props.classes || {};
    const { currentLanguage } = this.state;

    return (
      <div>
          <Grid container justify="flex-end" alignItems="center" wrap={'nowrap'}>
            {LANGUAGES.map((language: Language, index: number) => (
              <Grid item className={classes.languageButton} key={index} style={{whiteSpace:'nowrap'}}>
                <button
                  className={currentLanguage === language.locale ? 'active' : ''}
                  value={language.locale}
                  onClick={(event) => {
                    languageUpdated(LANGUAGE_BY_NAME[language.locale]);
                    this.setState({
                      currentLanguage: event.currentTarget.value
                    });
                  }}
                >
                  <StyledParagraph variation={currentLanguage===language.locale?'navBarItemSelected':'navBarItem'}>{language.locale.toUpperCase()}</StyledParagraph>
                </button>
                <span className={classes.separator}>/</span>
              </Grid>
            ))}
          </Grid>
      </div>
    );
  }
}

// @ts-ignore
const refetchConnected = connect((props: Props) => ({
  languageUpdated: (language: Language) => {
    if(!props.authToken){
      props.setLanguage(LANGUAGE_BY_NAME[language.locale], true);
      return {};
    }
    return {
      language: {
        url: `/stop/user/settings`,
        authenticationToken: props.authToken,
        method: 'PATCH',
        body: JSON.stringify({
          settings: {
            language: language.locale
          }
        }),
        then: (subject: SettingsObject) => {
          props.setLanguage(LANGUAGE_BY_NAME[subject.language], true);
        },
        catch: (error: any) => {
          console.log(error);
        }
      }
    };
  }
}))(withStyles(styles)(LanguageToolBar));

export default ReduxConnect(
  (state: State) => ({
    languageChanged: state.language.languageChanged,
    initialLanguage: state.language.language
  }),
  (dispatch) => ({
    setLanguage: (language: Language, languageChanged: boolean) => {
      dispatch(setLanguage(language, languageChanged));
    }
  })
)(refetchConnected);
