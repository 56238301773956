import { Grid, withStyles } from '@material-ui/core';
import { Component } from 'react';
import * as React from 'react';
import { theme } from '../../../styling/DefaultTheme';
import style from '../../../styling/Style';

const styles = {
  footer: {
    [theme.breakpoints.up('xs')]: {
      marginTop: style.margins.small
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 50
    },
    backgroundColor: theme.palette.grey[100],
    padding: '8px 0',
    height: 64
  }
};

class FooterComponent extends Component<any, any, any> {
  render() {
    const { classes } = this.props;

    return (
        <Grid container alignItems="center" justify="center" className={`${classes.footer}`}>
          <img src="/powered_on_light.svg" alt="Foodpairing logo" />
        </Grid>
    );
  }
}

export default withStyles(styles)(FooterComponent);
