import { ResetPasswordPageTranslations } from '../interfaces/IResetPasswordPageTranslations';

export const resetpasswordpage_translations_en: ResetPasswordPageTranslations = {
  reset_password_title: 'Reset password',
  reset_password_title_success: 'Reset password succeeded',
  reset_password_title_failure: 'Reset password failed',
  reset_password_form_submit_button: 'Reset',
  reset_password_callback_form_description_failure: "Failed to set new password.",
  reset_password_callback_form_description_success: "New password successfully applied",
  reset_password_button_failure: "Retry",
  reset_password_button_success: "Back",
};
