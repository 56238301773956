import { Component } from 'react';
import * as React from 'react';
import { SIGNIN, SIGNUP_QUESTIONNAIRE } from '../AuthenticationViewTypes';
import { AUTHENTICATION_TYPE_TO_COMPONENT_HEADER, AUTHENTICATION_TYPE_TO_COMPONENT_MAP } from '../AuthenticationViewTypesMap';
import { State } from '../../../redux/Reducers';
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {DEFAULT_QUESTIONNAIRE} from "../../../constants/QuestionnaireConstants";
import QuestionnaireApiDetailView, {ValueType} from "../../../API/containers/QuestionnaireAPIDetailFetcher";
import AuthenticatedPageWithLoading from "../../../shared/containers/AuthenticatedPageWithLoading";

// Type of initial authentication
interface Props {
  type?: string;
  isAuthenticated: boolean;
}

// Type of authentication view (Sign-in, Sign-up-initial, ...)
interface ViewState {
  type: string;
}

class AuthenticationView extends Component<Props, ViewState> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: props.type || SIGNIN
    };
  }

  onAction(value: any | undefined, component: any) {

    this.setState({ type: component.NEXT_VIEW });
  }

  renderCardContent() {
    const { type } = this.state;
    const { isAuthenticated } = this.props;
    const RenderedComponent = AUTHENTICATION_TYPE_TO_COMPONENT_MAP[isAuthenticated?type:(this.props.type || SIGNIN)];

    if(type===SIGNUP_QUESTIONNAIRE){
      return (
          <AuthenticatedPageWithLoading>
            <QuestionnaireApiDetailView questionnaireId={DEFAULT_QUESTIONNAIRE}>
              {(questionnaire: ValueType) => {

                /*
                 * I've added this to redirect the user if the start questionnaire
                 * is not started.
                 * It is my opinion that it would be necessary to receive a flag from /stop/user
                 * indicating whether the first questionnaire should be shown.
                 * */
                if(!questionnaire.questionnaire.completed){
                  return <Redirect to={'/questionnaire/' + DEFAULT_QUESTIONNAIRE} />;
                }else{
                  return <Redirect to={'/'} />;
                }

              }}
            </QuestionnaireApiDetailView>
          </AuthenticatedPageWithLoading>
      );
    }

    return <RenderedComponent onAction={(x?: any) => this.onAction(x, RenderedComponent)} />;
  }

  render() {
    const { type } = this.state;
    const { isAuthenticated } = this.props;

    return (
      <div>
        {AUTHENTICATION_TYPE_TO_COMPONENT_HEADER[isAuthenticated?type:(this.props.type || SIGNIN)]}
        {this.renderCardContent()}
      </div>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    isAuthenticated: state.authentication.authenticated
  };
};

export default connect(mapStateToProps)(AuthenticationView);
